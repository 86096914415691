<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { RecycleScroller } from "vue-virtual-scroller";

import DocPreview from "~/components/docs/DocPreview.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Filter from "~/components/filters/Filter.vue";
import { CHANGELOG_DESCRIPTION, STANDUP_DESCRIPTION } from "~/constants/report";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { ChangelogReportIcon, SpaceFieldIcon, StandupReportIcon } from "~/icons";
import { goHome } from "~/router/common";
import { DropdownMenuItemKind, FilterApplicability, FilterConnector, FilterType, ReportKind } from "~/shared/enums";
import { type Doc, type DropdownMenuSection, type Filter as IFilter, type FilterDefinition } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getDocLink, getPageDisplayName, getReportsLink } from "~/utils/common";
import { makeStringComparator } from "~/utils/comparator";

const props = defineProps<{
  spaceDuid?: string;
}>();

const currentInstance = getCurrentInstance();
const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const space = computed(() => (props.spaceDuid ? dataStore.getSpaceByDuid(props.spaceDuid) : null));
const reportsFolder = computed(() => (props.spaceDuid ? dataStore.reportsFolder(props.spaceDuid) : null));
const docs = computed(() => {
  if (!reportsFolder.value) {
    return [];
  }
  return dataStore
    .getDocsByFolderDuidOrdered(reportsFolder.value.duid)
    .sort(makeStringComparator((e) => e.updatedAt, true));
});

watch(
  () => space.value,
  (newSpace) => {
    if (!newSpace) {
      setTimeout(() => {
        const { workspaceSpace } = dataStore;
        if (!workspaceSpace) {
          goHome();
          return;
        }
        router.replace({
          ...getReportsLink(workspaceSpace),
          query: { ...router.currentRoute.value.query },
        });
      });
      return;
    }

    appStore.setCurrentPage(newSpace);
  },
  { immediate: true }
);

const openDoc = (doc: Doc) => {
  router.push(getDocLink(doc));
};

const openReportCreationModal = (reportKind: ReportKind) => {
  if (!space.value) {
    return;
  }

  appStore.setSpaceOpenInReportCreationModal(space.value, reportKind);
};

const recycleScroller = ref<InstanceType<typeof RecycleScroller> | null>(null);
const itemSecondarySize = ref(336);
const onResize = () => {
  const { width } = useElementBounding(recycleScroller);
  const maxWidth = 1024;
  itemSecondarySize.value = Math.floor(Math.min(width.value, maxWidth) / 3) - 5;
};

const createDoc = () => {
  if (!space.value) {
    return;
  }

  appStore.setSpaceOpenInReportCreationModal(space.value);
};

const dropdownSections = computed<DropdownMenuSection[]>(() => [
  {
    title: "Space",
    showTitle: false,
    items: dataStore.getSpaceList().map((e) => ({
      title: getPageDisplayName(e, dataStore.getSpaceByDuid),
      kind: DropdownMenuItemKind.BUTTON,
      disabled: space.value?.duid === e.duid,
      icon: PageIcon,
      iconArgs: { page: e },
      onClick: () => {
        router.replace(getReportsLink(e));
      },
    })),
  },
]);

const spaceFilter = computed<IFilter>(() => ({
  id: "space",
  propertyDuid: "space",
  locked: false,
  applicability: FilterApplicability.IS,
  connector: FilterConnector.AND,
  values: [props.spaceDuid ?? ""],
}));
const spaceDefinition = computed<FilterDefinition>(() => ({
  propertyDuid: "space",
  title: "Space",
  icon: SpaceFieldIcon,
  getValue: () => null,
  config: {
    type: FilterType.VALUE,
    available: true,
    singleton: true,
    applicabilities: [FilterApplicability.IS],
    options: dataStore.getSpaceList().map((e) => ({
      value: e.duid,
      label: getPageDisplayName(e, dataStore.getSpaceByDuid),
      colorHex: DEFAULT_CHIP_COLOR,
      icon: PageIcon,
      iconArgs: { page: e, small: true },
    })),
    test: () => true,
  },
}));

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.spaceOrFolder = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.spaceOrFolder = null;
});

defineExpose({
  createDoc,
});
</script>

<template>
  <div v-if="space" class="relative flex w-full max-w-5xl flex-col items-center overflow-hidden pt-10">
    <div class="flex w-full flex-wrap gap-1">
      <DropdownMenu :sections="dropdownSections" cover>
        <div>
          <Filter
            :filter="spaceFilter"
            :definition="spaceDefinition"
            :open-new-filter="() => {}"
            class="pointer-events-none select-none" />
        </div>
      </DropdownMenu>
    </div>

    <div class="mt-6 flex w-full flex-col items-center">
      <div class="flex w-full flex-col gap-4">
        <div v-if="pageStore.isOnline" class="mb-10 flex w-full flex-col items-center gap-8 sm:flex-row">
          <button
            type="button"
            class="flex h-full w-1/2 cursor-pointer items-center gap-3 rounded border p-4 text-left text-base text-md border-md hover:bg-lt"
            @click="openReportCreationModal(ReportKind.STANDUP)">
            <div class="flex items-center justify-center rounded-full bg-lt icon-xl">
              <StandupReportIcon class="icon-lg" />
            </div>
            <div class="flex flex-col gap-1">
              <span class="select-none text-base font-semibold text-hvy">New standup report</span>
              <span class="select-none text-sm text-md">{{ STANDUP_DESCRIPTION }}</span>
            </div>
          </button>
          <button
            type="button"
            class="flex h-full w-1/2 cursor-pointer items-center gap-3 rounded border p-4 text-left text-base text-md border-md hover:bg-lt"
            @click="openReportCreationModal(ReportKind.CHANGELOG)">
            <div class="flex items-center justify-center rounded-full bg-lt icon-xl">
              <ChangelogReportIcon class="icon-lg" />
            </div>
            <div class="flex flex-col gap-1">
              <span class="select-none text-base font-semibold text-hvy">New changelog report</span>
              <span class="select-none text-sm text-md">{{ CHANGELOG_DESCRIPTION }}</span>
            </div>
          </button>
        </div>

        <!-- Reports -->
        <div class="mb-4 flex w-full items-center justify-between">
          <span class="select-none text-xl font-semibold text-md">Recent reports</span>
        </div>
      </div>
    </div>

    <RecycleScroller
      ref="recycleScroller"
      :items="docs"
      :item-size="139 + 24"
      :item-secondary-size="itemSecondarySize"
      :grid-items="3"
      key-field="duid"
      item-class="flex items-center pb-6"
      list-class="h-full w-full"
      class="size-full overflow-y-scroll pb-[300px]"
      @resize="onResize">
      <template #default="{ item: doc, index }">
        <DocPreview
          enable-context-menu
          class="size-full overflow-hidden"
          :class="{
            'ml-3': index % 3 !== 0,
            'mr-3': index % 3 !== 2,
          }"
          :doc="doc"
          hide-remove
          @click.stop="openDoc(doc)"
          @keydown.enter.stop="openDoc(doc)" />
      </template>
    </RecycleScroller>

    <PageEmptyState v-if="docs.length === 0" class="mt-72" />
  </div>
</template>
