<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import { notify } from "~/components/notifications";
import { PlusIcon } from "~/icons";
import { ButtonSize, ButtonStyle, NotificationType } from "~/shared/enums";
import type { Option, PropertyAnyMultiselect, PropertyConfig, PropertySelect } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";

import OptionItem from "./OptionItem.vue";

export type OptionWithChildren = { option: Option; children: OptionWithChildren[] };

const props = defineProps<{
  property: PropertyAnyMultiselect | PropertySelect;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const addingOption = ref<Option | null>(null);
const options = computed<Option[]>(() => [
  ...dataStore.getOptionList(props.property),
  ...(addingOption.value ? [addingOption.value] : []),
]);

const addOption = (parentDuid: string | null) => {
  addingOption.value = {
    duid: makeDuid(),
    title: "",
    propertyDuid: props.property.duid,
    parentDuid,
    colorHex: makeRandomColorHex(),
  };
};

const onAdd = async (title: string) => {
  if (!addingOption.value) {
    return;
  }

  if (title !== "") {
    const option = await dataStore.createOption(title, props.property, {
      colorHex: addingOption.value.colorHex,
      parentDuid: addingOption.value.parentDuid,
    });
    if (!option) {
      notify({ message: "Option already exists for property", type: NotificationType.ERROR });
    }
  }

  addingOption.value = null;
};

const optionsWithChildren = computed<OptionWithChildren[]>(() => {
  const items = [...options.value];
  const rootItems = items.filter((e) => !e.parentDuid);

  const parentToChildren = items.reduce(
    (acc, e) => {
      if (!e.parentDuid) {
        return acc;
      }
      // eslint-disable-next-line no-param-reassign
      acc[e.parentDuid] = acc[e.parentDuid] || [];
      acc[e.parentDuid].push(e);
      return acc;
    },
    {} as Record<string, Option[]>
  );

  const getChildren = (parentDuid: string): OptionWithChildren[] => {
    const children = parentToChildren[parentDuid] || [];
    return children.map((e) => ({ option: e, children: getChildren(e.duid) }));
  };

  return rootItems.map((e) => ({ option: e, children: getChildren(e.duid) }));
});

const addSubitem = (parentDuid: string) => {
  addOption(parentDuid);
};
</script>

<template>
  <div class="flex flex-col gap-2">
    <span class="select-none text-sm text-lt">Options</span>
    <div class="flex flex-col gap-2">
      <OptionItem
        v-for="item in optionsWithChildren"
        :key="item.option.duid"
        :option="item.option"
        :children="item.children"
        :adding-duid="addingOption?.duid"
        @add="onAdd"
        @add-subitem="addSubitem" />
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :icon="PlusIcon"
        text="New option"
        borderless
        :size="ButtonSize.SMALL"
        class="w-max pl-2"
        @click="() => addOption(null)" />
    </div>
  </div>
</template>
