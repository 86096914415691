import { FolderKind, IconKind, PageKind, SpaceKind, SprintMode, UserRole } from "~/shared/enums";
import type { Dartboard, Folder, PageIconOption, Space, SpaceUpdate, TransactionResponse } from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { filterInPlace, getNextTitleInSequence, makeDuid, randomSample } from "~/utils/common";
import { spaceComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  /* Get draft space */
  spaceDraft: Space | undefined;
  getSpaceList: (options?: { includeDraft?: boolean }) => Space[];
  getSpaceByDuid: (duid: string) => Space | undefined;
  getSpacesByDuidsOrdered: (duids: string[]) => Space[];
  workspaceSpace: Space | undefined;
  personalSpace: Space;
};

export type Actions = {
  /** Create a new space. */
  createSpace: (order: string, partialSpace?: Partial<Space>, options?: { awaitBackend?: boolean }) => Promise<Space>;
  /** Update a space. */
  updateSpace: (update: SpaceUpdate, options?: { awaitBackend?: boolean }) => Promise<Space | undefined>;
  /** Update space sprint mode */
  updateSpaceSprintMode: (
    spaceDuid: string,
    sprintMode: SprintMode,
    options?: { awaitBackend?: boolean }
  ) => Promise<Space | undefined>;
  /* Update accessors for a space */
  updateSpaceAccessors: (
    spaceDuid: string,
    accessorDuids: string[],
    add?: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<Space | undefined>;
  /** Replicate space */
  replicateSpace: (
    space: Space,
    order: string,
    partialSpace?: Partial<Space>,
    options?: { awaitBackend?: boolean; maintainTitle?: boolean }
  ) => Promise<Space | undefined>;
  /** Delete space */
  deleteSpace: (space: Space, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort spaces.
   * @PRIVATE */
  $sortSpaces: () => void;
  /** Set spaces initially.
   * @PRIVATE */
  $setSpaces: (spaces: Space[]) => void;
  /** Create or update space from WS.
   * @PRIVATE */
  $createOrUpdateSpace: (space: Space) => void;
  /** Delete space from WS.
   * @PRIVATE */
  $deleteSpace: (space: Space) => void;
};

const makeReportsFolder = (space: Space, appStore: { pageIconOptions: PageIconOption[] }): Folder => ({
  pageKind: PageKind.FOLDER,
  duid: makeDuid(),
  spaceDuid: space.duid,
  kind: FolderKind.REPORTS,
  order: String.fromCharCode(2),
  title: "Reports",
  description: "",
  iconKind: IconKind.NONE,
  iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
  colorHex: makeRandomColorHex(),
});

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  spaceDraft() {
    if (!this.spaceDraftDuid) {
      return undefined;
    }

    return this.getSpaceByDuid(this.spaceDraftDuid);
  },
  getSpaceList() {
    return (options = {}) => {
      const { includeDraft = false } = options;
      return [...this._duidsToSpaces.values()].filter((e) => includeDraft || !e.drafterDuid);
    };
  },
  getSpaceByDuid() {
    return (duid) => this._duidsToSpaces.get(duid);
  },
  getSpacesByDuidsOrdered() {
    return (duids) => this.getSpaceList({ includeDraft: true }).filter((e) => duids.includes(e.duid));
  },
  workspaceSpace() {
    if (!this.$useUserStore().isRoleGreaterOrEqual(UserRole.MEMBER)) {
      return undefined;
    }

    const workspace = this.getSpaceList().filter((e) => e.kind === SpaceKind.WORKSPACE);
    if (workspace.length !== 1) {
      throw new Error("Expected exactly one workspace space");
    }
    return workspace[0];
  },
  personalSpace() {
    const personal = this.getSpaceList().filter((e) => e.kind === SpaceKind.PERSONAL);
    if (personal.length !== 1) {
      throw new Error("Expected exactly one personal space");
    }
    return personal[0];
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createSpace(order, draftSpace, options = {}) {
    const { awaitBackend = false } = options;
    const appStore = this.$useAppStore();

    const space: Space = {
      pageKind: PageKind.SPACE,
      duid: makeDuid(),
      drafterDuid: null,
      order,
      title: "",
      description: "",
      abrev: "",
      accessibleByTeam: true,
      accessibleByUserDuids: [],
      sprintMode: SprintMode.NONE,
      sprintReplicateOnRollover: false,
      sprintNameFmt: "Sprint {N}",
      kind: SpaceKind.OTHER,
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      standupRecurrence: null,
      standupRecursNextAt: null,
      changelogRecurrence: null,
      changelogRecursNextAt: null,
      ...draftSpace,
    };

    this.$createOrUpdateSpace(space);

    const reportsFolder = makeReportsFolder(space, appStore);

    this.$createOrUpdateFolder(reportsFolder);

    if (space.drafterDuid !== null) {
      this.spaceDraftDuid = space.duid;
    }

    const backendAction = this.$backend.space.create(space, reportsFolder);
    if (awaitBackend) {
      await backendAction;
    }
    return space;
  },
  async updateSpace(update, options = {}) {
    const { awaitBackend = false } = options;
    const space = this.getSpaceByDuid(update.duid);
    if (!space) {
      return undefined;
    }

    if (space.duid === this.spaceDraft?.duid && update.drafterDuid === null) {
      this.spaceDraftDuid = null;
    }

    Object.assign(space, update);
    this.$sortSpaces();

    const backendAction = this.$backend.space.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return space;
  },
  async updateSpaceSprintMode(spaceDuid, sprintMode, options = {}) {
    const { awaitBackend = false } = options;
    const space = this.getSpaceByDuid(spaceDuid);
    if (!space) {
      return undefined;
    }

    space.sprintMode = sprintMode;

    const dartboardCreates =
      sprintMode !== SprintMode.NONE ? await this.createSprintDartboardsNoBackend(space.duid) : undefined;
    let layoutCreates;
    let userDartboardLayoutCreates;
    if (dartboardCreates !== undefined) {
      const userDuid = this.$useUserStore().duid;
      layoutCreates = [];
      userDartboardLayoutCreates = [];
      dartboardCreates.forEach((dartboard) => {
        const layout = this.getLayoutByDartboardDuid(dartboard.duid);
        if (!layout) {
          return;
        }
        layoutCreates.push(layout);
        userDartboardLayoutCreates.push({
          userDuid,
          dartboardDuid: dartboard.duid,
          layoutDuid: layout.duid,
        });
      });
    }
    const backendAction = this.$backend.space.updateSprintMode(
      {
        duid: space.duid,
        sprintMode,
      },
      dartboardCreates,
      layoutCreates,
      userDartboardLayoutCreates,
      sprintMode === SprintMode.NONE ? this.deleteSprintDartboardsNoBackend(space.duid) : undefined
    );
    if (awaitBackend) {
      await backendAction;
    }
    return space;
  },
  async updateSpaceAccessors(duid, accessorDuids, add, options = {}) {
    const { awaitBackend = false } = options;
    const space = this.getSpaceByDuid(duid);
    if (!space) {
      return undefined;
    }

    let backendAction: Promise<TransactionResponse>;

    if (add) {
      space.accessibleByUserDuids.push(...accessorDuids);
      backendAction = this.$backend.space.updateListAdd({
        duid: space.duid,
        accessibleByUserDuids: accessorDuids,
      });
    } else {
      filterInPlace(space.accessibleByUserDuids, (e) => !accessorDuids.includes(e));
      backendAction = this.$backend.space.updateListRemove({
        duid: space.duid,
        accessibleByUserDuids: accessorDuids,
      });
    }

    if (awaitBackend) {
      await backendAction;
    }

    this.$sortDartboards();

    return space;
  },
  async replicateSpace(space, order, partialSpace, options = {}) {
    const { awaitBackend = false, maintainTitle = false } = options;
    const appStore = this.$useAppStore();

    const newSpace: Space = {
      ...JSON.parse(JSON.stringify(space)),
      duid: makeDuid(),
      kind: SpaceKind.OTHER,
      order,
      ...partialSpace,
    };
    if (!maintainTitle && partialSpace?.title === undefined) {
      newSpace.title = getNextTitleInSequence(
        space.title,
        this.getSpaceList().map((e) => e.title)
      );
    }

    this.$createOrUpdateSpace(newSpace);

    const reportsFolder = makeReportsFolder(newSpace, appStore);

    this.$createOrUpdateFolder(reportsFolder);

    // TODO transactionify these calls
    const spaceCreate = this.$backend.space.create(newSpace, reportsFolder);

    const dartboards = this.getDartboardsBySpaceDuidOrdered(space.duid, { excludeFinished: true });
    const dartboardCreates = Promise.all(
      dartboards.map((dartboard) =>
        this.replicateDartboard(
          dartboard,
          dartboard.order,
          { spaceDuid: newSpace.duid, kind: dartboard.kind },
          { maintainTitle: true }
        )
      )
    );

    const folders = this.getFoldersBySpaceDuidOrdered(space.duid).filter((e) => e.kind === FolderKind.OTHER);
    const folderCreates = Promise.all(
      folders.map((folder) =>
        this.replicateFolder(folder, folder.order, { spaceDuid: newSpace.duid }, { maintainTitle: true })
      )
    );

    if (awaitBackend) {
      await Promise.all([spaceCreate, dartboardCreates, folderCreates]);
    }

    return newSpace;
  },
  async deleteSpace(space, options = {}) {
    const { awaitBackend = false } = options;
    // TODO transactionify
    this.getDartboardsBySpaceDuidOrdered(space.duid, { includeHidden: true }).forEach((e) => this.deleteDartboard(e));
    this.getFoldersBySpaceDuidOrdered(space.duid).forEach((e) => this.deleteFolder(e));

    this.$deleteSpace(space);

    if (space.duid === this.spaceDraft?.duid) {
      this.spaceDraftDuid = null;
    }

    const backendAction = this.$backend.space.delete(space.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortSpaces() {
    this._duidsToSpaces = new Map([...this._duidsToSpaces].sort((a, b) => spaceComparator(a[1], b[1])));
  },
  $setSpaces(spaces) {
    this._duidsToSpaces = new Map(spaces.map((e) => [e.duid, { ...e, pageKind: PageKind.SPACE }]));
    this.$sortSpaces();

    this.spaceDraftDuid = this.getSpaceList({ includeDraft: true }).find((e) => e.drafterDuid)?.duid ?? null;
  },
  async $createOrUpdateSpace(space) {
    const currentSpace = this.getSpaceByDuid(space.duid);

    // TODO remove this check when we are filtering out spurious updates on BE of WS
    if (
      !(
        (this.$useUserStore().isRoleGreaterOrEqual(UserRole.MEMBER) && space.accessibleByTeam) ||
        space.accessibleByUserDuids.includes(this.$useUserStore().duid)
      )
    ) {
      if (currentSpace) {
        this.$deleteSpace(space);
      }
      return;
    }

    // eslint-disable-next-line no-param-reassign
    space.pageKind = PageKind.SPACE;

    if (!currentSpace) {
      this._duidsToSpaces.set(space.duid, space);
    } else {
      Object.assign(currentSpace, space);
    }

    this.$sortSpaces();

    /* Create */
    // TODO shouldn't rely on client to get the dartboards and folders separately, this should probably come over WS
    if (!currentSpace) {
      const { data: dartboardData } = await this.$backendOld.dartboards.getBySpace(space.duid);
      dartboardData.items.forEach((dartboard: Dartboard) => {
        this.$createOrUpdateDartboard(dartboard);
      });
      const { data: folderData } = await this.$backendOld.folders.getBySpace(space.duid);
      folderData.items.forEach((folder: Folder) => {
        this.$createOrUpdateFolder(folder);
      });
    }
  },
  $deleteSpace(space) {
    this._duidsToSpaces.delete(space.duid);

    if (space.duid === this.spaceDraft?.duid) {
      this.spaceDraftDuid = null;
    }

    // TODO shouldn't rely on client to delete these, this should probably come over WS; also this is bad because it can be bad for the normal delete flow
    this.getDartboardsBySpaceDuidOrdered(space.duid, { includeHidden: true }).forEach(this.$deleteDartboard);
    this.getFoldersBySpaceDuidOrdered(space.duid).forEach(this.$deleteFolder);
  },
};

export { actions, getters };
