<script setup lang="ts">
import { createEmptyHistoryState } from "@lexical/history";
import type { Klass, LexicalEditor, LexicalNode, SerializedEditorState } from "lexical";
import {
  LexicalCollaborationPlugin,
  LexicalComposer,
  LexicalContentEditable,
  LexicalHistoryPlugin,
  LexicalRichTextPlugin,
} from "lexical-vue";
import { computed, h, ref, render } from "vue";
import { WebsocketProvider } from "y-websocket";
import * as Y from "yjs";

import { colorsByTheme } from "~/constants/style";
import { useDataStore, useEnvironmentStore, usePageStore, useUserStore } from "~/stores";

import CollaborationCursor from "./CollaborationCursor.vue";
import { LEXICAL_OUTSIDE_CLASS } from "./const";
import EditablePlugin from "./plugins/EditablePlugin.vue";
import EmojiMenuPlugin from "./plugins/EmojiMenuPlugin.vue";
import FocusPlugin from "./plugins/FocusPlugin.vue";
import HeadingNormalizationPlugin from "./plugins/HeadingNormalizationPlugin.vue";
import HorizontalRulePlugin from "./plugins/HorizontalRulePlugin.vue";
import ListNormalizationPlugin from "./plugins/ListNormalizationPlugin.vue";
import SetEditorStatePlugin from "./plugins/SetEditorStatePlugin.vue";
import TextContentPlugin from "./plugins/TextContentPlugin.vue";
import ToggleSectionPlugin from "./plugins/ToggleSectionPlugin.vue";
import EDITOR_THEME from "./theme";

const props = defineProps<{
  namespace: string;
  nodes?: Klass<LexicalNode>[];
  initialState?: SerializedEditorState;
  placeholder: string;
  disabled?: boolean;
  singleLine?: boolean;
  grow?: boolean;
  minHeightPx?: number;
  maxHeightPx?: number;
  topMarginPx?: number;
  bottomMarginPx?: number;
  leftMarginPx?: number;
  rightMarginPx?: number;
  unrounded?: boolean;
  extraRounded?: boolean;
  normallyBorderless?: boolean;
  alwaysBorderless?: boolean;
  highContrastBorder?: boolean;
  largeText?: boolean;
  extraLargeText?: boolean;
  smallLeading?: boolean;
  noWrap?: boolean;
  editorClasses?: string;
  collaboration?: {
    namespace: string;
    id: string;
  };
}>();

const emit = defineEmits<{
  blur: [];
}>();

const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const setEditorStatePlugin = ref<InstanceType<typeof SetEditorStatePlugin> | null>(null);
const focusManager = ref<InstanceType<typeof FocusPlugin> | null>(null);
const textContentPlugin = ref<InstanceType<typeof TextContentPlugin> | null>(null);
const hasFocus = ref(false);

const isEditable = computed(() => !props.disabled);

// Initial editor state; used for initializing Y.Doc as well
const initializeEditorState = (initialEditor: LexicalEditor) => {
  if (!props.initialState) {
    return;
  }

  const newState = initialEditor.parseEditorState(props.initialState);
  initialEditor.setEditorState(newState);
};

const editorConfig = {
  namespace: props.namespace,
  theme: EDITOR_THEME,
  nodes: props.nodes ?? [],
  editable: isEditable.value,
  editorState: props.collaboration ? null : initializeEditorState,
};

const topMarginPxNorm = computed(() => props.topMarginPx ?? 0);
const bottomMarginPxNorm = computed(() => `${props.bottomMarginPx ?? 0}px`);
const placeholderTopMarginPxNorm = computed(
  () =>
    `${topMarginPxNorm.value + 2 + (props.largeText || props.extraLargeText ? -2 : 2) + (props.smallLeading ? 2 : 0)}px`
);
const contentTopMarginPxNorm = computed(() => `${topMarginPxNorm.value - 2}px`);
const leftMarginPxNorm = computed(() => `${(props.leftMarginPx ?? 6) - 2}px`);

const history = createEmptyHistoryState();

const editorRef = ref<HTMLElement | null>(null);

const synced = ref(!props.collaboration);

const collaborationProviderFactory = (id: string, yjsDocMap: Map<string, Y.Doc>) => {
  const documentId = `${props.collaboration?.namespace}-${id}`;
  const doc = new Y.Doc({
    guid: documentId,
  });
  yjsDocMap.set(id, doc);

  const provider = new WebsocketProvider(`${environmentStore.wsBase}/ws/v0/texts`, documentId, doc, {
    params: { client_duid: pageStore.duid },
  });
  const onProviderSync = (isSynced: boolean) => {
    if (!isSynced) {
      return;
    }
    provider.off("sync", onProviderSync);
    synced.value = true;
  };
  provider.on("sync", onProviderSync);
  return provider;
};

const onError = (error: Error) => {
  throw error;
};

const focus = (start?: boolean) => focusManager.value?.focus(start);

const onBlur = (event: FocusEvent) => {
  let curr = event.relatedTarget as HTMLElement | null;
  for (let i = 0; i < 1000; i += 1) {
    if (!curr) {
      break;
    }
    if (curr === editorRef.value) {
      return;
    }
    if (curr.classList.contains(LEXICAL_OUTSIDE_CLASS)) {
      return;
    }
    curr = curr.parentElement;
  }

  hasFocus.value = false;
  emit("blur");
};

const setEditorState = (state: SerializedEditorState) => setEditorStatePlugin.value?.setEditorState(state);

const getTextContent = () => textContentPlugin.value?.getTextContent();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const createCursor = (cursor: { name: string; color: string }, caret: HTMLSpanElement) => {
  const user = dataStore.getUserByDuid(cursor.name);
  const node = h(CollaborationCursor, { user });
  render(node, caret);
};

defineExpose({
  focus,
  hasFocus,
  getTextContent,
  setEditorState,
});
</script>

<template>
  <TextEditor v-if="!synced" v-bind="{ ...props, disabled: true, collaboration: undefined }" />
  <LexicalComposer :initial-config="editorConfig as any" @error="onError">
    <div
      class="group/text-editor relative"
      :class="[
        editorClasses,
        {
          border: !alwaysBorderless,
          'hover:border-md': !alwaysBorderless && !highContrastBorder && !hasFocus,
          'hover:border-hvy': !alwaysBorderless && highContrastBorder && !hasFocus,
          'border-transparent': !alwaysBorderless && normallyBorderless && !hasFocus,
          'border-lt': !alwaysBorderless && !normallyBorderless && !highContrastBorder && !hasFocus,
          'border-md': !alwaysBorderless && highContrastBorder !== hasFocus,
          'border-primary-base': !alwaysBorderless && highContrastBorder && hasFocus,
          rounded: !unrounded,
          'rounded-lg': extraRounded,
          'w-full': !grow,
          'w-auto min-w-[40px]': grow,
          hidden: !synced,
        },
      ]">
      <slot name="before" />
      <div
        ref="editorRef"
        class="dart-editor relative"
        :class="collaboration ? 'dart-cursors-container' : undefined"
        :style="{ '--textColor': colors.textMd }">
        <EditablePlugin :editable="isEditable" />
        <FocusPlugin v-if="isEditable" ref="focusManager" :has-focus="hasFocus" />
        <LexicalRichTextPlugin>
          <template v-if="isEditable && placeholder" #placeholder>
            <div
              class="pointer-events-none absolute top-0 select-none pl-0.5"
              :class="{
                'text-2xl font-semibold text-gray-300 dark:text-zinc-600': extraLargeText,
                'text-vlt': !extraLargeText,
                'text-base font-semibold': largeText,
                'font-normal': !largeText && !extraLargeText,
                'text-sm/6': !largeText && !extraLargeText && !smallLeading,
                'text-sm/5': !largeText && !extraLargeText && smallLeading,
                '!whitespace-nowrap': noWrap && !singleLine,
              }"
              :style="{ marginTop: placeholderTopMarginPxNorm, marginLeft: leftMarginPxNorm }">
              {{ placeholder }}
            </div>
          </template>
          <template #contentEditable>
            <LexicalContentEditable
              auto-capitalize
              auto-complete
              auto-correct
              spellcheck
              enable-grammarly
              :editable="isEditable"
              :class="{
                rounded: !unrounded,
                'overflow-hidden !whitespace-pre': singleLine,
                'text-2xl font-semibold': extraLargeText,
                'text-base font-semibold': largeText,
                'font-normal': !largeText && !extraLargeText,
                'text-sm/6': !largeText && !extraLargeText && !smallLeading,
                'text-sm/5': !largeText && !extraLargeText && smallLeading,
                '!whitespace-nowrap': noWrap && !singleLine,
                'overflow-y-auto': maxHeightPx !== undefined,
              }"
              class="relative pl-0.5 pr-1.5 outline-none text-md focus-ring-none"
              :style="{
                minHeight: minHeightPx !== undefined ? `${minHeightPx}px` : undefined,
                maxHeight: maxHeightPx !== undefined ? `${maxHeightPx}px` : undefined,
                marginTop: contentTopMarginPxNorm,
                marginLeft: leftMarginPxNorm,
                marginRight: rightMarginPx !== undefined ? `${rightMarginPx}px` : undefined,
                paddingBottom: bottomMarginPxNorm,
              }"
              :aria-label="placeholder"
              @focus="hasFocus = true"
              @blur="onBlur"
              @keydown.esc.stop.prevent />
          </template>
        </LexicalRichTextPlugin>
        <slot :history="history" />
        <LexicalHistoryPlugin v-if="isEditable" :external-history-state="history" />
        <LexicalCollaborationPlugin
          v-if="collaboration"
          :id="collaboration.id"
          :provider-factory="collaborationProviderFactory as any"
          :initial-editor-state="initializeEditorState"
          :should-bootstrap="false"
          :cursors-container-ref="editorRef"
          :username="userStore.duid"
          :cursor-color="userStore.colorHex"
          :custom-cursor-fn="createCursor" />
        <SetEditorStatePlugin ref="setEditorStatePlugin" />
        <!-- Custom plugins -->
        <EmojiMenuPlugin v-if="isEditable" />
        <HeadingNormalizationPlugin />
        <HorizontalRulePlugin />
        <ListNormalizationPlugin />
        <TextContentPlugin ref="textContentPlugin" />
        <ToggleSectionPlugin />
      </div>
      <slot name="after" />
    </div>
  </LexicalComposer>
</template>

<style>
.dart-cursors-container > div:last-child > span > span:last-child > span:first-child {
  border-radius: 2px;
  top: unset !important;
  bottom: -16px !important;
  font-family: "Inter var" !important;
  color: var(--textColor) !important;
  padding: 2px 4px !important;
}
</style>
