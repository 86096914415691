<script setup lang="ts">
import moment from "moment-timezone";
import { DatePicker as DatePickerExternal } from "v-calendar";
import { capitalize, computed, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import NumberInput from "~/components/dumb/NumberInput.vue";
import { DAY_OF_WEEK_LETTERS_AND_NUMBERS, RECURRENCE_KIND_TO_VALUE_MAP } from "~/constants/time";
import { ChevronDownIcon, RecurrenceIcon, XIcon } from "~/icons";
import { ButtonStyle, DropdownMenuItemKind, IconSize, RecurrenceKind } from "~/shared/enums";
import type { DayOfWeek, TaskRecurrence } from "~/shared/types";
import { usePageStore, useUserStore } from "~/stores";
import { getRecurrenceSummary, isBrowserLocale24h } from "~/utils/time";

const pageStore = usePageStore();
const userStore = useUserStore();

const props = defineProps<{
  recurrence: TaskRecurrence | null;
  recursNextAt: string | null;
  showTime?: boolean;
}>();

const emit = defineEmits<{
  changeRecurrence: [recurrence: TaskRecurrence | null];
  changeRecursNextAt: [recursNextAt: string | null];
}>();

const hasRecurrence = computed(() => !!props.recurrence && !!props.recursNextAt);

const interval = ref(props.recurrence?.interval ?? 1);
const kind = ref(props.recurrence?.kind ?? RecurrenceKind.DAILY);
const weeklyDays = ref(
  props.recurrence?.kind === RecurrenceKind.WEEKLY
    ? props.recurrence.days
    : [((moment().isoWeekday() + 6) % 7) as DayOfWeek]
);

const timeDate = ref<Date>(moment().toDate());
const setTimeDate = () => {
  const start = moment(props.recursNextAt ?? undefined).startOf("minute");
  const remainder = (15 - (start.minute() % 15)) % 15;
  timeDate.value = start.add(remainder, "minutes").toDate();
};
setTimeDate();

watch(
  () => props.recurrence,
  (newRecurrence) => {
    if (!newRecurrence) {
      return;
    }

    interval.value = newRecurrence.interval;
    kind.value = newRecurrence.kind;
    weeklyDays.value =
      newRecurrence.kind === RecurrenceKind.WEEKLY
        ? newRecurrence.days
        : [((moment().isoWeekday() + 6) % 7) as DayOfWeek];
  }
);

watch(() => props.recursNextAt, setTimeDate);

const plural = computed(() => (interval.value > 1 ? "s" : ""));

const recurrenceSummary = computed(() => {
  if (!props.recurrence || !props.recursNextAt) {
    return null;
  }

  return getRecurrenceSummary(props.recurrence, moment(props.recursNextAt));
});

const deleteRecurrence = () => {
  emit("changeRecurrence", null);
};

const updateTaskRecurrence = () => {
  const recurrence = {
    kind: kind.value,
    interval: interval.value,
  } as TaskRecurrence;
  if (recurrence.kind === RecurrenceKind.WEEKLY) {
    recurrence.days = weeklyDays.value;
  }

  emit("changeRecurrence", recurrence);
};

const onTimeUpdate = (date: Date) => {
  timeDate.value = date;

  emit("changeRecursNextAt", timeDate.value.toISOString());
};

const updateRecurrenceNumber = (newNumber: number) => {
  interval.value = newNumber;
  updateTaskRecurrence();
};

const toggleSelectedDay = (day: DayOfWeek) => {
  if (weeklyDays.value.includes(day)) {
    if (weeklyDays.value.length === 1) {
      return;
    }
    weeklyDays.value = weeklyDays.value.filter((d) => d !== day);
  } else {
    weeklyDays.value = [...weeklyDays.value, day].sort();
  }
  updateTaskRecurrence();
};

const SIMPLE_RECURRENCES: TaskRecurrence[] = [
  {
    kind: RecurrenceKind.DAILY,
    interval: 1,
  },
  {
    kind: RecurrenceKind.WEEKLY,
    interval: 1,
    days: [((moment().isoWeekday() + 6) % 7) as DayOfWeek],
  },
  {
    kind: RecurrenceKind.WEEKLY,
    interval: 1,
    days: [0, 1, 2, 3, 4],
  },
  {
    kind: RecurrenceKind.MONTHLY,
    interval: 1,
  },
  {
    kind: RecurrenceKind.YEARLY,
    interval: 1,
  },
];

const simpleRecurrenceSections = [
  {
    title: "Interval",
    items: [
      ...SIMPLE_RECURRENCES.map((recurrence) => {
        const title = getRecurrenceSummary(recurrence, moment());
        return {
          title: capitalize(title),
          recurrence,
        };
      }),
      { title: "Custom", recurrence: SIMPLE_RECURRENCES[0] },
    ].map(({ title, recurrence }) => ({
      title,
      kind: DropdownMenuItemKind.BUTTON,
      onClick: () => {
        emit("changeRecurrence", recurrence);
      },
    })),
  },
];

const intervalDropdownSections = computed(() => [
  {
    title: "Recurrence",
    items: [...RECURRENCE_KIND_TO_VALUE_MAP.entries()].map(([currKind, title]) => ({
      title,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: currKind === kind.value,
      onClick: () => {
        kind.value = currKind;
        updateTaskRecurrence();
      },
    })),
  },
]);
</script>

<template>
  <DropdownMenu :disabled="hasRecurrence" :sections="simpleRecurrenceSections" prevent-close-on-select>
    <button
      type="button"
      class="flex w-full items-center justify-between gap-2 rounded border py-1.5 pl-3 pr-2 text-sm text-lt border-hvy focus-ring-std"
      :class="hasRecurrence ? 'cursor-default' : 'hover:bg-md'">
      <div class="flex items-center gap-2">
        <RecurrenceIcon class="icon-sm" :class="hasRecurrence && 'text-recurrence-base'" />
        <div class="select-none text-left text-md">
          {{ hasRecurrence ? `Repeats ${recurrenceSummary}` : "Set repetition" }}
        </div>
      </div>
      <div
        v-if="hasRecurrence"
        class="cursor-pointer rounded p-0.5 hover:bg-md"
        @click.stop="deleteRecurrence"
        @keydown.enter.stop="deleteRecurrence">
        <XIcon class="text-lt icon-xs" />
      </div>
    </button>
  </DropdownMenu>

  <div v-if="hasRecurrence" class="flex flex-col gap-2 px-1 text-md">
    <div class="flex items-center gap-4">
      <div class="text-xs">Repeat every</div>
      <div class="flex items-center gap-2">
        <NumberInput :value="interval" label="interval" @change="updateRecurrenceNumber" />

        <DropdownMenu :sections="intervalDropdownSections" :width-pixels="120" prevent-close-on-select>
          <Button
            class="!gap-1 py-0.5 pl-1 pr-0.5"
            :btn-style="ButtonStyle.SECONDARY"
            borderless
            :text="`${RECURRENCE_KIND_TO_VALUE_MAP.get(kind)}${plural}`"
            :icon-after="ChevronDownIcon"
            :icon-size="IconSize.S"
            is-contrast />
        </DropdownMenu>
      </div>
    </div>

    <div v-if="kind === RecurrenceKind.WEEKLY" class="flex flex-col gap-2">
      <div class="flex items-center gap-4">
        <div class="text-xs">On</div>
        <div class="flex items-center gap-2">
          <button
            v-for="[dowLetter, dowNumber] in DAY_OF_WEEK_LETTERS_AND_NUMBERS"
            :key="dowNumber"
            type="button"
            class="flex items-center justify-center rounded-full text-center text-xs uppercase transition-colors icon-md"
            :class="
              weeklyDays.includes(dowNumber) ? 'bg-primary-base hover-bg-primary text-oncolor' : 'bg-md hover:bg-hvy'
            "
            @click="toggleSelectedDay(dowNumber)">
            {{ dowLetter }}
          </button>
        </div>
      </div>
    </div>

    <DatePickerExternal
      v-if="showTime"
      v-model="timeDate"
      mode="time"
      color="indigo"
      is-expanded
      hide-time-header
      :minute-increment="15"
      :is-dark="pageStore.theme === 'dark'"
      :min-date="new Date()"
      :is24hr="isBrowserLocale24h()"
      :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
      :style="{ fontFamily: 'Inter var' }"
      @update:model-value="onTimeUpdate" />
  </div>
</template>

<style scoped>
:deep(.vc-time-date) {
  display: none;
}
:deep(.vc-time-picker) {
  padding: 0px !important;
}
</style>
