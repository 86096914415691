<script setup lang="ts">
import { computed, ref } from "vue";

import { FIELDS_ALWAYS_AVAILABLE_SET, getFilterDefinitionList, NO_VALUE_APPLICABILITIES } from "~/common/filter";
import { getPropertyWithConfigList } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { AI_PROPERTY_PSUEDO_DUID } from "~/constants/property";
import { PlusIcon } from "~/icons";
import { filterHasApplicability } from "~/shared/common";
import { ButtonStyle, CommandId, DropdownMenuItemKind, IconSize } from "~/shared/enums";
import type { Filter } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  filters: Filter[];
  hideAi?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  select: [propertyDuid: string];
}>();

const dataStore = useDataStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const options = computed(() => {
  const filters = new Map(props.filters.map((e) => [e.propertyDuid, e]));
  return getFilterDefinitionList(getPropertyWithConfigList()).filter((definition) => {
    if (!definition.config.available) {
      return false;
    }
    const propertyKind = dataStore.getPropertyByDuid(definition.propertyDuid)?.kind;
    const filter = filters.get(definition.propertyDuid);
    if (
      propertyKind &&
      FIELDS_ALWAYS_AVAILABLE_SET.has(propertyKind) &&
      filter &&
      !(
        filter.values.length > 0 ||
        (filterHasApplicability(filter) && NO_VALUE_APPLICABILITIES.has(filter.applicability))
      )
    ) {
      return true;
    }
    return !filter || definition.propertyDuid === AI_PROPERTY_PSUEDO_DUID;
  });
});

const dropdownSections = computed(() => [
  {
    title: "Fields",
    items: options.value.map((definition) => ({
      title: definition.title,
      kind: DropdownMenuItemKind.BUTTON,
      hidden: definition.propertyDuid === AI_PROPERTY_PSUEDO_DUID && props.hideAi,
      icon: definition.icon,
      iconArgs: {
        class: `icon-sm ${definition.propertyDuid === AI_PROPERTY_PSUEDO_DUID ? "text-recommendation-base" : ""}`,
      },
      onClick: () => emit("select", definition.propertyDuid),
    })),
  },
]);

defineExpose({
  openDropdown: () => {
    dropdownMenu.value?.open();
  },
});
</script>

<template>
  <DropdownMenu
    v-if="options.length > 0"
    ref="dropdownMenu"
    :sections="dropdownSections"
    cover
    :max-height-pixels="960">
    <Tooltip :disabled="!filters.length" :command-id="CommandId.ADD_FILTER">
      <Button
        :btn-style="ButtonStyle.SECONDARY"
        :disabled="disabled"
        :icon="PlusIcon"
        :icon-size="IconSize.S"
        a11y-label="Add filter"
        class="h-6 !gap-0.5 px-[3px] py-0 !border-md" />
    </Tooltip>
  </DropdownMenu>
</template>
