<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref, toRef, watch } from "vue";

import { getPropertyConfig } from "~/common/properties";
import AiSpinner from "~/components/dumb/AiSpinner.vue";
import Progressbar from "~/components/dumb/ProgressBar.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { ArrowRightIcon, CalendarIcon, DatesFieldIcon, DueDateFieldIcon, RecurrenceIcon, XIcon } from "~/icons";
import { EditorMode } from "~/shared/enums";
import type { PropertyAnyDates, PropertyValueForKind, TaskRecurrence } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getMsUntilNext, getRelativeTimeForDatesDate } from "~/utils/time";

const props = defineProps<{
  property: PropertyAnyDates;
  value: PropertyValueForKind<PropertyAnyDates>;
  statusDuid: string | null;
  recurrence: TaskRecurrence | null;
  loading?: boolean;
  showIfEmpty?: boolean;
  showIcon?: boolean;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  accept: [];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const startAt = toRef(() => props.value[0]);
const dueAt = toRef(() => props.value[1]);

const propertyConfig = computed(() => getPropertyConfig(props.property.kind));
const isRange = computed(
  () =>
    ("adtl" in props.property && props.property.adtl.isRange) ||
    propertyConfig.value.alwaysShowForLayouts?.includes(appStore.layoutKind)
);
const showDuration = computed(
  () =>
    ("adtl" in props.property && props.property.adtl.showDuration) ||
    propertyConfig.value.alwaysShowForLayouts?.includes(appStore.layoutKind)
);
const hasValue = computed(() => (isRange.value && startAt.value) || dueAt.value || props.recurrence);

const getValues = () => ({
  startAtStr: startAt.value ? getRelativeTimeForDatesDate(startAt.value) : null,
  dueAtStr: dueAt.value ? getRelativeTimeForDatesDate(dueAt.value) : null,
  duration: startAt.value && dueAt.value ? moment(startAt.value).to(moment(dueAt.value), true) : null,
  isActive:
    isRange.value && !!startAt.value
      ? moment(startAt.value).isSameOrBefore(moment(), "day") &&
        (!dueAt.value || moment(dueAt.value).isSameOrAfter(moment(), "day"))
      : !!dueAt.value && moment(dueAt.value).isSame(moment(), "day"),
  isOverdue: !!dueAt.value && moment(dueAt.value).isBefore(moment(), "day"),
});

const now = ref(new Date());
const values = ref<ReturnType<typeof getValues>>({
  startAtStr: null,
  dueAtStr: null,
  duration: null,
  isActive: false,
  isOverdue: false,
});

const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isChipBaseMode = computed(() => props.editorMode === EditorMode.CHIP);
const isChipMode = computed(() => isChipBaseMode.value || isChipRecommendationMode.value);
const isListMiniRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI_ROADMAP);
const isListMode = computed(
  () =>
    props.editorMode === EditorMode.LIST ||
    props.editorMode === EditorMode.LIST_MINI ||
    props.editorMode === EditorMode.LIST_MINI_TCM ||
    isListMiniRoadmapMode.value
);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isDetailBaseMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => isDetailBaseMode.value || isDetailRecommendationMode.value);
const isRecommendationMode = computed(() => isChipRecommendationMode.value || isDetailRecommendationMode.value);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const isPropertyReadOnly = computed(() => getPropertyConfig(props.property.kind).readOnly);

const isCompletedStatus = computed(() => {
  const statusKind = props.statusDuid ? dataStore.getStatusByDuid(props.statusDuid)?.kind : undefined;
  if (!statusKind) {
    return false;
  }
  return COMPLETED_STATUS_KINDS.has(statusKind);
});

let timeout: ReturnType<typeof setTimeout> | undefined;

const resetValuesAndTimeout = () => {
  now.value = new Date();
  values.value = getValues();
  clearTimeout(timeout);
  if (!startAt.value && dueAt.value) {
    return;
  }
  timeout = setTimeout(resetValuesAndTimeout, getMsUntilNext("hour"));
};

const hoverStyle = computed(() => {
  if ((isDetailBaseMode.value || isFormMode.value) && !isPropertyReadOnly.value) {
    return "hover:bg-lt";
  }
  if (isDetailRecommendationMode.value) {
    return "hover:bg-recommendation-base/20";
  }
  if (isChipBaseMode.value) {
    return "hover:bg-md";
  }
  if (isChipRecommendationMode.value) {
    return "hover:bg-recommendation-base/40 dark:hover:bg-recommendation-base/40";
  }
  return "";
});

const textStyle = computed(() => {
  if (!isPropertyReadOnly.value) {
    if (values.value.isActive && !isCompletedStatus.value) {
      return "text-primary-base dark:text-primary-base";
    }
    if (values.value.isOverdue && !isCompletedStatus.value) {
      return "text-danger-base dark:text-danger-base";
    }
  }
  return "text-md";
});

const borderStyle = computed(() => {
  if (isChipRecommendationMode.value) {
    return "border-recommendation-base/50 bg-recommendation-base/30";
  }
  if (isChipBaseMode.value) {
    return "border-oncolor";
  }
  if (isListMode.value || isTaskDetailMode.value) {
    return "border-none";
  }
  if (!isPropertyReadOnly.value) {
    if (values.value.isActive && !isCompletedStatus.value) {
      return "border-primary-base/50 dark:border-primary-base/50";
    }
    if (values.value.isOverdue && !isCompletedStatus.value) {
      return "border-danger-base/50 dark:border-danger-base/50";
    }
  }
  return "border-oncolor";
});

const iconStyle = computed(() => {
  if (!isPropertyReadOnly.value) {
    if (values.value.isActive && !isCompletedStatus.value) {
      return "text-primary-base";
    }
    if (values.value.isOverdue && !isCompletedStatus.value) {
      return "text-danger-base";
    }
  }
  if (isChipRecommendationMode.value) {
    return "text-recommendation-base";
  }
  if (isChipMode.value && (startAt.value || dueAt.value)) {
    return "text-primary-base";
  }
  return "text-md";
});

const rejectRecommendation = () => {
  emit("reject");
};

const progressPctg = computed(() => {
  if (!startAt.value || !dueAt.value || moment(now.value).isBefore(moment(startAt.value))) {
    return null;
  }
  const start = moment(startAt.value);
  const total = moment(dueAt.value).diff(start);
  const progress = moment(now.value).diff(start);
  return (progress / total) * 100;
});

resetValuesAndTimeout();

watch([() => startAt.value, () => dueAt.value], resetValuesAndTimeout);

onUnmounted(() => {
  clearTimeout(timeout);
});
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" v-if="hasValue || showIfEmpty" :text="property?.title">
    <button
      type="button"
      :class="[
        'border',
        hoverStyle,
        borderStyle,
        textStyle,
        isFormMode && '-ml-1 border-0 pl-0.5 text-sm',
        isChipMode && 'h-[26px] gap-1 px-1 py-0.5 text-sm',
        isBoardMode && 'gap-1 pl-1 pr-1.5',
        !isChipMode && !isBoardMode && 'gap-2',
        !isChipMode && !isTaskDetailMode && !isFormMode && 'h-5 text-xs',
        isTaskDetailMode && 'w-full justify-start rounded px-2 py-1 text-left',
        isPropertyReadOnly && !isBoardMode && 'cursor-default',
        !isTaskDetailMode && 'justify-center',
        isDetailRecommendationMode && 'bg-recommendation-base/10',
      ]"
      class="flex items-center rounded focus-ring-none"
      tabindex="-1">
      <div v-if="loading" class="flex size-full items-center justify-center">
        <AiSpinner class="icon-sm" />
      </div>
      <span class="sr-only">{{ property.title }}</span>
      <component
        :is="isRange ? DatesFieldIcon : isPropertyReadOnly ? CalendarIcon : DueDateFieldIcon"
        v-if="
          !loading &&
          (showIcon || isChipMode || (isFormMode && hasValue) || (isTaskDetailMode && hasValue)) &&
          (isBoardMode || !isRange || progressPctg === null)
        "
        :class="[iconStyle, isChipMode ? 'icon-sm' : 'icon-xs']"
        class="shrink-0"
        aria-hidden="true" />
      <div
        v-if="!loading && !isBoardMode && isRange && progressPctg !== null"
        class="absolute inset-0 flex items-center px-2 py-1.5">
        <Progressbar :value="progressPctg" />
      </div>
      <div
        v-if="!loading"
        class="relative flex w-full select-none items-center gap-1 hyphens-auto break-words"
        :class="[
          isTaskDetailMode && 'flex-wrap',
          !isBoardMode && isRange && progressPctg !== null && 'px-2 text-md',
          !loading && !isBoardMode && isRange && progressPctg !== null && 'py-1',
        ]">
        <template v-if="hasValue">
          <span v-if="isRange && values.startAtStr">{{ values.startAtStr }}</span>
          <ArrowRightIcon v-if="isRange && (values.startAtStr || values.dueAtStr)" class="size-3 shrink-0" />
          <span v-if="values.dueAtStr">{{ values.dueAtStr }}</span>
          <span v-if="((isRange && showDuration) || isListMiniRoadmapMode) && values.duration">
            ({{ values.duration }})
          </span>
          <RecurrenceIcon v-if="recurrence" class="size-3 shrink-0" />
        </template>
        <span v-else :class="(isTaskDetailMode || isFormMode) && 'text-vlt'">
          {{ isChipMode ? property.title : isFormMode ? "Set date" : "None" }}
        </span>
      </div>
      <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation">
        <span
          class="rounded text-md hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
          @click.stop="rejectRecommendation"
          @keydown.enter.stop="rejectRecommendation">
          <XIcon class="icon-xs" />
        </span>
      </Tooltip>
    </button>

    <div v-if="!showIfEmpty && !hasValue && loading" class="flex size-full items-center justify-center">
      <AiSpinner class="icon-sm" />
    </div>
  </component>
</template>
