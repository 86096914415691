<script setup lang="ts">
import equal from "deep-equal";
import { type ComponentPublicInstance, computed, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";
import { useRouter } from "vue-router";

import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { NONCUSTOM_VIEW_KINDS } from "~/components/visualization/constants";
import { EXCLUDED_VIEW_KINDS, SPACES_PSEUDO_SECTION_KEY } from "~/constants/page";
import { colorsByTheme } from "~/constants/style";
import { HomeIcon, InboxIcon, InsightsIcon, MyTasksIcon, PlusIcon, SpaceFieldIcon, ViewIcon } from "~/icons";
import { CommandId, DartboardKind, PageKind, SpaceKind, UserRole, ViewKind } from "~/shared/enums";
import type {
  Dartboard,
  DartboardTabConfig,
  DashboardTabConfig,
  Folder,
  FolderTabConfig,
  PageTabConfig,
  Space,
  ViewTabConfig,
} from "~/shared/types";
import type { Constructable } from "~/shared/typeUtils";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";
import { makeDartboardComparator, makeFolderComparator, stringComparator } from "~/utils/comparator";
import { downTheLine } from "~/utils/fun";
import { getOrdersBetween } from "~/utils/orderManager";

import LeftbarFooter from "./LeftbarFooter.vue";
import LeftbarHeader from "./LeftbarHeader.vue";
import PagesSection from "./PagesSection.vue";

const COMMAND_ID_TO_GO_TO_DARTBOARD_MAP = new Map<DartboardKind | ViewKind, CommandId>([
  [ViewKind.MY_TASKS, CommandId.GO_TO_MY_TASKS],
]);

type IPagesSection<T extends PageTabConfig = PageTabConfig> = ComponentExposed<typeof PagesSection<T["pageKind"]>>;

defineProps<{
  isMobileLeftbar?: boolean;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const favoritesSection = ref<IPagesSection<ViewTabConfig | DashboardTabConfig> | null>(null);

const colors = computed(() => colorsByTheme[pageStore.theme]);
const placeholderColor = computed(() => colorsByTheme[pageStore.theme].highlight);

const userIsMemberOrGreater = computed(() => userStore.isRoleGreaterOrEqual(UserRole.MEMBER));
const onInboxPage = computed(() => router.currentRoute.value.name === "inbox");
const onViewsPage = computed(() => router.currentRoute.value.name === "views");
const onHomePage = computed(() => router.currentRoute.value.name === "home_page");
const onMyTasksPage = computed(() => router.currentRoute.value.name === "my_tasks");
const onInsightsPage = computed(() => {
  const routeName = router.currentRoute.value.name;
  return routeName === "reports" || routeName === "dashboards" || routeName === "dashboard";
});

const workspaceSpace = computed(() => dataStore.workspaceSpace);
const personalSpace = computed(() => dataStore.personalSpace);
const customSpaces = computed(() => dataStore.getSpaceList().filter((e) => e.kind === SpaceKind.OTHER));

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

// Favorites
const views = computed(() =>
  dataStore.viewList.filter((e) => !EXCLUDED_VIEW_KINDS.has(e.kind) && e.kind === ViewKind.CUSTOM)
);
const dashboards = computed(() => dataStore.dashboardList);
const favoriteConfigs = computed(() =>
  [...views.value, ...dashboards.value]
    .sort((a, b) => stringComparator(a.order, b.order))
    .filter((page) => page.favoritedByUserDuids.includes(userStore.duid))
    .map((page) => ({
      ...page,
      id: `page-${page.duid}`,
      active:
        page.pageKind === PageKind.VIEW && NONCUSTOM_VIEW_KINDS.has(page.kind)
          ? appStore.currentPage?.kind === page.kind
          : appStore.currentPage?.duid === page.duid,
      commandId: page.pageKind === PageKind.VIEW ? COMMAND_ID_TO_GO_TO_DARTBOARD_MAP.get(page.kind) : undefined,
      title: getPageDisplayName(page, dataStore.getSpaceByDuid),
    }))
);

const moveFavoritePage = (_isPrimary: boolean, _category: string, page: PageTabConfig) => {
  dataStore.updatePage(
    {
      duid: page.duid,
      order: page.order,
    },
    page.pageKind
  );
};

// Spaces
const showSpaces = computed(() => userStore.getSectionExpanded(SPACES_PSEUDO_SECTION_KEY));
const toggleShowSpaces = () => {
  userStore.setSectionExpanded(SPACES_PSEUDO_SECTION_KEY, !showSpaces.value);
};

const createSpace = async () => {
  if (dataStore.spaceDraft) {
    appStore.setSpaceOpenInModal(dataStore.spaceDraft as Space);
    return;
  }

  const topSpaceOrder = customSpaces.value.length === 0 ? undefined : customSpaces.value[0].order;
  const order = getOrdersBetween(undefined, topSpaceOrder)[0];

  const draftSpace = await dataStore.createSpace(order, {
    drafterDuid: userStore.duid,
    order,
  });
  appStore.setSpaceOpenInModal(draftSpace);
};

const editSpace = (space: Space) => {
  appStore.setSpaceOpenInModal(space);
};

const moveSpace = (category: string, space: Space) => {
  if (category.toLowerCase() === "views") {
    return;
  }
  dataStore.updateSpace(space);
};

const rollover = (spaceDuid: string) => {
  downTheLine();
  dataStore.rollover(spaceDuid);
};

const pageSectionRefMap = new Map<string, IPagesSection>();
const assignPageSectionRef = (spaceDuid: string, e: IPagesSection | null) => {
  if (!e) {
    return;
  }
  pageSectionRefMap.set(spaceDuid, e);
};

// Dartboards
const dartboardConfigs = computed(() =>
  dataStore.getDartboardList().map((e) => ({
    ...e,
    id: `dartboard-${e.kind.toLowerCase()}-${e.duid}`,
    active: appStore.currentPage?.duid === e.duid,
    commandId: e.spaceDuid === workspaceSpace.value?.duid ? COMMAND_ID_TO_GO_TO_DARTBOARD_MAP.get(e.kind) : null,
    title: getPageDisplayName(e, dataStore.getSpaceByDuid),
  }))
);

const spaceDuidToDartboardsMap = computed(() => {
  const res = new Map();
  dartboardConfigs.value.forEach((e) => {
    const key = e.spaceDuid;
    if (!res.has(key)) {
      res.set(key, []);
    }
    res.get(key).push(e);
  });
  return new Map<string, DartboardTabConfig[]>(
    Array.from(res, ([k, v]) => [k, v.sort(makeDartboardComparator(dataStore.getSpaceByDuid))])
  );
});

// Folders
const folderConfigs = computed(() =>
  dataStore.folderList.map((e) => ({
    ...e,
    id: `folder-${e.kind.toLowerCase()}-${e.duid}`,
    active: appStore.currentPage?.duid === e.duid,
    title: getPageDisplayName(e, dataStore.getSpaceByDuid),
  }))
);

const spaceDuidToFoldersMap = computed(() => {
  const res = new Map();
  folderConfigs.value.forEach((e) => {
    const key = e.spaceDuid;
    if (!res.has(key)) {
      res.set(key, []);
    }
    res.get(key).push(e);
  });
  return new Map<string, FolderTabConfig[]>(
    Array.from(res, ([k, v]) => [k, v.sort(makeFolderComparator(dataStore.getSpaceByDuid))])
  );
});

// Dartboards or Folders
const addPage = (isPrimary: boolean, spaceDuid: string) => {
  const topPageOrder = (isPrimary ? spaceDuidToDartboardsMap : spaceDuidToFoldersMap).value.get(spaceDuid)?.[0]?.order;
  const order = getOrdersBetween(undefined, topPageOrder)[0];
  (isPrimary ? dataStore.createDartboard : dataStore.createFolder)(spaceDuid, order);
  pageSectionRefMap.get(spaceDuid)?.focusNewestPage(isPrimary);
};

const movePageModal = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const movingPageBundle = ref<{ page: Dartboard | Folder; newSpaceDuid: string; newOrder: string } | null>(null);
const movingPageBundleDescription = computed(() => {
  if (!movingPageBundle.value) {
    return "";
  }
  return `This ${movingPageBundle.value.page.pageKind} is currently in ${
    dataStore.getSpaceByDuid(movingPageBundle.value.page.spaceDuid)?.title
  }. Are you sure you want to move it to ${dataStore.getSpaceByDuid(movingPageBundle.value.newSpaceDuid)?.title}?`;
});

const cancelMove = () => {
  pageSectionRefMap.forEach((e) => {
    e.reset();
  });
  movingPageBundle.value = null;
};

const confirmMove = () => {
  if (!movingPageBundle.value) {
    return;
  }

  const { page, newSpaceDuid, newOrder } = movingPageBundle.value;

  dataStore.updatePage(
    {
      duid: page.duid,
      spaceDuid: newSpaceDuid,
      order: newOrder,
    },
    page.pageKind
  );
};

const spacePermissionsSame = (spaceDuidA: string, spaceDuidB: string) => {
  const spaceA = dataStore.getSpaceByDuid(spaceDuidA);
  const spaceB = dataStore.getSpaceByDuid(spaceDuidB);
  if (!spaceA || !spaceB) {
    return false;
  }
  if (spaceA.accessibleByTeam || spaceB.accessibleByTeam) {
    return spaceA.accessibleByTeam === spaceB.accessibleByTeam;
  }
  return equal(new Set(spaceA.accessibleByUserDuids), new Set(spaceB.accessibleByUserDuids), { strict: true });
};

const movePage = (isPrimary: boolean, category: string, page: Dartboard | Folder) => {
  const newSpaceDuid = category;
  const currentSpaceDuid = page.spaceDuid;

  // Show confirmation modal if page is being moved between spaces
  if (newSpaceDuid !== currentSpaceDuid) {
    if (spacePermissionsSame(newSpaceDuid, currentSpaceDuid)) {
      dataStore.updatePage(
        {
          duid: page.duid,
          spaceDuid: newSpaceDuid,
          order: page.order,
        },
        page.pageKind
      );
      return;
    }

    movingPageBundle.value = {
      page,
      newSpaceDuid,
      newOrder: page.order,
    };
    movePageModal.value?.openModal();
    return;
  }

  // Reorder page
  dataStore.updatePage(
    {
      duid: page.duid,
      order: page.order,
    },
    page.pageKind
  );
};

const getComponentProps = (space: Space) => ({
  ref: (elem: Element | ComponentPublicInstance | null) => assignPageSectionRef(space.duid, elem as never),
  pageKind: PageKind.DARTBOARD,
  pages: spaceDuidToDartboardsMap.value.get(space.duid) ?? [],
  secondaryPageKind: PageKind.FOLDER,
  secondaryPages: spaceDuidToFoldersMap.value.get(space.duid) ?? [],
  parent: space,
  onRollover: () => rollover(space.duid),
  onEditSpace: editSpace,
  onAddPage: (isPrimary: boolean) => addPage(isPrimary, space.duid),
  onMovePage: movePage,
});
</script>

<template>
  <!-- Header -->
  <LeftbarHeader :is-mobile-leftbar="isMobileLeftbar" />

  <ConfirmationDialog
    ref="movePageModal"
    :title="movingPageBundle ? `Move ${movingPageBundle.page.title}` : ''"
    :description="movingPageBundleDescription"
    confirm-text="Confirm"
    cancel-text="Cancel"
    :icon="SpaceFieldIcon"
    @confirm="confirmMove"
    @cancel="cancelMove" />

  <div
    class="dart-leftbar flex h-0 grow flex-col overflow-y-auto"
    :style="{
      '--background': isMobileLeftbar ? colors.bgStd : colors.bgLt,
      '--highlight': isMobileLeftbar ? colors.highlight : colors.borderMd,
    }">
    <nav class="space-y-5 px-1 py-2">
      <div v-if="!pageStore.isMobile" class="space-y-px">
        <!-- Home -->
        <Tooltip :command-id="CommandId.GO_TO_HOME">
          <RouterLink
            id="home"
            to="/home"
            class="group/page-tab flex h-7 w-full select-none items-center justify-between gap-1 rounded pl-4 pr-[11px] text-sm font-medium transition-colors drag-none text-lt focus-ring-lt"
            :class="onHomePage ? 'bg-md hover:bg-hvy' : 'hover:bg-md'">
            <div class="flex items-center gap-2">
              <HomeIcon class="icon-md" aria-hidden="true" />
              <span>Home</span>
            </div>
          </RouterLink>
        </Tooltip>
        <!-- My tasks -->
        <Tooltip :command-id="CommandId.GO_TO_MY_TASKS">
          <RouterLink
            id="my_tasks"
            to="/my-tasks"
            class="group/page-tab flex h-7 w-full select-none items-center justify-between gap-1 rounded pl-4 pr-[11px] text-sm font-medium transition-colors drag-none text-lt focus-ring-lt"
            :class="onMyTasksPage ? 'bg-md hover:bg-hvy' : 'hover:bg-md'">
            <div class="flex items-center gap-2">
              <MyTasksIcon class="icon-md" aria-hidden="true" />
              <span>My tasks</span>
            </div>
          </RouterLink>
        </Tooltip>
        <!-- Inbox -->
        <Tooltip :command-id="CommandId.GO_TO_INBOX">
          <RouterLink
            id="inbox"
            to="/inbox"
            class="group/page-tab flex h-7 w-full select-none items-center justify-between gap-1 rounded pl-4 pr-[11px] text-sm font-medium transition-colors drag-none text-lt focus-ring-lt"
            :class="onInboxPage ? 'bg-md hover:bg-hvy' : 'hover:bg-md'">
            <div class="flex items-center gap-2">
              <InboxIcon class="icon-md" aria-hidden="true" />
              <span>Inbox</span>
            </div>

            <div
              v-if="dataStore.unreadNotificationCount > 0"
              class="flex min-h-4 min-w-4 items-center justify-center rounded bg-primary-base px-[3px] text-[10px]/[13px] font-semibold text-oncolor">
              {{ dataStore.unreadNotificationCount }}
            </div>
          </RouterLink>
        </Tooltip>

        <!-- Insights -->
        <Tooltip v-if="userIsMemberOrGreater" :command-id="CommandId.GO_TO_DASHBOARDS">
          <RouterLink
            id="insights"
            :to="{ name: 'dashboards' }"
            class="group/page-tab flex h-7 w-full select-none items-center justify-between gap-1 rounded pl-4 pr-[11px] text-sm font-medium transition-colors drag-none text-lt focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-gray-100 dark:focus-visible:ring-offset-zinc-800"
            :class="onInsightsPage ? 'bg-md hover:bg-hvy' : 'hover:bg-md'">
            <div class="flex items-center gap-2">
              <InsightsIcon class="icon-md" aria-hidden="true" />
              <span>Insights</span>
            </div>
          </RouterLink>
        </Tooltip>

        <!-- Views -->
        <Tooltip :command-id="CommandId.GO_TO_VIEWS">
          <RouterLink
            id="views"
            to="/views"
            class="dart-link group/page-tab flex h-7 w-full select-none items-center justify-between gap-1 rounded pl-4 pr-[11px] text-sm font-medium transition-colors drag-none text-lt focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-gray-100 dark:focus-visible:ring-offset-zinc-800"
            :class="onViewsPage ? 'bg-md hover:bg-hvy' : 'hover:bg-md'">
            <div class="flex items-center gap-2">
              <ViewIcon class="icon-md" aria-hidden="true" />
              <span>Views</span>
            </div>
          </RouterLink>
        </Tooltip>
      </div>

      <!-- Favorited Pages -->
      <PagesSection
        v-if="favoriteConfigs.length > 0"
        ref="favoritesSection"
        :page-kind="PageKind.VIEW"
        :pages="favoriteConfigs"
        @move-page="moveFavoritePage" />

      <!-- Spaces -->
      <div class="flex flex-col gap-px">
        <div
          class="group/spaces flex h-5 flex-1 cursor-pointer items-center justify-between rounded pl-3.5 pr-2.5 hover:bg-md"
          @click="toggleShowSpaces"
          @keydown.enter="toggleShowSpaces">
          <span class="select-none text-xs/5 font-semibold uppercase text-vlt group-hover/spaces:text-lt">Spaces</span>
          <Tooltip v-if="userIsMemberOrGreater" :command-id="CommandId.CREATE_SPACE">
            <button
              type="button"
              class="-mt-px hidden items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-opposite/10 focus:outline-none group-hover/spaces:flex"
              aria-label="Create a space"
              @click.stop="createSpace"
              @keydown.enter.stop="createSpace">
              <PlusIcon class="text-lt icon-sm" aria-hidden="true" />
            </button>
          </Tooltip>
        </div>
        <div v-if="showSpaces" class="flex flex-col gap-2">
          <PagesSection
            v-if="userIsMemberOrGreater && workspaceSpace"
            :ref="(elem) => workspaceSpace && assignPageSectionRef(workspaceSpace.duid, elem as never)"
            :page-kind="PageKind.DARTBOARD"
            :pages="spaceDuidToDartboardsMap.get(workspaceSpace.duid) ?? []"
            :secondary-page-kind="PageKind.FOLDER"
            :secondary-pages="spaceDuidToFoldersMap.get(workspaceSpace.duid) ?? []"
            :parent="workspaceSpace"
            @rollover="rollover(workspaceSpace.duid)"
            @edit-space="editSpace"
            @add-page="(isPrimary) => workspaceSpace && addPage(isPrimary, workspaceSpace.duid)"
            @move-page="movePage" />

          <DragArea
            v-if="customSpaces.length > 0"
            ref="dragArea"
            group="leftbar"
            category="spaces"
            class="!h-auto min-h-[20px] flex-1 gap-2.5 rounded"
            drop-area-classes="bg-md/50"
            :placeholder-color="placeholderColor"
            :items="customSpaces"
            :component="PagesSection as unknown as Constructable"
            :get-component-props="getComponentProps"
            @change="moveSpace" />

          <PagesSection
            :ref="(elem) => assignPageSectionRef(personalSpace.duid, elem as never)"
            :page-kind="PageKind.DARTBOARD"
            :pages="spaceDuidToDartboardsMap.get(personalSpace.duid) ?? []"
            :secondary-page-kind="PageKind.FOLDER"
            :secondary-pages="spaceDuidToFoldersMap.get(personalSpace.duid) ?? []"
            :parent="personalSpace"
            @rollover="rollover(personalSpace.duid)"
            @edit-space="editSpace"
            @add-page="(isPrimary) => addPage(isPrimary, personalSpace.duid)"
            @move-page="movePage" />
        </div>
      </div>
    </nav>
    <div class="grow" @click="deselectAll" @keydown.enter="deselectAll" />
    <LeftbarFooter :is-mobile-leftbar="isMobileLeftbar" />
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  width: 11px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid var(--background);
  background: var(--highlight);
  border-left: none;
  border-radius: 0px;
}
</style>
