<script setup lang="ts">
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import EditText from "~/components/dumb/EditText.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { copyAndNotify } from "~/components/notifications";
import { CopyIcon, RefreshIcon } from "~/icons";
import { ButtonStyle, DialogMode, Entitlement } from "~/shared/enums";
import type { ValidationFunctionResult } from "~/shared/types";
import { useDataStore, useEnvironmentStore, useTenantStore } from "~/stores";
import { isValidLocalUrl, makeSecretWebhook, validateUrl } from "~/utils/common";

const ROTATE_SECRET_DIALOG_DESCRIPTION =
  "Rotating the webhook secret will invalidate the old secret. This can't be undone. Are you sure you want to proceed?";

const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const tenantStore = useTenantStore();

const confirmationDialog = ref<InstanceType<typeof UpgradeConfirmationDialog> | null>(null);
const urlEditorRef = ref<InstanceType<typeof EditText> | null>(null);
const toggleRef = ref<InstanceType<typeof Toggle> | null>(null);
const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.WEBHOOKS));

const webhook = computed(() => dataStore.webhookList[0]);
const webhookUrl = computed(() => webhook.value?.url ?? null);

const toggleEnabled = (newValue: boolean) => {
  if (upgradeRequired.value && !tenantStore.webhookEnabled) {
    toggleRef.value?.reset();
    confirmationDialog.value?.openModal();
    return;
  }

  urlEditorRef.value?.stopEditing();
  tenantStore.webhookEnabled = newValue;
  backendOld.workspace.edit("webhookEnabled", newValue);
};

const validateWebhookUrl = (url: string): ValidationFunctionResult => {
  if (url === "") {
    return { isValid: true };
  }
  if (isValidLocalUrl(url)) {
    return { isValid: false, error: "Enter a URL that is not local" };
  }
  if (url.startsWith(environmentStore.urlBase)) {
    return { isValid: false, error: "Enter a URL that is not from Dart" };
  }
  return validateUrl(url);
};

const changeUrl = (newUrl: string) => {
  const workingNewUrl = newUrl === "" ? null : newUrl;
  if (!webhook.value && workingNewUrl) {
    dataStore.createWebhook(workingNewUrl, String.fromCharCode(128));
    return;
  }
  if (webhook.value && !workingNewUrl) {
    dataStore.deleteWebhook(webhook.value);
    return;
  }
  if (!(webhook.value && workingNewUrl)) {
    return;
  }
  dataStore.updateWebhook({ duid: webhook.value.duid, url: workingNewUrl });
};

const copySecret = () => {
  copyAndNotify("Webhook secret", tenantStore.webhookSecret);
};

const rotateSecret = () => {
  const newSecret = makeSecretWebhook();
  tenantStore.webhookSecret = newSecret;
  backendOld.workspace.edit("webhookSecret", newSecret);
  copySecret();
};
</script>

<template>
  <div class="h-full overflow-y-scroll">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex items-center gap-4">
          <h2 class="select-none text-xl text-md">Webhooks</h2>
          <UpgradeConfirmationDialog v-if="upgradeRequired" ref="confirmationDialog" feature-action="use webhooks" />
        </div>
        <div class="select-none text-sm/relaxed text-lt">
          <p>
            Dart can be configured to send updates to an endpoint of yours to automate some common workflows and build
            other custom integrations or processes.
          </p>
          <br />
          <p>
            Read
            <a href="https://help.itsdart.com/articles/9024895-webhooks" target="_blank" rel="noopener noreferrer">
              <span class="underline text-vlt">this help center article</span>
            </a>
            for more information on how to get this configured.
          </p>
        </div>
      </div>

      <Toggle
        ref="toggleRef"
        :value="tenantStore.webhookEnabled"
        label="Enable"
        description="Receive update notifications via webhooks"
        @update="toggleEnabled" />

      <template v-if="tenantStore.webhookEnabled">
        <div class="flex select-none flex-col gap-1">
          <div class="text-md">Webhook target URL</div>
          <EditText
            ref="urlEditorRef"
            :value="webhookUrl ?? ''"
            label="Webhook target URL"
            block
            :validate="validateWebhookUrl"
            @save="changeUrl">
            <div
              class="size-full rounded border px-3 py-2 text-left text-base shadow-sm border-md hover:bg-lt"
              :class="[webhookUrl ? 'text-md' : 'text-lt']">
              {{ webhookUrl ?? "Webhook target URL" }}
            </div>
          </EditText>
        </div>

        <div class="flex select-none flex-col gap-1">
          <div class="text-md">Webhook secret</div>
          <div class="flex gap-1 text-lt">
            <pre class="grow rounded px-3 py-[7px] text-xs bg-lt">{{ tenantStore.webhookSecret }}</pre>
            <Tooltip text="Copy webhook secret">
              <Button
                :btn-style="ButtonStyle.CHIP"
                :icon="CopyIcon"
                a11y-label="Copy webhook secret"
                @click="copySecret" />
            </Tooltip>
            <ConfirmationDialog
              :mode="DialogMode.DELETE"
              title="Rotate webhook secret"
              :description="ROTATE_SECRET_DIALOG_DESCRIPTION"
              confirm-text="Proceed"
              cancel-text="Cancel"
              :icon="RefreshIcon"
              @confirm="rotateSecret">
              <Tooltip text="Rotate webhook secret">
                <Button :btn-style="ButtonStyle.CHIP" :icon="RefreshIcon" a11y-label="Rotate webhook secret" />
              </Tooltip>
            </ConfirmationDialog>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
