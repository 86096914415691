<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from "vue";
import { DynamicScroller } from "vue-virtual-scroller";

import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import TaskListAndDetail from "~/components/dumb/TaskListAndDetail.vue";
import { getNotificationEntity } from "~/components/notifications/common";
import NotificationCard from "~/components/notifications/NotificationCard.vue";
import { INBOX_PSEUDO_PAGE } from "~/constants/page";
import { EntityName, InboxFilter, TaskDetailMode } from "~/shared/enums";
import type { Notification } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

const ENTITY_KINDS_TO_OPEN_IN_TASK_DETAIL = new Set([EntityName.TASK, EntityName.COMMENT]);

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();

appStore.setCurrentPage(INBOX_PSEUDO_PAGE);

const filter = ref(InboxFilter.UNREAD);
const selected = ref<Notification | null>(null);
const justReadDuids = ref<Set<string>>(new Set());

const isFullscreen = computed(() => appStore.taskDetailMode === TaskDetailMode.FULLSCREEN);

const literallyUnreadNotifications = computed(() => dataStore.notificationList.filter((e) => !e.read));
const shownNotifications = computed(() => {
  const notifications =
    filter.value === InboxFilter.NONE
      ? dataStore.notificationList
      : dataStore.notificationList.filter((e) => !e.read || justReadDuids.value.has(e.duid));

  return notifications.filter((e) => !!getNotificationEntity(e));
});
const areUnreads = computed(
  () =>
    (filter.value === InboxFilter.UNREAD ? shownNotifications.value : literallyUnreadNotifications.value).length === 0
);

const deselectAll = () => {
  selected.value = null;
  appStore.selectedTaskDuids = new Set();
  appStore.setTaskDetailOpen(false);
};

const resetNotifications = () => {
  justReadDuids.value = new Set();
  deselectAll();
};

const getSelectedTasks = () => {
  if (selected.value === null) {
    return [];
  }

  const { mainEntityName, taskDuid } = selected.value.event;
  if (!ENTITY_KINDS_TO_OPEN_IN_TASK_DETAIL.has(mainEntityName) || taskDuid === null) {
    return [];
  }

  return dataStore.getTasksByDuidsOrdered([taskDuid]);
};

const setFilter = (newFilter: InboxFilter) => {
  filter.value = newFilter;
  resetNotifications();
};

const setSelectedNotificationDuid = (notification: Notification) => {
  selected.value = notification;
  justReadDuids.value.add(notification.duid);
  dataStore.updateNotification({ duid: notification.duid, read: true });
};

const markAllAsRead = () => {
  resetNotifications();

  if (literallyUnreadNotifications.value.length === 0) {
    return;
  }
  dataStore.updateNotifications(literallyUnreadNotifications.value.map((e) => ({ duid: e.duid, read: true })));
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.inbox = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.inbox = null;
});

defineExpose({
  areUnreads,
  filter,
  getSelectedTasks,
  setFilter,
  markAllAsRead,
});
</script>

<template>
  <TaskListAndDetail :hide-content="isFullscreen || shownNotifications.length === 0">
    <template #default>
      <DynamicScroller
        :items="shownNotifications"
        :min-item-size="80"
        focus
        key-field="duid"
        class="h-full flex-1 overflow-y-auto"
        :class="[appStore.taskDetailOpen && 'hidden', appStore.taskDetailOpen && !isFullscreen && 'md:block']"
        @click="deselectAll"
        @keydown.enter="deselectAll">
        <template #default="{ item: notification, active }">
          <NotificationCard
            :key="notification.duid"
            :notification="notification"
            :active-in-scroll="active"
            :selected="notification.duid === selected?.duid"
            :just-read="justReadDuids.has(notification.duid)"
            @select="setSelectedNotificationDuid(notification)"
            @clear="justReadDuids.delete(notification.duid)" />
        </template>
        <template #after>
          <div class="h-[300px] w-full" />
        </template>
      </DynamicScroller>
    </template>
    <template #extra>
      <PageEmptyState v-if="shownNotifications.length === 0" />
    </template>
  </TaskListAndDetail>
</template>
