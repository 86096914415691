<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import { DashboardIcon, PremiumFeatureIcon, ReportsIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { useTenantStore } from "~/stores";

const router = useRouter();
const tenantStore = useTenantStore();

const isDashboardActive = computed(() => router.currentRoute.value.name === "dashboard");
const isDashboardsActive = computed(() => router.currentRoute.value.name === "dashboards");
const isReportsActive = computed(() => router.currentRoute.value.name === "reports");

const onTabClick = (routeName: string) => {
  if (router.currentRoute.value.name === routeName) {
    return;
  }

  router.push({ name: routeName });
};
</script>

<template>
  <main class="flex size-full items-center justify-center overflow-hidden pt-10">
    <div class="flex size-full flex-col items-center">
      <div class="mx-24 flex w-full max-w-5xl items-center border-b border-md">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Dashboards"
          :text-style="isDashboardActive || isDashboardsActive ? undefined : 'text-vlt'"
          borderless
          :icon-after="tenantStore.isPremium ? undefined : PremiumFeatureIcon"
          :icon="DashboardIcon"
          class="rounded-b-none border-b-2 pb-2"
          :class="{
            'border-b-primary-base': isDashboardsActive,
            'border-b-hvy': isDashboardActive,
          }"
          @click="onTabClick('dashboards')" />
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Reports"
          borderless
          :icon="ReportsIcon"
          :text-style="isReportsActive ? undefined : 'text-vlt'"
          class="rounded-b-none border-b-2 pb-2"
          :class="isReportsActive && 'border-b-primary-base'"
          @click="onTabClick('reports')" />
      </div>
      <RouterView />
    </div>
  </main>
</template>
