import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import {
  ARCHIVE_PSEUDO_SECTION_KEY_SUFFIX,
  FILTERS_PSEUDO_SECTION_KEY,
  FOOTER_PSUEDO_SECTION_KEY,
} from "~/constants/page";
import { DEFAULT_BLOCK_COLOR } from "~/constants/style";
import { EventKind, TaskDetailMode, Theme, type TutorialName, UserRole, UserStatus } from "~/shared/enums";
import type { NotificationSettings, User } from "~/shared/types";
import { lsSet, USER_DUID_LOCAL_STORAGE_KEY } from "~/utils/localStorageManager";

type State = User & { openInNativeApp: boolean; firstDayOfWeek: number; notificationSettings: NotificationSettings };

const ROLE_KIND_TO_INDEX = Object.fromEntries(
  [UserRole.FINANCIAL_ADMIN, UserRole.TECHNICAL_ADMIN, UserRole.GUEST, UserRole.MEMBER, UserRole.ADMIN].map((e, i) => [
    e,
    i,
  ])
);

const useUserStore = defineStore("UserStore", {
  // TODO initializing all of these is a type hack that should be fixed
  state: (): State =>
    ({
      duid: "",
      status: UserStatus.DEACTIVATED,
      role: UserRole.GUEST,
      email: "",
      name: "",
      abrev: "",
      theme: Theme.SYSTEM_DEFAULT,
      colorHex: DEFAULT_BLOCK_COLOR,
      taskDetailMode: TaskDetailMode.RIGHTBAR,
      imageUrl: null,
      isAdmin: false,
      authToken: null,
      googleData: null,
      sections: { expanded: [], collapsed: [] },
      layout: { rightbarWidthPx: 650, leftbarWidthPx: 225, fullscreenRightbarWidthPx: 600 },
      openInNativeApp: false,
      firstDayOfWeek: 1,
      notificationSettings: { default: false, inApp: false, email: false, slack: false, subscriptions: new Map() },
      tutorialStatusMap: new Map(),
    }) as State,
  getters: {
    firstName: (state) => (state.name.length > 0 ? state.name.split(" ")[0] : state.email.split("@")[0]),
    getTutorialStatus: (state) => (name: TutorialName) => state.tutorialStatusMap.get(name),
    isRoleGreaterOrEqual: (state) => (role: UserRole) => ROLE_KIND_TO_INDEX[state.role] >= ROLE_KIND_TO_INDEX[role],
    rightbarWidthPx: (state) => state.layout.rightbarWidthPx ?? 650,
    leftbarWidthPx: (state) => state.layout.leftbarWidthPx ?? 225,
    fullscreenRightbarWidthPx: (state) => state.layout.fullscreenRightbarWidthPx ?? 600,
  },
  actions: {
    setUser(user: User) {
      if (user.duid !== this.duid) {
        lsSet(USER_DUID_LOCAL_STORAGE_KEY, user.duid);
      }
      // TODO try this instead here and in tenantStore
      // Object.assign(this, user);

      this.duid = user.duid;
      this.status = user.status;
      this.role = user.role;
      this.email = user.email;
      this.name = user.name;
      this.abrev = user.abrev;
      this.theme = user.theme;
      this.colorHex = user.colorHex;
      this.taskDetailMode = user.taskDetailMode;
      if (!this.sections) {
        this.sections = user.sections;
      } else {
        Object.assign(this.sections, user.sections);
      }
      if (!this.layout) {
        this.layout = user.layout;
      } else {
        Object.assign(this.layout, user.layout);
      }
      this.imageUrl = user.imageUrl;
      this.isAdmin = user.isAdmin;
      this.authToken = user.authToken;
      if (!this.googleData) {
        this.googleData = user.googleData;
      } else {
        Object.assign(this.googleData, user.googleData);
      }

      this.$backend.event.create(EventKind.AUTHENTICATE);
    },
    setTheme(theme: Theme) {
      this.theme = theme;
      this.$usePageStore().updateTheme();
      this.$backendOld.profile.edit("theme", theme);
    },
    setTutorialStatusMap(tutorialStatusMap: Record<number, number>) {
      this.tutorialStatusMap = new Map(
        Object.entries(tutorialStatusMap).map(([k, v]) => [parseInt(k, 10), v as number])
      );
    },
    updateTutorialStatuses(updates: { name: TutorialName; status: number }[], allowRegressions?: boolean) {
      const allowedUpdates = allowRegressions
        ? updates
        : updates.filter(({ name, status }) => status > (this.getTutorialStatus(name) ?? 0));
      if (allowedUpdates.length === 0) {
        return;
      }
      allowedUpdates.forEach(({ name, status }) => {
        this.tutorialStatusMap.set(name, status);
      });
      this.$backendOld.profile.updateTutorialStatuses(allowedUpdates);
    },
    setNotificationSettings(notificationSettings: NotificationSettings) {
      this.notificationSettings = {
        ...notificationSettings,
        subscriptions: new Map(
          Object.entries(notificationSettings.subscriptions).map(([k, v]) => [parseInt(k, 10), v])
        ),
      };
    },
    setOpenInNativeApp(openInNativeApp: boolean) {
      this.openInNativeApp = openInNativeApp;
    },
    setFirstDayOfWeek(firstDayOfWeek: number) {
      this.firstDayOfWeek = firstDayOfWeek;
    },
    getSectionExpanded(key: string) {
      if (this.sections.expanded.includes(key)) {
        return true;
      }
      if (this.sections.collapsed.includes(key)) {
        return false;
      }
      return !key.endsWith(ARCHIVE_PSEUDO_SECTION_KEY_SUFFIX);
    },
    setSectionExpanded(key: string, value: boolean) {
      if (value) {
        this.sections.expanded = [...this.sections.expanded.filter((e) => e !== key), key];
        this.sections.collapsed = this.sections.collapsed.filter((e) => e !== key);
      } else {
        this.sections.expanded = this.sections.expanded.filter((e) => e !== key);
        this.sections.collapsed = [...this.sections.collapsed.filter((e) => e !== key), key];
      }
      if (this.$usePageStore().isPublicView) {
        return;
      }
      this.$backend.user.update({ duid: this.duid, sections: this.sections });
    },
    setRightbarWidthPx(rightbarWidthPx: number) {
      this.layout.rightbarWidthPx = rightbarWidthPx;
      if (this.$usePageStore().isPublicView) {
        return;
      }
      this.$backend.user.update({ duid: this.duid, layout: this.layout });
    },
    setLeftbarWidthPx(leftbarWidthPx: number) {
      this.layout.leftbarWidthPx = leftbarWidthPx;
      if (this.$usePageStore().isPublicView) {
        return;
      }
      this.$backend.user.update({ duid: this.duid, layout: this.layout });
    },
    setFullscreenRightbarWidthPx(fullscreenRightbarWidthPx: number) {
      this.layout.fullscreenRightbarWidthPx = fullscreenRightbarWidthPx;
      if (this.$usePageStore().isPublicView) {
        return;
      }
      this.$backend.user.update({ duid: this.duid, layout: this.layout });
    },
    getShowFilters(): boolean {
      return this.getSectionExpanded(FILTERS_PSEUDO_SECTION_KEY);
    },
    setShowFilters(newShowFilters: boolean) {
      this.setSectionExpanded(FILTERS_PSEUDO_SECTION_KEY, newShowFilters);
    },
    getShowFooter(): boolean {
      return this.getSectionExpanded(FOOTER_PSUEDO_SECTION_KEY);
    },
    setShowFooter(newShowFooter: boolean) {
      this.setSectionExpanded(FOOTER_PSUEDO_SECTION_KEY, newShowFooter);
    },
    setTaskDetailMode(taskDetailMode: TaskDetailMode) {
      this.taskDetailMode = taskDetailMode;
      this.$backendOld.profile.edit("taskDetailMode", taskDetailMode);
    },
    forceLogout() {
      this.$router.push({ name: "logout" });
      try {
        throw new Error("Forced logout");
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  },
});

export default useUserStore;
