<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { DotsHorizontalIcon } from "~/icons";
import { Placement } from "~/shared/enums";
import type { Comment } from "~/shared/types";

const BUTTON_STYLE = "flex items-center rounded p-0.5 text-lt hover:bg-lt focus-ring-std";

const props = defineProps<{
  comment: Comment;
}>();

const emit = defineEmits<{
  startEdit: [];
  delete: [];
}>();

const optionsDropdown = computed(() =>
  actions.context.comment(props.comment, (eventKind: string) => {
    if (eventKind === "startEdit") {
      emit("startEdit");
      return;
    }
    if (eventKind === "delete") {
      emit("delete");
    }
  })
);
</script>

<template>
  <DropdownMenu :sections="optionsDropdown" :placement="Placement.BOTTOM_RIGHT" :width-pixels="160" :distance="2">
    <Tooltip text="Options">
      <button type="button" :class="BUTTON_STYLE">
        <span class="sr-only">Options</span>
        <DotsHorizontalIcon class="icon-sm" aria-hidden="true" />
      </button>
    </Tooltip>
  </DropdownMenu>
</template>
