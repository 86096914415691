<script setup lang="ts">
import { computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import EmptyState from "~/components/dumb/EmptyState.vue";
import FilterArea from "~/components/filters/FilterArea.vue";
import { doesTaskPass } from "~/components/filters/utils";
import TaskStatistics from "~/components/statistics/TaskStatistics.vue";
import { PremiumFeatureIcon } from "~/icons";
import { goHomeNextTick, makeLinkToSettingsRef } from "~/router/common";
import { ButtonSize, ButtonStyle } from "~/shared/enums";
import type { StatisticConfig } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";
import { checkout } from "~/utils/billing";

const props = defineProps<{
  dashboardDuid: string;
  slugSep?: string;
  slug?: string;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const dashboard = computed(() => dataStore.getDashboardByDuid(props.dashboardDuid));
watchEffect(() => {
  if (!dashboard.value) {
    goHomeNextTick();
    return;
  }

  appStore.setCurrentPage(dashboard.value);
});

const redirectToBilling = () => router.push(makeLinkToSettingsRef("billing").value);
const upgradePlan = () => {
  checkout(router.currentRoute.value.fullPath);
};

/* Task statistics */
const statisticTasks = computed(() =>
  dataStore.getTaskList({ includeTrashed: true }).filter((task) => doesTaskPass(dataStore, task, appStore.filters))
);

const onUpdateConfigs = (configs: StatisticConfig[]) => {
  if (!dashboard.value) {
    return;
  }

  dataStore.updateDashboard({
    duid: dashboard.value.duid,
    charts: configs,
  });
};
</script>

<template>
  <div class="relative flex size-full flex-col items-center gap-2 pt-6">
    <FilterArea
      class="w-full max-w-[1048px] !border-0 px-[11px] bg-std"
      :class="{ 'pointer-events-none opacity-30 blur-sm': !tenantStore.isPremium }"
      is-search-mode
      :filters="appStore.filters"
      @set-filter="appStore.setFilter"
      @remove-filter="appStore.removeFilter" />
    <div class="size-full max-w-[1058px] pb-[300px]" :class="tenantStore.isPremium && 'overflow-y-scroll'">
      <TaskStatistics
        class="w-full flex-col"
        :class="{ 'pointer-events-none opacity-30 blur-sm': !tenantStore.isPremium }"
        :tasks="statisticTasks"
        :statistics="dashboard?.charts ?? []"
        @update="onUpdateConfigs" />

      <EmptyState
        v-if="!tenantStore.isPremium"
        title="Upgrade to premium"
        description="Your workspace is on the free plan. To use dashboards and enjoy other benefits, upgrade to premium."
        :main-icon="PremiumFeatureIcon"
        :button-icon="PremiumFeatureIcon"
        button-text="Upgrade!"
        @click="upgradePlan">
        <template #extraButton>
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="See plans"
            :size="ButtonSize.SMALL"
            @click="redirectToBilling" />
        </template>
      </EmptyState>
    </div>
  </div>
</template>
