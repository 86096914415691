<script setup lang="ts">
import { nextTick } from "vue";
import { useRouter } from "vue-router";

import { UserRole, ViewKind } from "~/shared/enums";
import { useDataStore, useUserStore } from "~/stores";
import { getPageLink } from "~/utils/common";

const router = useRouter();
const dataStore = useDataStore();
const userStore = useUserStore();

// TODO remove these hacks when guest permissions are better and they don't load the workspace space and/or when the workspace space is not required
const userIsBelowMember = !userStore.isRoleGreaterOrEqual(UserRole.MEMBER);
const link = getPageLink(
  userIsBelowMember ? dataStore.getViewByKind(ViewKind.MY_TASKS) : dataStore.defaultDartboard,
  dataStore.getSpaceByDuid
);

nextTick(() => {
  router.push({ ...link, query: router.currentRoute.value.query });
});
</script>

<template>
  <slot />
</template>
