import type { Component } from "vue";

import { UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyWithConfig } from "~/common/properties";
import GenericSubdropdown from "~/components/dumb/GenericSubdropdown.vue";
import {
  BarChartIcon,
  BurnUpChartIcon,
  GlobeIcon,
  LineChartIcon,
  NumberChartIcon,
  PieChartIcon,
  TableChartIcon,
} from "~/icons";
import { DropdownMenuItemKind, NumberChartAggregation, PieChartDisplayMetric, StatisticType } from "~/shared/enums";
import type { DropdownMenuItem, GroupByGroup, Property, StatisticConfig } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

export type StatisticDefinition<T = StatisticType> = {
  type: T;
  icon: Component;
  sizes?: { minH?: number; minW?: number; defaultH?: number; defaultW?: number; maxH?: number; maxW?: number };
  configOptions: (config: StatisticConfig<T>, updateConfig: (e: StatisticConfig<T>) => void) => DropdownMenuItem[];
  getDefaultTitle: (config: StatisticConfig<T>) => string;
};

const getGroupByGroups = (propertyDuid: string) =>
  useAppStore().groupByDefinitionList.find((e) => e.property.duid === propertyDuid)?.groups ?? [];

type DropdownItem = {
  title: string;
  selected: boolean;
  icon?: Component;
  iconArgs?: object;
  onClick: () => void;
};
const createOptionSubmenu = (
  title: string,
  items: DropdownItem[],
  options?: {
    disabled?: boolean;
    disabledReason?: string;
    preventCloseOnSelect?: boolean;
  }
): DropdownMenuItem => ({
  title,
  kind: DropdownMenuItemKind.COMPONENT,
  component: GenericSubdropdown,
  disabled: options?.disabled,
  disabledReason: options?.disabledReason,
  componentArgs: {
    preventCloseOnSelect: options?.preventCloseOnSelect,
    title,
    sections: [
      {
        title,
        items: items.map((item) => ({
          title: item.title,
          kind: DropdownMenuItemKind.BUTTON,
          disabled: item.selected,
          icon: item.icon,
          iconArgs: item.iconArgs,
          onClick: item.onClick,
        })),
      },
    ],
  },
});

const createPropertySubmenu = (
  title: string,
  selectedPropertyDuid: string | null,
  onClick: (property: Property, groups: GroupByGroup[]) => void,
  allowNull = false
): DropdownMenuItem => {
  const items: DropdownItem[] = useAppStore()
    .groupByDefinitionList.filter((e) => allowNull || e.property.duid !== UNGROUPED_PSEUDO_GROUP_BY)
    .map((option) => ({
      title: option.property.title,
      selected: selectedPropertyDuid
        ? option.property.duid === selectedPropertyDuid
        : option.property.duid === UNGROUPED_PSEUDO_GROUP_BY,
      icon: option.icon,
      onClick: () => onClick(option.property, option.groups),
    }));
  return createOptionSubmenu(title, items, { preventCloseOnSelect: true });
};

const BAR_STATISTIC: StatisticDefinition<StatisticType.BAR> = {
  type: StatisticType.BAR,
  icon: BarChartIcon,
  sizes: { minH: 4, minW: 6, defaultH: 4, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("X Axis property", config.adtl.xPropertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, xPropertyDuid: property.duid } })
    ),
    createPropertySubmenu(
      "Stack property",
      config.adtl.stackPropertyDuid,
      (property) => updateConfig({ ...config, adtl: { ...config.adtl, stackPropertyDuid: property.duid } }),
      true
    ),
  ],
  getDefaultTitle: (config) => {
    const dataStore = useDataStore();
    const { xPropertyDuid, stackPropertyDuid } = config.adtl;
    const xProperty = dataStore.getPropertyByDuid(xPropertyDuid);
    const stackProperty = stackPropertyDuid ? dataStore.getPropertyByDuid(stackPropertyDuid) : undefined;
    const xTitle = (xProperty?.title ?? "").toLowerCase();
    const stackTitle = (stackProperty?.title ?? "").toLowerCase();
    return `Tasks by ${xTitle}${stackTitle ? ` and ${stackTitle}` : ""}`;
  },
};

const BURN_UP_STATISTIC: StatisticDefinition<StatisticType.BURN_UP> = {
  type: StatisticType.BURN_UP,
  icon: BurnUpChartIcon,
  sizes: { minH: 12, minW: 12, defaultH: 12, defaultW: 12 },
  configOptions: () => [],
  getDefaultTitle: () => "Burnup chart",
};

const LINE_STATISTIC: StatisticDefinition<StatisticType.LINE> = {
  type: StatisticType.LINE,
  icon: LineChartIcon,
  sizes: { minH: 5, minW: 6, defaultH: 5, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("X Axis property", config.adtl.xPropertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, xPropertyDuid: property.duid } })
    ),
    createPropertySubmenu(
      "Group by property",
      config.adtl.groupByPropertyDuid,
      (property) =>
        updateConfig({
          ...config,
          adtl: {
            ...config.adtl,
            groupByPropertyDuid: property.duid === UNGROUPED_PSEUDO_GROUP_BY ? null : property.duid,
          },
        }),
      true
    ),
  ],
  getDefaultTitle: (config) => {
    const dataStore = useDataStore();
    const { xPropertyDuid, groupByPropertyDuid } = config.adtl;
    const xProperty = dataStore.getPropertyByDuid(xPropertyDuid);
    const groupByProperty = groupByPropertyDuid ? dataStore.getPropertyByDuid(groupByPropertyDuid) : undefined;
    const xTitle = (xProperty?.title ?? "").toLowerCase();
    const groupByTitle = (groupByProperty?.title ?? "").toLowerCase();
    return `Tasks ${groupByProperty ? "over" : "by"} ${xTitle}${groupByProperty ? ` by ${groupByTitle}` : ""}`;
  },
};

const NUMBER_STATISTIC: StatisticDefinition<StatisticType.NUMBER> = {
  type: StatisticType.NUMBER,
  icon: NumberChartIcon,
  sizes: { minH: 3, minW: 2, defaultH: 3, defaultW: 2 },
  configOptions: (config, updateConfig) => {
    const noPropertySelected = config.adtl.propertyDuid === null;
    const propertyDuid = config.adtl.propertyDuid || UNGROUPED_PSEUDO_GROUP_BY;
    const values = getGroupByGroups(propertyDuid);
    const propertyWithConfig = getPropertyWithConfig(propertyDuid);

    return [
      createPropertySubmenu(
        "Property",
        config.adtl.propertyDuid,
        (property) =>
          updateConfig({
            ...config,
            adtl: {
              ...config.adtl,
              propertyDuid: property.duid === UNGROUPED_PSEUDO_GROUP_BY ? null : property.duid,
              filterByValueId: undefined,
            },
          }),
        true
      ),
      createOptionSubmenu(
        "Filter by value",
        noPropertySelected
          ? [{ title: "-", selected: false, onClick: () => {} }]
          : [
              {
                title: "None",
                selected: config.adtl.filterByValueId === undefined,
                icon: GlobeIcon,
                iconArgs: { class: "icon-sm" },
                onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, filterByValueId: undefined } }),
              },
              ...values.map((value) => ({
                title: value.title,
                selected: config.adtl.filterByValueId === value.id,
                icon: value.icon,
                iconArgs: value.iconArgs,
                onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, filterByValueId: value.id } }),
              })),
            ],
        {
          disabled: noPropertySelected,
          disabledReason: "Select a property to enable this option",
          preventCloseOnSelect: true,
        }
      ),
      createOptionSubmenu(
        "Aggregation",
        [
          {
            title: "Sum",
            selected: config.adtl.aggregation === NumberChartAggregation.SUM,
            onClick: () =>
              updateConfig({ ...config, adtl: { ...config.adtl, aggregation: NumberChartAggregation.SUM } }),
          },
          {
            title: "Average",
            selected: config.adtl.aggregation === NumberChartAggregation.AVG,
            onClick: () =>
              updateConfig({ ...config, adtl: { ...config.adtl, aggregation: NumberChartAggregation.AVG } }),
          },
          {
            title: "Count",
            selected: config.adtl.aggregation === NumberChartAggregation.COUNT,
            onClick: () =>
              updateConfig({ ...config, adtl: { ...config.adtl, aggregation: NumberChartAggregation.COUNT } }),
          },
        ],
        {
          disabled: noPropertySelected || !(propertyWithConfig && propertyWithConfig[1].isNumeric),
          disabledReason: "Select a numeric property to enable this option",
        }
      ),
    ];
  },
  getDefaultTitle: (config) => {
    const { propertyDuid, aggregation, filterByValueId } = config.adtl;
    if (propertyDuid === null) {
      return "Tasks";
    }

    const values = getGroupByGroups(propertyDuid);

    const property = useDataStore().getPropertyByDuid(propertyDuid);
    const propertyTitle = (property?.title ?? "").toLowerCase();
    const valueTitle = filterByValueId
      ? (values.find((e) => e.id === filterByValueId)?.title ?? filterByValueId)
      : "All";
    return aggregation === "count"
      ? `${valueTitle} ${propertyTitle} tasks`
      : [aggregation === "avg" ? "Average" : "Sum", "of", valueTitle, propertyTitle, valueTitle ? "tasks" : ""]
          .filter((e) => !!e && e !== " ")
          .join(" ");
  },
};

const PIE_STATISTIC: StatisticDefinition<StatisticType.PIE> = {
  type: StatisticType.PIE,
  icon: PieChartIcon,
  sizes: { minH: 5, minW: 4, defaultH: 5, defaultW: 4 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("Property", config.adtl.propertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, propertyDuid: property.duid } })
    ),
    createOptionSubmenu("Type", [
      {
        title: "Count",
        selected: config.adtl.displayMetric === PieChartDisplayMetric.COUNT,
        onClick: () =>
          updateConfig({ ...config, adtl: { ...config.adtl, displayMetric: PieChartDisplayMetric.COUNT } }),
      },
      {
        title: "Percentage",
        selected: config.adtl.displayMetric === PieChartDisplayMetric.PCT,
        onClick: () => updateConfig({ ...config, adtl: { ...config.adtl, displayMetric: PieChartDisplayMetric.PCT } }),
      },
    ]),
  ],
  getDefaultTitle: (config) => {
    const { propertyDuid } = config.adtl;
    const property = useDataStore().getPropertyByDuid(propertyDuid);
    const title = (property?.title ?? "").toLowerCase();
    return `Tasks by ${title}`;
  },
};

const TABLE_STATISTIC: StatisticDefinition<StatisticType.TABLE> = {
  type: StatisticType.TABLE,
  icon: TableChartIcon,
  sizes: { minH: 4, minW: 6, defaultH: 5, defaultW: 6 },
  configOptions: (config, updateConfig) => [
    createPropertySubmenu("Column property", config.adtl.columnPropertyDuid, (property) =>
      updateConfig({ ...config, adtl: { ...config.adtl, columnPropertyDuid: property.duid } })
    ),
    createPropertySubmenu(
      "Row property",
      config.adtl.rowPropertyDuid,
      (property) => updateConfig({ ...config, adtl: { ...config.adtl, rowPropertyDuid: property.duid } }),
      true
    ),
  ],
  getDefaultTitle: (config) => {
    const dataStore = useDataStore();
    const { columnPropertyDuid, rowPropertyDuid } = config.adtl;
    const columnProperty = dataStore.getPropertyByDuid(columnPropertyDuid);
    const rowProperty = rowPropertyDuid ? dataStore.getPropertyByDuid(rowPropertyDuid) : undefined;
    const columnTitle = (columnProperty?.title ?? "").toLowerCase();
    const rowTitle = (rowProperty?.title ?? "").toLowerCase();
    return `Tasks by ${columnTitle}${rowProperty ? ` and ${rowTitle}` : ""}`;
  },
};

export const STATISTIC_DEFINITIONS: { [T in StatisticType]: StatisticDefinition<T> } = {
  [StatisticType.BAR]: BAR_STATISTIC,
  [StatisticType.BURN_UP]: BURN_UP_STATISTIC,
  [StatisticType.LINE]: LINE_STATISTIC,
  [StatisticType.NUMBER]: NUMBER_STATISTIC,
  [StatisticType.PIE]: PIE_STATISTIC,
  [StatisticType.TABLE]: TABLE_STATISTIC,
};

export const getStatisticDefinition = <T extends StatisticType = StatisticType>(type: T): StatisticDefinition<T> =>
  STATISTIC_DEFINITIONS[type];
