import type { ApexOptions } from "apexcharts";
import moment, { type Moment } from "moment";

import { colorsByTheme } from "~/constants/style";
import type { DateRange } from "~/shared/types";
import { usePageStore } from "~/stores";
import { isBrowserLocale24h } from "~/utils/time";

export enum StatKind {
  START = 1,
  PERIOD = 2,
  END = 3,
}
export type Stat = { [assigneeDuid: string]: { 0: string; 1: number | null; 2?: StatKind }[] };
export type DartboardStats = {
  scopeCount: Stat;
  scopePoints: Stat;
  startedCount: Stat;
  startedPoints: Stat;
  completedCount: Stat;
  completedPoints: Stat;
};

export const TOTAL_DUID = "@Total";
export const UNASSIGNED_DUID = "@Unassigned";

const formatData = (data: Stat, duids: string[]): [number, number | null][] => {
  const formattedData: [number, number | null][] = Object.entries(data)
    .flatMap(([key, points]) => (duids.includes(key) ? points : []))
    .map((e) => [moment(e[0]).valueOf(), e[1]]);

  // Extend to today
  if (formattedData.length) {
    const lastPoint = formattedData[formattedData.length - 1];
    formattedData.push([moment().valueOf(), lastPoint[1]]);
  }

  return formattedData;
};

const getNextStartOfDay = (date: Moment, day: number): Moment => {
  const nextDay = date.clone().startOf("isoWeek").add(day, "day");
  if (nextDay.isBefore(date)) {
    return nextDay.add(1, "week");
  }
  return nextDay;
};

const isDateWeekend = (date: Moment): boolean => {
  const day = date.day();
  return day === 0 || day === 6;
};

export default (
  stats: DartboardStats | null,
  assigneeDuids: string[],
  dates: DateRange,
  isLoading: boolean
): ApexOptions => {
  const pageStore = usePageStore();

  const colors = colorsByTheme[pageStore.theme];
  const isDark = pageStore.theme === "dark";
  const scopeColor = colors.primary;
  const startedColor = colors.warning;
  const completedColor = colors.success;
  const targetColor = colors.opposite;

  const chart: ApexOptions = {
    theme: {
      mode: isDark ? "dark" : "light",
    },
    series: [
      { name: "Scope", data: [] },
      { name: "Started", data: [] },
      { name: "Completed", data: [] },
      { name: "Target", type: "line", data: [] },
    ],
    colors: [scopeColor, startedColor, completedColor, targetColor],
    chart: {
      height: "100%",
      width: "100%",
      foreColor: colors.textMd,
      background: "transparent",
      type: "area",
      stacked: false,
      zoom: {
        zoomedArea: {
          fill: { color: colors.primary },
          stroke: { color: colors.primary },
        },
        enabled: true,
        type: "x",
        allowMouseWheelZoom: true,
      },
      toolbar: {
        show: false,
      },
      events: {
        mounted: (c) => {
          // Chart is not correct width on first render
          c.windowResizeHandler();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: ["stepline", "stepline", "stepline", "straight"],
      colors: [scopeColor, startedColor, completedColor, targetColor],
      width: 2,
      dashArray: [0, 0, 0, 4],
    },
    legend: {
      showForNullSeries: true,
      showForZeroSeries: true,
      showForSingleSeries: true,
      show: true,
      horizontalAlign: "right",
      labels: {},
      markers: {
        offsetX: -3,
        offsetY: 2,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    fill: {
      type: ["solid", "solid", "gradient", "solid"],
      colors: ["transparent", "transparent", completedColor, "transparent"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 0.2,
        stops: [0, 95, 100],
        gradientToColors: [completedColor],
      },
    },
    grid: {
      show: true,
      borderColor: colors.borderMd,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      title: {
        text: "Points",
      },
      axisBorder: {
        show: true,
        color: colors.borderMd,
        offsetX: -1,
      },
      min: 0,
    },
    xaxis: {
      type: "datetime",
      categories: [],
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: true,
        color: colors.borderMd,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: isBrowserLocale24h() ? "HH:mm" : "h:mm A",
        },
      },
    },
    annotations: {
      xaxis: isLoading
        ? []
        : [
            {
              x: new Date().getTime(),
              fillColor: colors.primary,
              borderColor: colors.primary,
              strokeDashArray: 0,
              borderWidth: 2,
            },
          ],
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      shared: true,
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
        const hoverIndexes = w.globals.seriesX.map((seriesX: number[]) => {
          let min = Math.abs(seriesX[0] - hoverXaxis);
          let minIndex = 0;
          for (let i = 1; i < seriesX.length; i += 1) {
            const v = Math.abs(seriesX[i] - hoverXaxis);
            if (v < min) {
              min = v;
              minIndex = i;
            }
          }
          return minIndex;
        });

        /* Series data */
        const TARGET_SERIES_INDEX = 3;
        let hoverList = "";
        hoverIndexes.forEach((hoverIndex: number, seriesEachIndex: number) => {
          const seriesTitle = w.globals.seriesNames[seriesEachIndex];
          let points = series[seriesEachIndex][hoverIndex];

          // Interpolate points for target series
          if (seriesEachIndex === TARGET_SERIES_INDEX) {
            const xValue: number = w.globals.seriesX[seriesIndex][dataPointIndex];
            const seriesXValues: number[] = w.globals.seriesX[TARGET_SERIES_INDEX];
            const seriesYValues: number[] = series[seriesEachIndex];
            // Ensure xValue lies within the bounds of seriesXValues
            if (xValue < seriesXValues[0] || xValue > seriesXValues[seriesXValues.length - 1]) {
              points = xValue < seriesXValues[0] ? seriesYValues[0] : seriesYValues[seriesYValues.length - 1];
            } else {
              const indexOfGreaterThan = seriesXValues.findIndex((e) => e > xValue);
              if (indexOfGreaterThan !== -1 && indexOfGreaterThan > 0) {
                const x1 = seriesXValues[indexOfGreaterThan - 1];
                const x2 = seriesXValues[indexOfGreaterThan];
                const y1 = seriesYValues[indexOfGreaterThan - 1];
                const y2 = seriesYValues[indexOfGreaterThan];
                points = Math.round(y1 + ((y2 - y1) * (xValue - x1)) / (x2 - x1));
              }
            }
          }

          points = w.globals.yLabelFormatters[0](Number.isNaN(points) ? 0 : points);
          if (hoverIndex >= 0 && points !== undefined) {
            hoverList += `
            <div class="apexcharts-tooltip-series-group apexcharts-active">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">${seriesTitle}</span>
                <span class="apexcharts-tooltip-text-y-value">${points} pts</span>
              </div>
            </div>`;
          }
        });

        const title = moment(hoverXaxis).format(isBrowserLocale24h() ? "H:mm D MMM yyyy" : "h:mm A D MMM yyyy");
        return `<div class="apexcharts-tooltip-title">${title}</div>${hoverList}`;
      },
    },
  };
  const allScopePoints = Object.entries(stats?.scopePoints ?? {}).flatMap(([key, points]) =>
    assigneeDuids.includes(key) ? points : []
  );
  const allCompletedPoints = Object.entries(stats?.completedPoints ?? {}).flatMap(([key, points]) =>
    assigneeDuids.includes(key) ? points : []
  );
  if (!stats || !stats.scopePoints || allScopePoints.length === 0) {
    return chart;
  }

  const fromDate = dates[0] ? moment(dates[0]).startOf("day") : null;
  const toDate = dates[1] ? moment(dates[1]).endOf("day") : null;

  const dateSet = new Set<string>();
  let maxPoints = 0;
  let minDate: Date | undefined;
  let maxDate: Date | undefined;
  [stats.scopePoints, stats.startedPoints, stats.completedPoints].forEach((points) =>
    assigneeDuids.forEach((assigneeDuid) =>
      Object.values(points[assigneeDuid] ?? []).forEach((data) => {
        const currentDate = moment(data[0]);

        if ((fromDate && currentDate.isBefore(fromDate)) || (toDate && currentDate.isAfter(toDate))) {
          return;
        }

        dateSet.add(data[0]);
        maxPoints = Math.max(maxPoints, data[1] ?? 0);
        minDate = minDate ? (minDate < new Date(data[0]) ? minDate : new Date(data[0])) : new Date(data[0]);
        maxDate = maxDate ? (maxDate > new Date(data[0]) ? maxDate : new Date(data[0])) : new Date(data[0]);
      })
    )
  );

  if (!minDate || !maxDate) {
    return chart;
  }

  const extraTime = 60 * 60 * 1000 * 3;
  chart.xaxis = {
    ...chart.xaxis,
    min: (dates[0] ? new Date(dates[0]).getTime() : minDate.getTime()) - extraTime,
    max: (dates[1] ? new Date(dates[1]).getTime() : maxDate.getTime()) + extraTime,
  };

  chart.yaxis = {
    ...chart.yaxis,
    max: maxPoints + 10,
  };

  const makeTargetLine = () => {
    const startDt = moment(minDate);
    const startPoints = allCompletedPoints[0][1] ?? 0;

    const endDt = moment(dates[1]);
    const endPoints = allScopePoints[allScopePoints.length - 1][1] ?? 0;

    const totalPoints = endPoints - startPoints;

    let totalNonWeekendTimeMs = 0;
    let currDt = moment(startDt);
    if (isDateWeekend(currDt)) {
      currDt = getNextStartOfDay(currDt, 0);
    }
    while (currDt.isBefore(endDt)) {
      const nextStartOfWeekendDt = getNextStartOfDay(currDt, 5);
      const nextDt = moment.min(nextStartOfWeekendDt, endDt);
      totalNonWeekendTimeMs += nextDt.diff(currDt);
      currDt = nextStartOfWeekendDt.add(2, "days");
    }

    const pointsPerMs = totalPoints / totalNonWeekendTimeMs;

    const targetData = [[startDt.valueOf(), startPoints]];
    currDt = startDt;
    let currPoints = startPoints;
    if (isDateWeekend(currDt)) {
      currDt = getNextStartOfDay(currDt, 0);
      targetData.push([currDt.valueOf(), currPoints]);
    }
    while (currDt.isBefore(endDt)) {
      const nextStartOfWeekendDt = getNextStartOfDay(currDt, 5);
      let nextDt = moment.min(nextStartOfWeekendDt, endDt);
      currPoints += nextDt.diff(currDt) * pointsPerMs;
      targetData.push([nextDt.valueOf(), Math.round(currPoints)]);
      if (nextStartOfWeekendDt.isSameOrAfter(endDt)) {
        break;
      }
      const nextEndOfWeekendDt = nextStartOfWeekendDt.add(2, "days");
      nextDt = moment.min(nextEndOfWeekendDt, endDt);
      targetData.push([nextDt.valueOf(), Math.round(currPoints)]);
      currDt = nextEndOfWeekendDt;
    }

    return targetData;
  };

  chart.series = [
    {
      name: "Scope",
      data: formatData(stats.scopePoints, assigneeDuids),
    },
    {
      name: "Started",
      data: formatData(stats.startedPoints, assigneeDuids),
    },
    {
      name: "Completed",
      data: formatData(stats.completedPoints, assigneeDuids),
    },
    {
      name: "Target",
      type: "line",
      data: makeTargetLine(),
    },
  ];

  return chart;
};
