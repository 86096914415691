<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import AiFeedbackButtons from "~/components/dumb/AiFeedbackButtons.vue";
import Button from "~/components/dumb/Button.vue";
import FileUploadProgress from "~/components/dumb/FileUploadProgress.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { CollapseIcon, DownloadIcon, FullscreenIcon, PlayIcon, TrashIcon } from "~/icons";
import { ButtonSize, ButtonStyle, EditorMode, IconSize, ViewKind } from "~/shared/enums";
import type { Attachment } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { downloadFile } from "~/utils/api";

const props = defineProps<{
  attachment: Attachment;
  editorMode: EditorMode;
  notEditable?: boolean;
}>();

const emit = defineEmits<{
  remove: [value: string];
  collapse: [value: string];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const isDocMode = computed(() => props.editorMode === EditorMode.DOC);
const isDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);

const attachmentLoadSuccess = ref(true);
const extension = computed(() => {
  const { name } = props.attachment;
  const index = name.lastIndexOf(".");
  if (index === -1) {
    return "";
  }
  return `.${name.substring(index + 1)}`.toUpperCase();
});

const remove = (event: Event) => {
  event.stopPropagation();
  emit("remove", props.attachment.duid);
};

const isImage = computed(() => props.attachment.kind.startsWith("image") && props.attachment.kind !== "image/svg+xml");
const isVideo = computed(() => props.attachment.kind.startsWith("video"));
const isAudio = computed(() => props.attachment.kind.startsWith("audio"));
const isVideoPreview = computed(() => isVideo.value && !isDetailMode.value);

const fullscreenOrDownloadFile = () => {
  if (!isVideo.value && !isImage.value) {
    downloadFile(props.attachment.fileUrl, props.attachment.name);
    return;
  }

  appStore.setFullscreenMediaModalOpen({
    attachmentFileUrl: props.attachment.fileUrl,
    attachmentName: props.attachment.name,
    isImage: isImage.value,
    entityName: appStore.taskOpenInDetail?.title ?? appStore.docOpenInFullscreen?.title ?? "",
  });
};

const removeFeedbackButton = () => {
  if (!props.attachment.recommendationDuid) {
    return;
  }
  dataStore.updateAttachment({ duid: props.attachment.duid, recommendationDuid: null });
};

onUnmounted(() => {
  removeFeedbackButton();
});
</script>

<template>
  <Tooltip
    :text="attachment.name"
    class="group/attachment-image relative flex-col gap-0.5"
    :class="isDocMode ? 'items-start' : 'items-center'">
    <FileUploadProgress :duid="attachment.duid">
      <template #default="{ previewFileUrl, uploading }">
        <div
          class="relative w-full select-none"
          :class="{
            'cursor-default': uploading,
            'cursor-zoom-in': !uploading && (isImage || (isDetailMode && isVideo)),
            'cursor-pointer': !(uploading || isImage || (isDetailMode && isVideo)),
            'w-full': isAudio,
          }">
          <!-- Image -->
          <img
            v-if="isImage && attachmentLoadSuccess"
            :src="previewFileUrl ?? attachment.fileUrl"
            :alt="attachment.name"
            class="rounded object-cover"
            :class="!isDocMode && 'h-20 w-20 border border-md'"
            @error="attachmentLoadSuccess = false" />
          <!-- Video -->
          <template v-else-if="isVideo && attachmentLoadSuccess">
            <video
              class="rounded object-cover"
              :class="!isDocMode && 'h-20 w-20 border border-md'"
              :src="previewFileUrl ?? attachment.fileUrl"
              loop
              :autoplay="false"
              :controls="!isDetailMode"
              :playsinline="isDetailMode"
              :muted="isDetailMode"
              :preload="isDetailMode ? 'metadata' : 'auto'"
              :disablepictureinpicture="isDetailMode"
              :alt="attachment.name"
              @error="attachmentLoadSuccess = false">
              <track kind="captions" />
            </video>
            <PlayIcon v-if="!uploading && isDetailMode" class="absolute inset-0 m-auto opacity-30 text-hvy icon-xl" />
          </template>
          <!-- Audio -->
          <audio
            v-else-if="isAudio && !isDetailMode && attachmentLoadSuccess"
            :src="previewFileUrl ?? attachment.fileUrl"
            :alt="attachment.name"
            controls
            :playsinline="false"
            :muted="false"
            preload="auto"
            :disablepictureinpicture="false"
            class="w-full"
            @error="attachmentLoadSuccess = false">
            <track kind="captions" />
          </audio>
          <!-- Other -->
          <div
            v-else
            class="flex size-20 items-center justify-center rounded border border-md"
            :style="{
              backgroundColor: props.attachment.colorHex,
            }">
            <div class="flex h-16 w-12 flex-col items-center justify-evenly rounded bg-gray-100 dark:bg-zinc-100">
              <div />
              <div
                class="min-h-5 w-full truncate bg-gray-500 px-1 py-0.5 text-center text-xs text-gray-100 dark:bg-zinc-500 dark:text-zinc-100">
                {{ extension }}
              </div>
            </div>
          </div>
          <div v-if="uploading" class="absolute inset-0 rounded bg-gray-400/80 dark:bg-md/80" />
        </div>
      </template>

      <template #actions="{ uploading }">
        <div
          class="absolute inset-0 flex items-center justify-center rounded opacity-0 group-hover/attachment-image:flex group-hover/attachment-image:opacity-100"
          :class="(uploading || isVideoPreview || (isAudio && !isDetailMode)) && 'pointer-events-none'"
          @click="() => (uploading || isVideoPreview || (isAudio && !isDetailMode) ? null : fullscreenOrDownloadFile())"
          @keydown.enter="
            () => (uploading || isVideoPreview || (isAudio && !isDetailMode) ? null : fullscreenOrDownloadFile())
          ">
          <div
            class="!pointer-events-auto absolute flex items-center rounded bg-md/80 text-md"
            :class="[isAudio && !isDetailMode ? '-top-7 right-1' : 'right-1 top-1']">
            <template v-if="!uploading">
              <Tooltip v-if="isVideo || isImage" text="Expand">
                <Button
                  class="!p-1 hover:bg-hvy/80"
                  :btn-style="ButtonStyle.CHIP"
                  :icon="FullscreenIcon"
                  borderless
                  :icon-size="IconSize.XS"
                  a11y-label="Expand"
                  @click.stop="fullscreenOrDownloadFile" />
              </Tooltip>
              <Tooltip text="Download">
                <Button
                  class="!p-1 hover:bg-hvy/80"
                  :btn-style="ButtonStyle.CHIP"
                  :icon="DownloadIcon"
                  borderless
                  :icon-size="IconSize.XS"
                  a11y-label="Download"
                  @click.stop="downloadFile(attachment.fileUrl, attachment.name)" />
              </Tooltip>
              <Tooltip v-if="!notEditable && isDocMode" text="Hide">
                <Button
                  class="!p-1 hover:bg-hvy/80"
                  :btn-style="ButtonStyle.CHIP"
                  :icon="CollapseIcon"
                  borderless
                  :icon-size="IconSize.XS"
                  a11y-label="Hide"
                  @click.stop="emit('collapse', attachment.duid)" />
              </Tooltip>
            </template>
            <Tooltip v-if="!notEditable && !pageStore.isPublicView && !isTrash" text="Delete">
              <Button
                class="!p-1 hover:bg-hvy/80"
                :btn-style="ButtonStyle.CHIP"
                :size="ButtonSize.CHIP"
                :icon="TrashIcon"
                borderless
                :icon-size="IconSize.XS"
                a11y-label="Delete"
                @click.stop="remove" />
            </Tooltip>
          </div>
        </div>
      </template>
    </FileUploadProgress>
    <!-- TODO make these more like a tooltip/popup and don't show the tooltip with the attachment name on them -->
    <AiFeedbackButtons
      v-if="attachment.recommendationDuid"
      :recommendation-duids="[attachment.recommendationDuid]"
      @remove="removeFeedbackButton" />
  </Tooltip>
</template>
