/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `bar` - BAR
 * * `burnup` - BURN_UP
 * * `line` - LINE
 * * `number` - NUMBER
 * * `pie` - PIE
 * * `table` - TABLE
 */
export enum ChartType {
  BAR = 'bar',
  BURN_UP = 'burnup',
  LINE = 'line',
  NUMBER = 'number',
  PIE = 'pie',
  TABLE = 'table',
}
