import type { CSVImportData } from "csv-import-js";
import type { SerializedEditorState } from "lexical";

import {
  EventKind,
  type FeedbackRating,
  type Language,
  type RecommendationKind,
  TaskSourceType,
  type TutorialName,
  UserDataMode,
  UserRole,
} from "~/shared/enums";
import type {
  EmailAuthState,
  EventAdtl,
  GithubIntegrationEdit,
  NotificationSettingEdit,
  StreamCallback,
  TaskCreate,
  TaskField,
} from "~/shared/types";
import { usePageStore } from "~/stores";
import { downloadFile } from "~/utils/api";

import { requestManager } from "./requestManager";
import { DartError } from "./shared";

const BASE_URL = "/api/v0";

const logTime = async <T>(message: string, promiseFunc: () => Promise<T>): Promise<T> => {
  const result = await promiseFunc();
  usePageStore().logTime(message, performance.now());
  return result;
};

// TODO convert to class and make underscored methods TS private with # instead of _
const backendOld = {
  _checkAuth: (authOverride: boolean | undefined, method: string, path: string) => {
    const pageStore = usePageStore();

    if (pageStore.isPublic && authOverride !== true) {
      throw new DartError(`Cannot make ${method} request to ${path} in public view`);
    }
  },
  _get: (path: string, options?: { authOverride?: boolean; absolute?: boolean; nonBlocking?: boolean }) => {
    backendOld._checkAuth(options?.authOverride, "GET", path);
    return requestManager.get(options?.absolute ? path : `${BASE_URL}/${path}`, options?.nonBlocking);
  },
  _put: (
    path: string,
    data?: object,
    options?: { authOverride?: boolean; absolute?: boolean; nonBlocking?: boolean },
    headers?: Record<string, string>,
    onStream?: StreamCallback
  ) => {
    backendOld._checkAuth(options?.authOverride, "PUT", path);
    return requestManager.put(
      options?.absolute ? path : `${BASE_URL}/${path}`,
      options?.nonBlocking,
      data,
      headers,
      onStream
    );
  },
  _post: async (
    path: string,
    data?: object,
    options?: { authOverride?: boolean; absolute?: boolean; nonBlocking?: boolean },
    headers?: Record<string, string>,
    onStream?: StreamCallback
  ) => {
    backendOld._checkAuth(options?.authOverride, "GET", path);
    return requestManager.post(
      options?.absolute ? path : `${BASE_URL}/${path}`,
      options?.nonBlocking,
      data,
      headers,
      onStream
    );
  },
  _downloadFileRaw: (path: string) => downloadFile(`${BASE_URL}/${path}`),
  setCsrf: async () => {
    const pageStore = usePageStore();
    pageStore.updateCsrf();
    if (pageStore.csrftoken) {
      return;
    }

    await backendOld._get("csrf-token", { authOverride: true });
    pageStore.updateCsrf();
  },
  ping: () => backendOld._get("ping"),
  log: (toDatabase: boolean, kind: string, message: string | object) =>
    backendOld._post("log", { toDatabase, datetime: new Date().toISOString(), kind, message }),
  createEvent: (kind: EventKind, adtl?: EventAdtl, nonBlocking?: boolean) =>
    backendOld._post("create-event", { kind, adtl }, { authOverride: true, nonBlocking }),
  createPresignedUrl: (kind: string, filename: string, entityDuid?: string) =>
    backendOld._post("create-presigned-url", { kind, filename, entityDuid }),
  createTestError: () => backendOld._post("create-test-error"),
  getUserData: (mode: UserDataMode, path?: string) => {
    const params = new URLSearchParams();
    params.append("mode", mode);
    if (mode === UserDataMode.SPECIFIC) {
      const pathSpl = (path ?? "").replace(/^\//, "").split("/");
      if (pathSpl.length < 2) {
        throw new Error(`For mode=specific, invalid path: ${path}`);
      }
      params.append("entityKind", pathSpl[0]);
      params.append("entityDuid", pathSpl[1].substring(0, 12));
    }
    return logTime(`getting user data with mode ${mode}`, () =>
      backendOld._get(`user-data?${params.toString()}`, { authOverride: true })
    );
  },
  auth: {
    checkInvitationToken: (tokenDuid: string) => backendOld._get(`check-invitation-token?duid=${tokenDuid}`),
    checkReferralTokenAndMaybeCreateAndLogin: (tokenId: string) =>
      backendOld._get(`check-referral-token-and-maybe-create-and-login?tokenId=${tokenId}`),
    checkResetPasswordToken: (tokenDuid: string) => backendOld._get(`check-reset-password-token?duid=${tokenDuid}`),
    login: (email: string, password: string) =>
      logTime("logging in and getting user data", () => backendOld._post("login", { email, password })),
    getSamlSsoRedirect: (email: string) => backendOld._get(`sso/saml-redirect/${email}`),
    tokenLogin: (tokenDuid: string) =>
      logTime("logging in with token and getting user data", () =>
        backendOld._post("token-login", { duid: tokenDuid })
      ),
    acceptInvitation: (tokenDuid: string, name: string, password: string) =>
      logTime("accepting invitation and getting user data", () =>
        backendOld._post("accept-invitation", { tokenDuid, name, password })
      ),
    signUp: (email: string, name: string, password: string, tokenId: string, state: EmailAuthState) =>
      backendOld._post("sign-up", { email, name, password, tokenId, state }),
    verifyEmail: (tokenDuid: string) =>
      logTime("verifying email and getting user data", () => backendOld._post("verify-email", { tokenDuid })),
    requestPasswordReset: (email: string) => backendOld._post("request-password-reset", { email }),
    changePassword: (tokenDuid: string, newPassword: string) =>
      backendOld._post("change-password", { tokenDuid, password: newPassword }),
    authenticateWithGoogle: (code: string, redirectUri: string, tokenId: string | null) =>
      backendOld._post("authenticate-with-google", { code, redirectUri, tokenId }),
    getEnvironment: () => backendOld._get("environment", { authOverride: true }),
    logout: () => backendOld._post("logout"),
    makeTeammateLoginTokenAdmin: () => backendOld._get("make-teammate-login-token-admin"),
  },
  dartboards: {
    getStats: (dartboardDuid: string) => backendOld._get(`dartboards/get-stats/${dartboardDuid}`),
    getBySpace: (spaceDuid: string) => backendOld._get(`dartboards/get-by-space/${spaceDuid}`),
  },
  views: {
    getData: (viewDuid: string, all: boolean) =>
      backendOld._get(`views/get-data/${viewDuid}?all=${all}`, { authOverride: true }),
  },
  docs: {
    getByFolder: (folderDuid: string) => backendOld._get(`docs/get-by-folder/${folderDuid}`),
  },
  folders: {
    getBySpace: (spaceDuid: string) => backendOld._get(`folders/get-by-space/${spaceDuid}`),
  },
  forms: {
    getData: (formDuid: string) => backendOld._get(`forms/get-data/${formDuid}`, { authOverride: true }),
  },
  tasks: {
    createFromExternalForm: (task: TaskCreate) =>
      backendOld._post(
        "tasks/create-from-external-form",
        {
          item: { ...task, sourceType: TaskSourceType.EXTERNAL_FORM },
        },
        { authOverride: true }
      ),
    getByDartboard: (dartboardDuid: string) => backendOld._get(`tasks/get-by-dartboard/${dartboardDuid}`),
    addNotionDocument: (taskDuid: string, pageId: string) =>
      backendOld._post("tasks/add-notion-document", { taskDuid, pageId }),
    getNotionDocument: (taskDuid: string) => backendOld._get(`tasks/get-notion-document/${taskDuid}`),
    refreshNotionDocument: (taskDuid: string) => backendOld._post("tasks/refresh-notion-document", { taskDuid }),
    removeNotionDocument: (taskDuid: string) => backendOld._post("tasks/remove-notion-document", { taskDuid }),
  },
  recommendations: {
    provideFeedback: (duid: string, rating: FeedbackRating) =>
      backendOld._post(`recommendations/feedback/${duid}`, { rating }),
    provideTaskDuplicatesFeedback: (taskDuid: string, duplicateDuids: string[], rating: FeedbackRating) =>
      backendOld._post("recommendations/task-duplicates-feedback", {
        taskDuid,
        duplicateDuids,
        rating,
      }),
    getTaskDuplicates: (taskDuid: string) => backendOld._get(`recommendations/task-duplicates/${taskDuid}`),
    getTaskProperties: (duid: string, fields: TaskField[], newTitle?: string | null) =>
      backendOld._post(`recommendations/task-properties/${duid}`, { newTitle: newTitle ?? null, fields }),
    getFilters: (text: string, includeQuery?: boolean) =>
      backendOld._post("recommendations/filters", { text, includeQuery }),
    getFiltersSummary: (layoutDuid: string) => backendOld._post("recommendations/filters-summary", { layoutDuid }),
    getPageEmoji: (duid: string, newTitle?: string | null) =>
      backendOld._post(`recommendations/page-emoji/${duid}`, { newTitle: newTitle ?? null }),
    getPageIcon: (duid: string, newTitle?: string | null) =>
      backendOld._post(`recommendations/page-icon/${duid}`, { newTitle: newTitle ?? null }),
    startPlanningProject: (
      dartboardDuid: string,
      selectedTaskDuids: string[],
      options: string[],
      startDate: string | null,
      endDate: string | null,
      assigneeDuids: string[]
    ) =>
      backendOld._post(`recommendations/start-planning-project/${dartboardDuid}`, {
        selectedTaskDuids,
        options,
        startDate,
        endDate,
        assigneeDuids,
      }),
    streamSubtasks: (duid: string, newTitle?: string | null) =>
      backendOld._post(`recommendations/stream/task-subtasks/${duid}`, { newTitle: newTitle ?? null }),
    streamContent: (duid: string, kind: RecommendationKind, onStream: StreamCallback, newTitle?: string | null) =>
      backendOld._post(
        `recommendations/stream/content/${kind}/${duid}`,
        { newTitle: newTitle ?? null },
        {},
        {},
        onStream
      ),
    streamTranslation: (duid: string, language: Language, onStream: StreamCallback, newTitle?: string | null) =>
      backendOld._post(
        `recommendations/stream/translate/${language}/${duid}`,
        { newTitle: newTitle ?? null },
        {},
        {},
        onStream
      ),
    streamReport: (docDuid: string, startDate: string, endDate: string, onStream: StreamCallback) =>
      backendOld._post(`recommendations/stream/report/${docDuid}`, { startDate, endDate }, {}, {}, onStream),
  },
  profile: {
    changePassword: (newPassword: string) => backendOld._post("profiles/change-password", { newPassword }),
    rotateAuthToken: () => backendOld._post("profiles/rotate-auth-token"),
    updateTutorialStatuses: (updates: { name: TutorialName; status: number }[]) =>
      backendOld._post("profiles/update-tutorial-statuses", { items: updates }),
    edit: (field: string, value: string | boolean | number) =>
      backendOld._post("profiles/edit", { items: [{ field, value }] }),
    uploadImage: async (file: File) => {
      const response = await backendOld.createPresignedUrl("profile", file.name);
      await backendOld._put(response.data.presignedUrl, file, { absolute: true }, response.data.signedHeaders);
      await backendOld._post("profiles/complete-image-upload", {
        imagePath: response.data.filePath,
        contentType: file.type,
      });
      return response;
    },
    removeImage: () => backendOld._post("profiles/remove-image"),
    contactSupport: (text: SerializedEditorState) => backendOld._post("profiles/contact-support", { text }),
    deleteAccount: () => backendOld._post("profiles/delete"),
  },
  workspace: {
    edit: (field: string, value: string | boolean | null) =>
      backendOld._post("workspaces/edit", { items: [{ field, value }] }),
    finishOnboarding: (name: string, adtl: Record<string, string | null>) =>
      backendOld._post("workspaces/edit", {
        items: [
          { field: "name", value: name },
          {
            field: "adtl",
            value: adtl,
          },
        ],
      }),
    uploadImage: async (file: File) => {
      const response = await backendOld.createPresignedUrl("workspace", file.name);
      await backendOld._put(response.data.presignedUrl, file, { absolute: true }, response.data.signedHeaders);
      await backendOld._post("workspaces/complete-image-upload", {
        imagePath: response.data.filePath,
        contentType: file.type,
      });
      return response;
    },
    enableSamlSso: () => backendOld._post("workspaces/sso/enable-saml"),
    disableSamlSso: () => backendOld._post("workspaces/sso/disable-saml"),
    configureSamlSso: (options: { url?: string; xml?: string }) =>
      backendOld._post("workspaces/sso/configure-saml", { url: options.url ?? null, xml: options.xml ?? null }),
    removeImage: () => backendOld._post("workspaces/remove-image"),
    inviteUsers: (role: UserRole, emails: string[]) => backendOld._post("workspaces/invite-users", { role, emails }),
    reinviteUsers: (duids: string[]) => backendOld._post("workspaces/reinvite-users", { duids }),
    removeUsers: (duids: string[]) => backendOld._post("workspaces/remove-users", { duids }),
    import: async (source: string, files: File[]) => {
      const filePaths: string[] = await Promise.all(
        files.map(async (file: File) => {
          const { data } = await backendOld.createPresignedUrl("importRecord", file.name);
          await backendOld._put(data.presignedUrl, file, { absolute: true }, data.signedHeaders);
          return data.filePath;
        })
      );
      return backendOld._post("workspaces/complete-records-import", { filePaths, source });
    },
    importV2: (data: CSVImportData) => backendOld._post("workspaces/import-v2", { data }),
    export: () => backendOld._post("workspaces/export"),
  },
  billing: {
    createCheckoutSession: (period: string, returnPath: string) =>
      backendOld._post("billing/create-checkout-session", { period, returnPath }),
    createPortalSession: () => backendOld._post("billing/create-portal-session"),
    resetAccount: () => backendOld._post("billing/reset-account"),
    deleteSubscriptions: () => backendOld._post("billing/delete-subscriptions"),
  },
  notifications: {
    edit: (edits: NotificationSettingEdit[]) => backendOld._post("notifications/edit", { items: edits }),
  },
  notion: {
    enableIntegration: (code: string | null) => backendOld._post("notion/enable", { code }),
    disableIntegration: () => backendOld._post("notion/disable"),
  },
  slack: {
    enableIntegration: () => backendOld._post("slack/enable"),
    getInstallationLink: () => `${BASE_URL}/slack/install`,
    disableIntegration: () => backendOld._post("slack/disable"),
  },
  discord: {
    edit: (field: "enabled" | "webhookUrl", value: string | boolean | null) =>
      backendOld._post("discord/edit", { items: [{ field, value }] }),
  },
  zapier: {
    edit: (field: "enabled", value: boolean) => backendOld._post("zapier/edit", { items: [{ field, value }] }),
  },
  vcs: {
    enableGithubIntegration: (installationId: string | null) =>
      backendOld._post("vcs/github/enable", { installation_id: installationId }),
    disableGithubIntegration: () => backendOld._post("vcs/github/disable"),
    updateGithubSettings: (edits: GithubIntegrationEdit[]) => backendOld._post("vcs/github/edit", { items: edits }),
    onBranchLinkCopy: (taskDuid: string) => backendOld._post("vcs/copy-branch-link", { duid: taskDuid }),
  },
  agentDemo: {
    ensureAgentsAdmin: () => backendOld._get("agent-demo/ensure-agents-admin"),
  },
};

export default backendOld;
