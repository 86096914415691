<script setup lang="ts">
import DartAnimation from "~/icons/DartAnimation.vue";

defineProps<{
  hasData: boolean;
  isLoading?: boolean;
}>();
</script>

<template>
  <div class="relative size-full">
    <slot />
    <template v-if="!hasData || isLoading">
      <div class="absolute inset-0 opacity-70 bg-std" />
      <div class="absolute inset-0 flex size-full select-none flex-col items-center justify-evenly">
        <div v-if="!hasData" class="flex w-64 flex-col items-center justify-center gap-1">
          <p class="select-none text-center font-semibold text-md">No data</p>
          <p class="w-full select-none hyphens-auto break-words text-center text-sm text-lt">
            No tasks match the selected filters
          </p>
        </div>
        <DartAnimation v-else class="size-40 animate-pulse" />
      </div>
    </template>
  </div>
</template>
