import { DartboardKind, FilterApplicability, FilterConnector, StatusKind, ViewKind } from "~/shared/enums";
import type { FilterAssignee, FilterSet } from "~/shared/types";

export const COMPLETED_STATUS_KINDS = new Set([StatusKind.FINISHED, StatusKind.CANCELED]);

export const DROP_HOVER_STYLE = "bg-primary-base/25"; // TODO move to styles
export const MAX_TIMEOUT = 2 ** 31 - 1;
export const MOVE_REPEAT_DELAY_MS = 500;
// TODO move
export const UNASSIGNED_ASSIGNEE_LABEL = "(Unassigned)";
export const UNASSIGNED_PSEUDO_ASSIGNEE_KEY = "@Unassigned";
export const UNSET_USER_LABEL = "(Unset)";
export const UNKNOWN_USER_LABEL = "(Unknown)";

export const SPRINT_DARTBOARD_KINDS_SET = new Set([
  DartboardKind.ACTIVE,
  DartboardKind.NEXT,
  DartboardKind.BACKLOG,
  DartboardKind.FINISHED,
]);

export const NONCUSTOM_VIEW_KINDS = new Set([ViewKind.SEARCH, ViewKind.TRASH, ViewKind.MY_TASKS]);

export const DEFAULT_ASSIGNEES_FILTER_PARTIAL: Omit<FilterAssignee, "propertyDuid" | "values"> = {
  id: "assignee",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
};

export const DEFAULT_STATUS_FILTER_PARTIAL: Omit<FilterSet, "propertyDuid" | "values"> = {
  id: "status",
  applicability: FilterApplicability.IS_NOT,
  connector: FilterConnector.OR,
};

export const DEFAULT_DARTBOARD_FILTER_PARTIAL: Omit<FilterSet, "propertyDuid" | "values"> = {
  id: "dartboard",
  applicability: FilterApplicability.IS_NOT,
  connector: FilterConnector.OR,
};

export const DEFAULT_TRASH_DARTBOARD_FILTER_PARTIAL: Omit<FilterSet, "propertyDuid" | "values"> = {
  id: "dartboard",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
};

export const TASK_CONTEXT_MENU_OPTIONS = {
  maxHeightPixels: 960,
  widthPixels: 280,
};
