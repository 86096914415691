<script setup lang="ts">
import { useMouseInElement } from "@vueuse/core";
import moment from "moment";
import { computed, nextTick, ref } from "vue";

import { DatesFieldIcon, PlusIcon } from "~/icons";
import { TaskSourceType } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { normalizeDateToDate } from "~/utils/date";
import { getOrdersBetween } from "~/utils/orderManager";

import { DEFAULT_BLOCK_WIDTH_PX, TASK_ROW_HEIGHT } from "./constants";
import type { PreviewRange, RoadmapConfig, RoadmapValues } from "./shared";

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
  index: number;
  task?: TaskAbsenteeMaybe;
}>();

const emit = defineEmits<{
  taskCreated: [task: TaskAbsenteeMaybe];
  updatePreviewRange: [previewRange: PreviewRange];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

/* Properties */
const height = computed(() => TASK_ROW_HEIGHT);
const width = computed(() => DEFAULT_BLOCK_WIDTH_PX);
const top = computed(() => props.index * TASK_ROW_HEIGHT);

/* Mouse */
const wrapper = ref<HTMLDivElement | null>(null);
const { elementX, isOutside } = useMouseInElement(wrapper);
const left = computed(() => elementX.value - width.value / 2);

const getRange = () => {
  const range = Math.ceil(DEFAULT_BLOCK_WIDTH_PX / props.roadmapConfig.pxPerDay);
  const startAt = normalizeDateToDate(
    moment(props.roadmapConfig.startDate).add(Math.round(left.value / props.roadmapConfig.pxPerDay), "day")
  ).toISOString();
  const dueAt = normalizeDateToDate(moment(startAt).add(range, "day")).toISOString();
  return { startAt, dueAt };
};

/* Preview range */
const onHover = () => {
  const { startAt, dueAt } = getRange();
  emit("updatePreviewRange", { startDate: startAt, endDate: dueAt });
};
const onLeave = () => {
  emit("updatePreviewRange", null);
};

const onClick = async () => {
  const { startAt, dueAt } = getRange();

  /* Update existing task */
  if (props.task) {
    dataStore.updateTasks([{ duid: props.task.duid, startAt, dueAt }]);
    appStore.getBaseVisualization().selectAndScrollTo(props.task.duid);
    return;
  }

  /* Create new task */
  const dartboard = appStore.currentDartboardOrDefault;
  const { tasks } = props.roadmapValues;
  const taskAboveDestOrder = tasks.length ? tasks[tasks.length - 1]?.order : undefined;
  const task = await dataStore.createTask(
    "",
    dartboard.duid,
    getOrdersBetween(taskAboveDestOrder, undefined)[0],
    TaskSourceType.APP_ROADMAP_TIMELINE,
    { startAt, dueAt }
  );
  emit("taskCreated", task);
  nextTick(() => appStore.getBaseVisualization().selectAndScrollTo(task.duid));
};
</script>

<template>
  <div
    ref="wrapper"
    :style="{
      minHeight: `${height}px`,
      maxHeight: `${height}px`,
      top: `${top}px`,
    }"
    class="group/roadmap-row absolute inset-x-0 flex size-full cursor-pointer select-none items-center py-1">
    <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events -->
    <button
      v-if="!isOutside"
      :style="{
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
        marginLeft: `${left}px`,
      }"
      type="button"
      class="hidden size-full items-center justify-center rounded border border-transparent p-2 bg-lt group-hover/roadmap-row:flex"
      @click.stop="onClick"
      @mouseenter="onHover"
      @mouseover="onHover"
      @mouseleave="onLeave">
      <component :is="task ? DatesFieldIcon : PlusIcon" class="text-md icon-sm" />
    </button>
  </div>
</template>
