import type { Attachment, AttachmentCreate, AttachmentUpdate } from "~/shared/types";
import { truncateFilename } from "~/utils/api";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";
import { attachmentComparator } from "~/utils/comparator";
import { finishFileUpload, makeFileUploadProgressTracker, startFileUpload } from "~/utils/fileUpload";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  attachmentList: Attachment[];
  getAttachmentByDuid: (duid: string) => Attachment | undefined;
  getAttachmentsByDuidsOrdered: (duids: string[]) => Attachment[];
};

export type Actions = {
  /* Create a new attachment. */
  createAttachment: (
    file: File,
    order: string,
    partialAttachment?: Partial<Attachment>,
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => Promise<{ attachment: Attachment; create: AttachmentCreate }>;
  createAttachments: (
    attachmentConfigs: {
      file: File;
      order: string;
      partialAttachment?: Partial<Attachment>;
    }[],
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => Promise<{ attachment: Attachment; create: AttachmentCreate }[]>;
  /* Update an existing attachment. */
  updateAttachment: (update: AttachmentUpdate, options?: { awaitBackend?: boolean }) => Promise<Attachment | undefined>;
  /** Delete a attachment. */
  deleteAttachment: (attachment: Attachment, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort attachments.
   * @PRIVATE */
  $sortAttachments: () => void;
  /** Set attachments internally.
   * @PRIVATE */
  $setAttachments: (attachments: Attachment[]) => void;
  /** Create or update attachment from WS.
   * @PRIVATE */
  $createOrUpdateAttachment: (attachment: Attachment) => void;
  /** Delete attachment from WS.
   * @PRIVATE */
  $deleteAttachment: (attachment: Attachment) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  attachmentList() {
    return Array.from(this._duidsToAttachments.values());
  },
  getAttachmentByDuid() {
    return (duid) => this._duidsToAttachments.get(duid);
  },
  getAttachmentsByDuidsOrdered() {
    return (duids) => this.attachmentList.filter((e) => duids.includes(e.duid));
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createAttachment(file, order, partialAttachment, options = {}) {
    const { noBackend = false, awaitBackend = false } = options;
    const attachmentDuid = partialAttachment?.duid ?? makeDuid();
    startFileUpload(attachmentDuid, file);
    const { data } = await this.$backendOld.createPresignedUrl("attachment", file.name, attachmentDuid);

    const attachment: Attachment = {
      duid: attachmentDuid,
      name: truncateFilename(file.name, 150),
      kind: file.type,
      order,
      fileUrl: data.fileUrl,
      colorHex: makeRandomColorHex(),
      recommendationDuid: null,
      ...partialAttachment,
    };
    const attachmentCreates: AttachmentCreate[] = [{ ...attachment, filePath: data.filePath }];

    if (!noBackend) {
      const backendAction = this.$backend.attachment.createMany(attachmentCreates);
      if (awaitBackend) {
        await backendAction;
      }
    }

    // TODO refactor to remove this timeout--can do this by returning the code that happens in the timeout as a callback and calling that after the attachment is created
    // the reason that this is this way right now is that we need this code to run after the actual attachment create and setTimeout basically puts it on the next tick
    // and we we were too lazy to refactor it right now
    setTimeout(async () => {
      await this.$backendOld._put(
        data.presignedUrl,
        file,
        { absolute: true, nonBlocking: true },
        data.signedHeaders,
        makeFileUploadProgressTracker(attachmentDuid)
      );
      finishFileUpload(attachmentDuid);
    });

    return { attachment, create: attachmentCreates[0] };
  },
  async createAttachments(attachmentConfigs, options) {
    return Promise.all(
      attachmentConfigs.map(async ({ file, order, partialAttachment }) =>
        this.createAttachment(file, order, partialAttachment, options)
      )
    );
  },
  async updateAttachment(update, options = {}) {
    const { awaitBackend = false } = options;
    const attachment = this.getAttachmentByDuid(update.duid);
    if (!attachment) {
      return undefined;
    }

    Object.assign(attachment, update);
    this.$sortAttachments();

    const backendAction = this.$backend.attachment.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return attachment;
  },
  async deleteAttachment(attachment, options = {}) {
    const { awaitBackend = false } = options;
    this.$deleteAttachment(attachment);

    const backendAction = this.$backend.attachment.delete(attachment.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortAttachments() {
    this._duidsToAttachments = new Map([...this._duidsToAttachments].sort((a, b) => attachmentComparator(a[1], b[1])));
  },
  $setAttachments(attachments) {
    this._duidsToAttachments = new Map(attachments.map((e) => [e.duid, e]));
    this.$sortAttachments();
  },
  $createOrUpdateAttachment(attachment) {
    const currentAttachment = this.getAttachmentByDuid(attachment.duid);

    if (!currentAttachment) {
      this._duidsToAttachments.set(attachment.duid, attachment);
    } else {
      Object.assign(currentAttachment, attachment);
    }

    this.$sortAttachments();
  },
  $deleteAttachment(attachment) {
    this._duidsToAttachments.delete(attachment.duid);
  },
};

export { actions, getters };
