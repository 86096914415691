import moment, { type Moment } from "moment";
import { type Ref, ref, watch } from "vue";

import { SHORT_WEEKDAYS } from "~/constants/time";
import { RecurrenceKind } from "~/shared/enums";
import type { DayOfWeek, IntervalUnit, TaskRecurrence, TimeTracking } from "~/shared/types";

import { getItemCountText, getOrdinalText, prettyFormatList } from "./common";

const CONNECTOR_ABSOLUTE = "on";
const CONNECTOR_RELATIVE = "";
const CONNECTOR_DIFFERENCE = "in";

export const isBrowserLocale24h = () =>
  Intl.DateTimeFormat(navigator.language, { hour: "numeric" }).resolvedOptions().hourCycle === "h23";

export const getMsUntilNext = (interval: IntervalUnit) =>
  moment().add(1, interval).startOf(interval).add(1, "second").diff(moment());

export const prettifyTimezone = (name: string) => name.replaceAll("_", " ").split("/").reverse().join(", ");

const makeResult = (connector: string, value: string) => ({
  value,
  message: `${connector}${connector ? " " : ""}${value}`,
});

export const getRelativeTimeForReminder = (date: Moment): { value: string; message: string } => {
  const now = moment();
  const minutes = Math.round(date.diff(now, "minute", true));

  // if it's less then a minute show seconds until
  if (minutes < 1) {
    const seconds = Math.round(date.diff(now, "second", true));
    return makeResult(CONNECTOR_DIFFERENCE, getItemCountText(seconds, "second"));
  }
  // if it's less than an hour show minutes until
  if (minutes < 60) {
    return makeResult(CONNECTOR_DIFFERENCE, getItemCountText(minutes, "minute"));
  }
  // if it's tomorrow, say tomorrow
  if (date.isSame(moment().add(1, "day"), "day")) {
    return makeResult(CONNECTOR_RELATIVE, "tomorrow");
  }
  // if it's less than a day show hours until
  if (minutes < 60 * 24) {
    const hours = Math.round(date.diff(now, "hour", true));
    return makeResult(CONNECTOR_DIFFERENCE, getItemCountText(hours, "hour"));
  }
  // if it's in the next week, show day of the week
  if (date.isSameOrBefore(moment().add(6, "day"), "day")) {
    return makeResult(CONNECTOR_ABSOLUTE, date.format("dddd"));
  }
  // if it's this year, show the month and day
  if (date.isSame(now, "year")) {
    return makeResult(CONNECTOR_ABSOLUTE, date.format("MMM D"));
  }
  // show the full date
  return makeResult(CONNECTOR_ABSOLUTE, date.format("MMM D, YYYY"));
};

export const getRelativeTimeForActivityItem = (date: Moment): { text: string; refreshMs: number } => {
  const now = moment();
  const minutes = now.diff(date, "minute", true);

  // if it's less then a minute show just now
  if (minutes < 1) {
    return {
      text: "just now",
      refreshMs: moment(date).add(1, "minute").add(1, "second").diff(now),
    };
  }

  // if it's within half an hour show minutes ago
  if (minutes < 30) {
    const roundedMinutes = Math.round(minutes);
    return {
      text: `${getItemCountText(roundedMinutes, "minute")} ago`,
      refreshMs: moment(date).add(roundedMinutes, "minute").add(31, "second").diff(now),
    };
  }

  const refreshMs = moment().add(1, "day").startOf("day").add(1, "second").diff(now);
  // if it's the same day show the time
  if (date.isSame(now, "day")) {
    return {
      text: isBrowserLocale24h() ? date.format("H:mm") : date.format("h:mm A"),
      refreshMs,
    };
  }
  // if it's yesterday show that
  if (date.isSame(moment().subtract(1, "day"), "day")) {
    return {
      text: "yesterday",
      refreshMs,
    };
  }
  // if it's in the past week show the day of the week
  if (date.isSameOrAfter(moment().subtract(6, "day"), "day")) {
    return {
      text: date.format("dddd"),
      refreshMs,
    };
  }
  // if it's this year, show the month and day
  if (date.isSame(now, "year")) {
    return {
      text: date.format("MMM D"),
      refreshMs,
    };
  }
  // show the full date
  return {
    text: date.format("MMM D, YYYY"),
    refreshMs,
  };
};

export const getRelativeTimeForDatesDate = (dateRaw: string): string => {
  const now = moment();
  const date = moment(dateRaw);

  // if it's today, say today
  if (date.isSame(now, "day")) {
    return "Today";
  }
  if (date.isBefore(now)) {
    // if it's yesterday, say yesterday
    if (date.isSame(moment().subtract(1, "day"), "day")) {
      return "Yesterday";
    }
    // if it's this year, show the month and day
    if (date.isSame(now, "year")) {
      return date.format("MMM D");
    }
    // show the full date
    return date.format("MMM D, YYYY");
  }
  // if it's tomorrow, say tomorrow
  if (date.isSame(moment().add(1, "day"), "day")) {
    return "Tomorrow";
  }
  // if it's in the next week, show day of the week
  if (date.isSameOrBefore(moment().add(6, "day"), "day")) {
    return date.format("dddd");
  }
  // if it's this year, show the month and day
  if (date.isSame(now, "year")) {
    return date.format("MMM D");
  }
  // show the full date
  return date.format("MMM D, YYYY");
};

export const getFullDate = (date: Moment): string =>
  isBrowserLocale24h() ? date.format("MMMM Do YYYY, H:mm:ss") : date.format("MMMM Do YYYY, h:mm:ss A");

export const makeRelativeTimeForActivityItemRef = (
  dateStr: Ref<string>
): Ref<{ short: string; full: string; destroy: () => void }> => {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  const res = ref({
    short: "",
    full: "",
    destroy: () => {
      clearTimeout(timeout);
    },
  });

  const resetValuesAndTimeout = () => {
    clearTimeout(timeout);

    const date = moment(dateStr.value);
    const relative = getRelativeTimeForActivityItem(date);
    res.value.short = relative.text;
    res.value.full = getFullDate(date);

    timeout = setTimeout(resetValuesAndTimeout, relative.refreshMs);
  };

  resetValuesAndTimeout();

  watch(() => dateStr.value, resetValuesAndTimeout);

  return res;
};

export const makeRelativeTimeForDatesDateRef = (
  dateStr: Ref<string>
): Ref<{ short: string; full: string; destroy: () => void }> => {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  const res = ref({
    short: "",
    full: "",
    destroy: () => {
      clearTimeout(timeout);
    },
  });

  const resetValuesAndTimeout = () => {
    clearTimeout(timeout);

    const date = moment(dateStr.value);
    res.value.short = getRelativeTimeForDatesDate(dateStr.value);
    res.value.full = getFullDate(date);

    timeout = setTimeout(resetValuesAndTimeout, getMsUntilNext("day"));
  };

  resetValuesAndTimeout();

  watch(() => dateStr.value, resetValuesAndTimeout);

  return res;
};

const getRecurrenceIntervalText = (interval: number, unit: string, descriptor: string): string => {
  if (interval === 1) {
    return descriptor;
  }
  if (interval === 2) {
    return `every other ${unit}`;
  }
  return `every ${getOrdinalText(interval)} ${unit}`;
};

export const getRecurrenceSummary = (recurrence: TaskRecurrence, recursNextAt: Moment): string => {
  switch (recurrence.kind) {
    case RecurrenceKind.DAILY: {
      return getRecurrenceIntervalText(recurrence.interval, "day", "daily");
    }
    case RecurrenceKind.WEEKLY: {
      const { days } = recurrence;
      let daysSummary;
      if (days.length === 7) {
        daysSummary = "every day";
      } else if (days.length === 5 && days.every((d) => d < 5)) {
        daysSummary = "every weekday";
      } else if (days.length === 2 && days.includes(5) && days.includes(6)) {
        daysSummary = "every weekend day";
      } else if (days.length >= 5) {
        daysSummary = `every day except ${prettyFormatList(
          SHORT_WEEKDAYS.filter((_, i) => !days.includes(i as DayOfWeek))
        )}`;
      } else {
        daysSummary = `every ${prettyFormatList(SHORT_WEEKDAYS.filter((_, i) => days.includes(i as DayOfWeek)))}`;
      }
      const intervalText =
        recurrence.interval === 1 ? "" : `${getRecurrenceIntervalText(recurrence.interval, "week", "weekly")} on `;
      return `${intervalText}${daysSummary}`;
    }
    case RecurrenceKind.MONTHLY: {
      return `${getRecurrenceIntervalText(recurrence.interval, "month", "monthly")} on the ${recursNextAt.format(
        "Do"
      )}`;
    }
    case RecurrenceKind.YEARLY: {
      return `${getRecurrenceIntervalText(recurrence.interval, "year", "yearly")} on ${recursNextAt.format("MMM Do")}`;
    }
    default: {
      throw new Error(`Unhandled recurrence kind: ${recurrence}`);
    }
  }
};

export const getPrevRecursNextAt = (recurrence: TaskRecurrence, currRecursNextAt: Moment): Moment => {
  switch (recurrence.kind) {
    case RecurrenceKind.DAILY: {
      return moment(currRecursNextAt).subtract(recurrence.interval, "day");
    }
    case RecurrenceKind.WEEKLY: {
      const { days } = recurrence;
      const day = ((currRecursNextAt.day() + 6) % 7) as DayOfWeek;
      const prevInList = days.findLast((e) => e < day);
      if (prevInList === undefined) {
        const dayDiff = days[days.length - 1] - day;
        return moment(currRecursNextAt).subtract(recurrence.interval, "week").add(dayDiff, "day");
      }
      const dayDiff = day - prevInList;
      return moment(currRecursNextAt).subtract(dayDiff, "day");
    }
    case RecurrenceKind.MONTHLY: {
      return moment(currRecursNextAt).subtract(recurrence.interval, "month");
    }
    case RecurrenceKind.YEARLY: {
      return moment(currRecursNextAt).subtract(recurrence.interval, "year");
    }
    default: {
      throw new Error(`Unhandled recurrence kind: ${recurrence}`);
    }
  }
};

export const getNextRecursNextAt = (recurrence: TaskRecurrence, currRecursNextAt: Moment): Moment => {
  switch (recurrence.kind) {
    case RecurrenceKind.DAILY: {
      return moment(currRecursNextAt).add(recurrence.interval, "day");
    }
    case RecurrenceKind.WEEKLY: {
      const { days } = recurrence;
      const day = ((currRecursNextAt.day() + 6) % 7) as DayOfWeek;
      const nextInList = days.find((e) => e > day);
      if (nextInList === undefined) {
        const dayDiff = day - days[0];
        return moment(currRecursNextAt).add(recurrence.interval, "week").subtract(dayDiff, "day");
      }
      const dayDiff = nextInList - day;
      return moment(currRecursNextAt).add(dayDiff, "day");
    }
    case RecurrenceKind.MONTHLY: {
      return moment(currRecursNextAt).add(recurrence.interval, "month");
    }
    case RecurrenceKind.YEARLY: {
      return moment(currRecursNextAt).add(recurrence.interval, "year");
    }
    default: {
      throw new Error(`Unhandled recurrence kind: ${recurrence}`);
    }
  }
};

export const getRecurrenceDatesInRange = (
  recurrence: TaskRecurrence,
  recursNextAt: Moment,
  rangeStart: Moment,
  rangeEnd: Moment
): Moment[] => {
  let currRecursNextAt = recursNextAt;
  for (let i = 0; i < 10000; i += 1) {
    if (currRecursNextAt.isBefore(rangeStart)) {
      break;
    }
    currRecursNextAt = getPrevRecursNextAt(recurrence, currRecursNextAt);
  }
  const res = [];
  for (let i = 0; i < 10000; i += 1) {
    if (currRecursNextAt.isAfter(rangeEnd)) {
      break;
    }
    if (currRecursNextAt.isSameOrAfter(rangeStart)) {
      res.push(currRecursNextAt);
    }
    currRecursNextAt = getNextRecursNextAt(recurrence, currRecursNextAt);
  }
  return res;
};

export const getUserFormatDate = (date: Moment): string => date.format("dddd, MMMM Do");

export const getPartOfDay = (date: Moment, noNight?: boolean): string => {
  const hour = date.hour();
  if (hour < 12 && (hour >= 5 || (noNight && hour >= 3))) {
    return "morning";
  }
  if (hour >= 12 && hour < 17) {
    return "afternoon";
  }
  if (noNight || (hour >= 17 && hour < 21)) {
    return "evening";
  }
  return "night";
};

export const getDurationText = (duration: moment.Duration) => {
  const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
  const minutes = duration.minutes().toString().padStart(2, "0");
  const seconds = duration.seconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const getTimeTrackingDuration = (timeTracking: TimeTracking) => {
  const totalDuration = moment.duration();
  timeTracking.forEach(({ startedAt, finishedAt }) => {
    const start = moment(startedAt);
    const end = finishedAt ? moment(finishedAt) : moment();
    const duration = moment.duration(end.diff(start));
    totalDuration.add(duration);
  });
  return totalDuration;
};

export const getTimeTrackingDurationText = (timeTracking: TimeTracking) =>
  getDurationText(getTimeTrackingDuration(timeTracking));
