<script setup lang="ts">
import { computed, ref } from "vue";

import { getProperty } from "~/common/properties";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import OptionDropdownItem from "~/components/options/OptionDropdownItem.vue";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { CommandId, Placement, PropertyKind } from "~/shared/enums";
import type { DateRange, Filter, FilterDefinition, FilterValue, TimeTracking } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";

import Chip from "./Chip.vue";

const props = defineProps<{
  filter: Filter;
  definition: FilterDefinition | undefined;
  enabled: boolean;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  select: [values: FilterValue[]];
  afterClose: [];
  removeFilter: [];
  replaceFilter: [];
}>();

const dataStore = useDataStore();

const menu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const selectedValues = computed(() => props.filter.values || []);

const onReplace = (value: FilterValue) => {
  emit("select", [value]);
  menu.value?.close();
};

const property = computed(() => getProperty(props.filter.propertyDuid));
const items = computed(() => {
  if (!props.definition) {
    return [];
  }

  /* Options have nested dropdowns */
  if (
    property.value &&
    [PropertyKind.DEFAULT_TAGS, PropertyKind.SELECT, PropertyKind.MULTISELECT].includes(property.value.kind)
  ) {
    return props.definition.config.options
      .map((e) => ({ ...e, option: dataStore.getOptionByDuid(e.value as string) }))
      .filter((e) => !e.option?.parentDuid || selectedValues.value.includes(e.value))
      .map((option) => {
        const descendants = dataStore.getOptionList(property.value!).filter((e) => e.parentDuid === option.value);
        return {
          value: option.value as Exclude<FilterValue, DateRange | TimeTracking>,
          label: option.label,
          selected: selectedValues.value.includes(option.value),
          alwaysShowInList: descendants.some((descendant) => !selectedValues.value.includes(descendant.duid)),
          component: OptionDropdownItem,
          componentArgs: {
            option: option.option,
            selectedOptionDuids: selectedValues.value,
            isNested: false,
            showDescendants: true,
            isFilter: true,
            replaceEnabled: selectedValues.value.length > 0,
            onSwap: (duid: string) => onReplace(duid),
            colorHex: option.colorHex,
            icon: option.icon,
            iconArgs: option.iconArgs,
          },
        };
      });
  }

  return props.definition.config.options.map((option) => ({
    value: option.value as Exclude<FilterValue, DateRange | TimeTracking>,
    label: option.label,
    adtlSearchTerms: option.adtlSearchTerms ?? [],
    selected: selectedValues.value.includes(option.value),
    component: Chip,
    componentArgs: {
      label: option.label,
      colorHex: option.colorHex,
      icon: option.icon,
      iconArgs: option.iconArgs,
      replaceEnabled: selectedValues.value.length > 0,
    },
  }));
});

const values = computed(() =>
  selectedValues.value
    .map((value) => {
      const item = items.value.find((e) => e.value === value);
      return {
        label: item?.label ?? "",
        colorHex: item?.componentArgs?.colorHex,
        icon: item?.componentArgs?.icon,
        iconArgs: item?.componentArgs?.iconArgs,
        disabled: props.filter.locked,
      };
    })
    .sort(makeStringComparator((e) => e.label))
);

const onAdd = (value: FilterValue) => {
  emit("select", [...selectedValues.value, value]);
};

const onRemove = (value: FilterValue) => {
  emit(
    "select",
    selectedValues.value.filter((v) => v !== value)
  );
};

const open = () => {
  menu.value?.open();
};

const hiddenOptionsText = computed(() => [
  {
    title: `${props.definition?.title}`,
    items: items.value
      .filter((option) => option.selected)
      .slice(1)
      .map((option) => option.label),
  },
]);

defineExpose({
  open,
});
</script>

<template>
  <MultiselectDropdownMenu
    ref="menu"
    :disabled="!enabled || filter.locked"
    :items="items"
    placeholder="Filter for..."
    :distance="-1"
    :skidding="-5"
    cover
    :propagate-click-classes="['dart-filter-applicability', 'dart-filter-field']"
    class="flex h-full items-center justify-center"
    @add="onAdd"
    @remove="onRemove"
    @replace="onReplace"
    @after-close="emit('afterClose')">
    <div class="flex h-full min-w-px flex-nowrap items-center justify-center gap-1">
      <!-- First value -->
      <Tooltip :command-id="CommandId.CHANGE_FILTER_VALUES" :disabled="filter.locked">
        <Chip v-if="values.length !== 0" class="h-5 max-w-48" v-bind="values[0]" />
      </Tooltip>

      <!-- Show connector if more than one value -->
      <slot v-if="values.length > 1" name="connector" :definition="definition" />

      <!-- Second value or + N -->
      <Tooltip
        v-if="values.length >= 2"
        :command-id="CommandId.CHANGE_FILTER_VALUES"
        block
        :disabled="filter.locked"
        :placement="values.length > 2 ? Placement.TOP : Placement.BOTTOM">
        <Chip v-if="values.length === 2" class="h-5 max-w-48" v-bind="values[1]" />
        <Tooltip v-else :sections="hiddenOptionsText" :placement="Placement.BOTTOM">
          <Chip class="h-5 max-w-48" :label="`+${values.length - 1}`" :color-hex="DEFAULT_CHIP_COLOR" />
        </Tooltip>
      </Tooltip>
    </div>
  </MultiselectDropdownMenu>
</template>
