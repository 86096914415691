import type { Moment } from "moment";
import moment from "moment";

import type { RangeDefinition } from "./shared";

export const LEFT_PANEL_MIN_PX = 200;
export const RIGHT_PANEL_MIN_PX = 300;

export const RANGE_HEIGHT = 70;
export const RANGE_HEIGHT_PX = `${RANGE_HEIGHT}px`;

export const TASK_ROW_HEIGHT = 37;
export const DEFAULT_BLOCK_WIDTH_PX = 160;

export const BOTTOM_PADDING = 300;

export const MIN_SIDE_BUFFER_PX = 500;
export const TARGET_SIDE_BUFFER_PX = 2000;

export const ZOOM_MAX = 200;
export const ZOOM_MIN = 2;
export const ZOOM_MULTIPLIER = 1.2;

export const ZOOM_MULTIPLIER_PER_SCROLL_Y = ZOOM_MULTIPLIER ** (1 / 12);

export const NAMED_ZOOM_LEVELS: [string, number][] = [
  ["Week", 120],
  ["Month", 30],
  ["Quarter", 10],
  ["Year", 2],
];

export const BASE_RANGE_DEFINITION: RangeDefinition = {
  rangeFormat: "D",
  rangeExtraFormatFn: (dt: Moment) => dt.format("ddd"),
  extraRange: {
    unit: "month",
    format: (dt) => (moment(dt).month() !== 0 ? moment(dt).format("MMM") : moment(dt).format("MMM YYYY")),
    stickyFormat: (dt: Moment) => moment(dt).format("MMMM YYYY"),
  },
  rangeInterval: [1, "day"],
  timelineInterval: [1, "day"],
  exact: true,
};
export const RANGE_DEFINITION_STOPS: [number, Partial<RangeDefinition>][] = [
  [
    60,
    {
      rangeExtraFormatFn: (dt: Moment) => dt.format("dd").charAt(0),
    },
  ],
  [
    40,
    {
      rangeInterval: [3, "day"],
      timelineInterval: [1, "week"],
      exact: false,
    },
  ],
  [
    15,
    {
      rangeExtraFormatFn: null,
      rangeInterval: [1, "week"],
    },
  ],
  [
    10,
    {
      timelineInterval: [1, "month"],
    },
  ],
  [
    5,
    {
      rangeInterval: [2, "week"],
    },
  ],
  [
    4,
    {
      rangeInterval: [1, "month"],
      timelineInterval: [1, "quarter"],
    },
  ],
  [
    3,
    {
      rangeFormat: "MMM",
      extraRange: {
        unit: "quarter",
        format: (dt) => (moment(dt).month() !== 0 ? moment(dt).format("[Q]Q") : moment(dt).format("[Q]Q YYYY")),
        stickyFormat: (dt: Moment) => moment(dt).format("[Quarter] Q YYYY"),
      },
    },
  ],
];
