<script setup lang="ts">
import { computed } from "vue";

import Avatar from "~/components/dumb/Avatar.vue";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import type { User } from "~/shared/types";
import { usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";

const props = defineProps<{
  user?: User;
}>();

const pageStore = usePageStore();

const name = computed(() => (props.user ? props.user.name || props.user.email : "Unknown"));
const colorSet = computed(() => getChipColorsHex(props.user?.colorHex ?? DEFAULT_CHIP_COLOR, pageStore.theme));
</script>

<template>
  <div
    class="pointer-events-none absolute -left-0.5 top-full flex min-h-5 max-w-xs items-center justify-center gap-1 overflow-hidden rounded border px-1"
    :title="name"
    :style="{ color: colorSet.fore, backgroundColor: colorSet.back, borderColor: colorSet.border }">
    <Avatar
      v-if="props.user"
      circle
      img-border
      :abrev="props.user.abrev"
      :color-hex="props.user.colorHex"
      :image-url="props.user.imageUrl"
      class="icon-sm" />
    <span class="truncate text-xs">{{ name }}</span>
  </div>
</template>
