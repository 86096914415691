import PageIcon from "~/components/dumb/PageIcon.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { TypeaheadSpecialEntity } from "~/shared/enums";
import type { TypeaheadOption } from "~/shared/types";
import { useDataStore } from "~/stores";

/** Tasks */
const makeTaskOption = (taskDuid: string): TypeaheadOption | undefined => {
  const dataStore = useDataStore();
  const task = dataStore.getTaskByDuid(taskDuid);
  if (!task) {
    return undefined;
  }
  const statusKind = dataStore.getStatusByDuid(task.statusDuid)?.kind;
  if (!statusKind) {
    return undefined;
  }

  return {
    value: taskDuid,
    label: task.title,
    specialEntity: TypeaheadSpecialEntity.TASK,
    adtlSearchTerms: ["task"],
    icon: StatusIcon,
    iconArgs: {
      class: "icon-md",
      duid: task.statusDuid,
    },
    chipColorHex: DEFAULT_CHIP_COLOR,
    strikeThrough: COMPLETED_STATUS_KINDS.has(statusKind),
  };
};

export const getTaskOptions = (): TypeaheadOption[] => {
  const dataStore = useDataStore();

  return dataStore
    .getTaskList()
    .map((e) => makeTaskOption(e.duid))
    .filter((e): e is TypeaheadOption => !!e);
};

/** Doc */
const makeDocOption = (docDuid: string): TypeaheadOption | undefined => {
  const dataStore = useDataStore();
  const doc = dataStore.getDocByDuid(docDuid);
  if (!doc) {
    return undefined;
  }

  return {
    value: docDuid,
    label: doc.title,
    specialEntity: TypeaheadSpecialEntity.DOC,
    adtlSearchTerms: ["doc"],
    icon: PageIcon,
    iconArgs: {
      page: doc,
    },
    chipColorHex: DEFAULT_CHIP_COLOR,
    chipIcon: PageIcon,
    chipIconArgs: {
      page: doc,
      small: true,
    },
  };
};

export const getDocOptions = (): TypeaheadOption[] => {
  const dataStore = useDataStore();

  return dataStore
    .getDocList()
    .map((e) => makeDocOption(e.duid))
    .filter((e): e is TypeaheadOption => !!e);
};
