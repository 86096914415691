import { computed, type ComputedRef } from "vue";

import { getPropertyValueFromTask } from "~/common/properties";
import type { GroupByGroup, Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { isString } from "~/utils/common";

type SimplePropertyValue = string[] | string | number | boolean | null;

const makeKey = (primaryId: string, secondaryId: string) => JSON.stringify([primaryId, secondaryId]);

export const getOptionsForProperty = (propertyDuid: string): GroupByGroup[] =>
  useAppStore().groupByDefinitionList.find((e) => e.property.duid === propertyDuid)?.groups ?? [];

export const getTotals = (args: ComputedRef<{ tasks: Task[]; propertyDuid: string }>) => {
  const propertyOptions = computed(() => getOptionsForProperty(args.value.propertyDuid));
  const valuesToIds = computed(() => new Map(propertyOptions.value.map((e) => [e.value, e.id])));
  const property = computed(() => useDataStore().getPropertyByDuid(args.value.propertyDuid));

  const totals = computed(() => {
    const values = new Map<string, number>();
    args.value.tasks.forEach((task) => {
      if (!property.value) {
        return;
      }
      const primaryValue = getPropertyValueFromTask(property.value, task) as SimplePropertyValue;
      const primaryIds = (
        Array.isArray(primaryValue)
          ? primaryValue.map((e) => valuesToIds.value.get(e))
          : [valuesToIds.value.get(primaryValue)]
      ).filter(isString);

      primaryIds.forEach((primaryId) => {
        if (!values.has(primaryId)) {
          values.set(primaryId, 0);
        }
        values.set(primaryId, values.get(primaryId)! + 1);
      });
    });
    return propertyOptions.value.map((e) => values.get(e.id) ?? 0);
  });
  const hasData = computed(() => totals.value.some((e) => e > 0));

  return { totals, hasData, property, propertyOptions, valuesToIds };
};

export const getSeries = (
  args: ComputedRef<{ tasks: Task[]; primaryPropertyDuid: string; secondaryPropertyDuid: string | null }>
) => {
  const innerArgs = computed(() => ({ ...args.value, propertyDuid: args.value.primaryPropertyDuid }));
  const {
    totals,
    hasData,
    property: primaryProperty,
    propertyOptions: primaryPropertyOptions,
    valuesToIds: primaryValuesToIds,
  } = getTotals(innerArgs);
  const secondaryPropertyOptions = computed(() =>
    args.value.secondaryPropertyDuid ? getOptionsForProperty(args.value.secondaryPropertyDuid) : []
  );
  const secondaryProperty = computed(() => useDataStore().getPropertyByDuid(args.value.secondaryPropertyDuid ?? ""));
  const secondaryValuesToIds = computed(() => new Map(secondaryPropertyOptions.value.map((e) => [e.value, e.id])));

  const series = computed(() => {
    if (!secondaryProperty.value) {
      return [{ name: "Total", data: totals.value }];
    }

    const values = new Map<string, number>();
    args.value.tasks.forEach((task) => {
      if (!primaryProperty.value || !secondaryProperty.value) {
        return;
      }

      const primaryValue = getPropertyValueFromTask(primaryProperty.value, task) as SimplePropertyValue;
      const primaryIds = (
        Array.isArray(primaryValue)
          ? primaryValue.map((e) => primaryValuesToIds.value.get(e))
          : [primaryValuesToIds.value.get(primaryValue)]
      ).filter(isString);

      const secondaryValue = getPropertyValueFromTask(secondaryProperty.value, task) as SimplePropertyValue;
      const secondaryIds = (
        Array.isArray(secondaryValue)
          ? secondaryValue.map((e) => secondaryValuesToIds.value.get(e))
          : [secondaryValuesToIds.value.get(secondaryValue)]
      ).filter(isString);

      primaryIds.forEach((primaryId) => {
        secondaryIds.forEach((secondaryId) => {
          const key = makeKey(primaryId, secondaryId);
          if (!values.has(key)) {
            values.set(key, 0);
          }
          values.set(key, values.get(key)! + 1);
        });
      });
    });

    return secondaryPropertyOptions.value.map((secondary) => ({
      name: secondary.title,
      data: primaryPropertyOptions.value.map((primary) => values.get(makeKey(primary.id, secondary.id)) ?? 0),
    }));
  });

  return { series, totals, hasData, primaryPropertyOptions, secondaryPropertyOptions };
};
