<script setup lang="ts">
import { computed, onUnmounted } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { Placement } from "~/shared/enums";
import { makeRelativeTimeForActivityItemRef } from "~/utils/time";

const props = defineProps<{
  at: string;
  prefix?: string;
  tooltipTop?: boolean;
  cursorPointer?: boolean;
}>();

const atPretty = makeRelativeTimeForActivityItemRef(computed(() => props.at));

onUnmounted(() => {
  atPretty.value.destroy();
});
</script>

<template>
  <Tooltip :placement="tooltipTop ? Placement.TOP : Placement.BOTTOM" :text="atPretty.full" class="shrink-0">
    <span class="select-none text-xs/[22px] text-vlt" :class="cursorPointer ? 'cursor-pointer' : 'cursor-default'">
      {{ `${props.prefix ? `${props.prefix} ` : ""}${atPretty.short}` }}
    </span>
  </Tooltip>
</template>
