<script setup lang="ts">
import actions from "~/actions";
import PageIcon from "~/components/dumb/PageIcon.vue";
import DocEditor from "~/components/text/DocEditor.vue";
import { XIcon } from "~/icons";
import type { Doc } from "~/shared/types";
import { useAppStore, usePageStore, useTenantStore } from "~/stores";

const props = defineProps<{
  doc: Doc;
  hideRemove?: boolean;
  borderless?: boolean;
  enableContextMenu?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
}>();

const appStore = useAppStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const onContextMenu = (event: MouseEvent) => {
  if ((tenantStore.isDart && !pageStore.adminHidden && event.altKey) || !props.enableContextMenu) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, actions.context.doc(props.doc));
};
</script>

<template>
  <div
    class="group/doc-preview relative cursor-pointer rounded"
    :class="!borderless && 'border border-md'"
    @contextmenu="onContextMenu">
    <div class="mx-2 mt-2 h-24 overflow-hidden">
      <DocEditor :doc="doc" mini />
    </div>
    <div class="flex items-center gap-2 rounded-b border-t px-2 py-1.5 text-sm bg-lt border-md">
      <PageIcon :page="doc" small />
      <span class="truncate text-md">
        {{ doc.title }}
      </span>
    </div>
    <div
      class="absolute inset-0 rounded opacity-0 bg-md/20 group-hover/doc-preview:flex group-hover/doc-preview:opacity-100">
      <div
        v-if="!hideRemove"
        class="absolute right-1 top-1 rounded text-md hover:bg-hvy/80"
        @click.stop="emit('remove')"
        @keydown.enter.stop="emit('remove')">
        <XIcon class="icon-sm" />
      </div>
    </div>
  </div>
</template>
