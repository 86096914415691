import { makeLinkToPropertySettingsRef, makeLinkToSettingsRef } from "~/router/common";
import { EntityName } from "~/shared/enums";
import type { Notification, Page, Status } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getCommentLink, getPageLink } from "~/utils/common";

import { convertToMarkdown, convertToPlainText } from "../text/transformers";

type EntityResult = {
  title: string;
  duid: string;
};

const ENTITY_TO_SETTINGS_LINK_FN_MAP = new Map([
  [EntityName.USER, () => makeLinkToSettingsRef("teammates").value],
  [EntityName.TENANT, () => makeLinkToSettingsRef("workspace").value],
  [EntityName.STATUS, (duid: string) => makeLinkToPropertySettingsRef(duid).value],
  [EntityName.PROPERTY, (duid: string) => makeLinkToPropertySettingsRef(duid).value],
]);

export const getEntity = (duid: string, name: EntityName): EntityResult | undefined => {
  const dataStore = useDataStore();
  const tenantStore = useTenantStore();

  const getTenant = () => ({
    title: tenantStore.name,
    duid: tenantStore.duid,
  });

  const getUser = (userDuid: string) => {
    const user = dataStore.getUserByDuid(userDuid);
    if (!user) {
      return undefined;
    }

    return {
      title: user.name || user.email,
      duid: user.duid,
    };
  };

  const getComment = () => {
    const comment = dataStore.getCommentByDuid(duid);
    if (!comment) {
      return undefined;
    }

    return {
      duid: comment.duid,
      title: convertToPlainText(convertToMarkdown(comment.text)) ?? "",
    };
  };

  const getUnknown = () => undefined;

  // Map of entity methods to fetch entity data
  const ENTITY_NAME_TO_GET_FN_MAP: Record<EntityName, (duid: string) => EntityResult | undefined> = {
    [EntityName.COMMENT]: getComment,
    [EntityName.TASK]: dataStore.getTaskByDuid,
    [EntityName.DARTBOARD]: dataStore.getDartboardByDuid,
    [EntityName.DASHBOARD]: dataStore.getDashboardByDuid,
    [EntityName.VIEW]: dataStore.getViewByDuid,
    [EntityName.SPACE]: dataStore.getSpaceByDuid,
    [EntityName.DOC]: dataStore.getDocByDuid,
    [EntityName.FOLDER]: dataStore.getFolderByDuid,
    [EntityName.FORM]: dataStore.getFormByDuid,
    [EntityName.STATUS]: dataStore.getStatusByDuid,
    [EntityName.PROPERTY]: dataStore.getPropertyByDuid,
    [EntityName.TENANT]: getTenant,
    [EntityName.USER]: getUser,
    // These don't have any FE notifications yet
    [EntityName.BRAINSTORM]: getUnknown,
    [EntityName.UNKNOWN]: getUnknown,
  };

  const getFn = ENTITY_NAME_TO_GET_FN_MAP[name];
  return getFn(duid);
};

export const getNotificationEntity = ({ event }: Notification): EntityResult | undefined => {
  const { mainEntityName } = event;
  // We still want the task entity for comments
  if (mainEntityName === EntityName.COMMENT) {
    const { taskDuid } = event;
    if (!taskDuid) {
      return undefined;
    }
    return getEntity(taskDuid, EntityName.TASK);
  }

  const duidKey = `${mainEntityName.toLowerCase()}Duid` as keyof typeof event;
  const duid = event[duidKey];
  return getEntity(duid, mainEntityName);
};

const getCommentEntityLink = (entityResult: EntityResult) => {
  const dataStore = useDataStore();
  const comment = dataStore.getCommentByDuid(entityResult.duid);
  if (!comment) {
    return undefined;
  }

  const task = dataStore.getTaskByDuid(comment.taskDuid);
  if (!task) {
    return undefined;
  }

  return getCommentLink(task, comment);
};

// TODO change this to actually get/have the whole entity so we don't have to cast the entityResult as things that it's definitely not
export const getEntityLink = (entityResult: EntityResult, entityName: EntityName) => {
  const dataStore = useDataStore();

  if (entityName === EntityName.COMMENT) {
    return getCommentEntityLink(entityResult);
  }

  const linkFn = ENTITY_TO_SETTINGS_LINK_FN_MAP.get(entityName);
  if (linkFn !== undefined) {
    const linkDuid = entityName === EntityName.STATUS ? (entityResult as Status).propertyDuid : entityResult.duid;
    return linkFn(linkDuid);
  }

  return getPageLink(entityResult as Page, dataStore.getSpaceByDuid);
};
