<script setup lang="ts">
import { capitalize, computed, ref } from "vue";

import actions from "~/actions";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { SprintRolloverIcon } from "~/icons";
import { Placement } from "~/shared/enums";
import type { Page, Space } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { downTheLine } from "~/utils/fun";

// TODO a lot of this is duplicate from PagesSection
const DIALOG_TITLE = "Start next sprint";
const DIALOG_DESCRIPTION =
  "Starting the next sprint will move next tasks to active and active tasks into archive, but tasks that are not finished or canceled stay in active. This can't be undone.";

const props = defineProps<{
  page: Page;
  placement?: Placement;
  showDescriptionOption?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  startEditingTitle: [];
  startEditingDescription: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const rolloverDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);
const isOpen = computed(() => !!dropdownMenu.value?.isOpen);

const rollover = () => {
  downTheLine();
  dataStore.rollover(props.page.duid);
};

const sections = computed(() =>
  actions.context.page(props.page, props.showDescriptionOption, (eventKind: string) => {
    if (eventKind === "startEditingTitle") {
      emit("startEditingTitle");
      return;
    }
    if (eventKind === "startEditingDescription") {
      emit("startEditingDescription");
      return;
    }
    if (eventKind === "startEditingSpace") {
      appStore.setSpaceOpenInModal(props.page as Space);
      return;
    }
    if (eventKind === "startNextSprint") {
      rolloverDialog.value?.openModal();
    }
  })
);

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, sections.value);
};

defineExpose({
  isOpen,
});
</script>

<template>
  <DropdownMenu
    ref="dropdownMenu"
    :sections="sections"
    :disabled="disabled"
    :placement="placement"
    :distance="0"
    @contextmenu="onContextMenu">
    <Tooltip :disabled="disabled" :text="`${capitalize(page.pageKind)} options`" class="truncate">
      <slot />
    </Tooltip>
    <ConfirmationDialog
      ref="rolloverDialog"
      cancel-text="Cancel"
      confirm-text="Proceed"
      :icon="SprintRolloverIcon"
      :title="DIALOG_TITLE"
      :description="DIALOG_DESCRIPTION"
      @confirm="rollover" />
  </DropdownMenu>
</template>
