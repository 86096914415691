import { defineStore } from "pinia";

import { getDescendantDuids } from "~/utils/common";

import type { ItemBase } from "./shared";

type State = {
  _listItems: Map<string, ItemBase[]>;
  _draggingItems: {
    group: string;
    category: string;
    items: ItemBase[];
    originalParentDuidMap: Map<string, string | undefined>;
    originalCategory: string;
  } | null;
};

const makeMapKey = (group: string, category: string) => JSON.stringify([group, category]);

const useDragStore = defineStore("DragStore", {
  state: (): State => ({
    _listItems: new Map(),
    _draggingItems: null,
  }),
  getters: {
    getListItems() {
      return <T extends ItemBase>(group: string, category: string) =>
        (this._listItems.get(makeMapKey(group, category)) ?? []) as T[];
    },
    getDraggingItemsForGroup() {
      return <T extends ItemBase>(group: string) =>
        this._draggingItems?.group === group ? (this._draggingItems.items as T[]) : [];
    },
    getDraggingDescendantItemsForGroup() {
      return <T extends ItemBase>(group: string) => {
        if (this._draggingItems?.group !== group) {
          return [];
        }
        const innerItems = this.getListItems(group, this._draggingItems.originalCategory);
        const descendantDuids = this._draggingItems.items.reduce(
          (acc, item) => new Set([...acc, ...getDescendantDuids(innerItems, item)]),
          new Set<string>()
        );
        const descendants: ItemBase[] = innerItems.filter((e) => descendantDuids.has(e.duid));
        return descendants as T[];
      };
    },
    getDraggingItemsForCategory() {
      return <T extends ItemBase>(group: string, category: string) =>
        this._draggingItems?.group === group && this._draggingItems?.category === category
          ? (this._draggingItems.items as T[])
          : [];
    },
    showPlaceholdersForCategory() {
      return (group: string, category: string): boolean =>
        !!this._draggingItems &&
        this._draggingItems.group === group &&
        this._draggingItems.category !== category &&
        this._draggingItems.originalCategory === category;
    },
  },
  actions: {
    setDraggingItems(group: string, category: string, items: ItemBase[]) {
      this._draggingItems = {
        group,
        category,
        items,
        originalParentDuidMap: new Map(items.map((item) => [item.duid, item.parentDuid])),
        originalCategory: category,
      };
    },
    clearDraggingItems() {
      this._draggingItems = null;
    },
    setListItems(group: string, category: string, items: ItemBase[]) {
      this._listItems.set(makeMapKey(group, category), items);
    },
    moveDraggedItemsToIndex(group: string, category: string, index: number) {
      const drag = this._draggingItems;
      let newIndex = index;
      if (!drag) {
        return;
      }

      // Remove items from the old category
      const existingOldItems = this.getListItems(drag.group, drag.category);
      this.setListItems(
        drag.group,
        drag.category,
        existingOldItems.filter((e) => !drag.items.some((item) => item.duid === e.duid))
      );

      const existingNewItems = this.getListItems(group, category);
      this._draggingItems = { ...drag, category };

      if (category !== drag.originalCategory) {
        // Change index to next item after the original index that isn't a child of any other item
        const freeIndex = existingNewItems.findIndex((item, i) => !item.parentDuid && i >= index);
        newIndex = freeIndex === -1 ? existingNewItems.length : freeIndex;

        // Revert parentDuid changes if the category has changed
        drag.items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.parentDuid = drag.originalParentDuidMap.get(item.duid);
        });
      }

      // Insert items into the new category at the correct index
      this.setListItems(group, category, [
        ...existingNewItems.slice(0, newIndex),
        ...drag.items,
        ...existingNewItems.slice(newIndex),
      ]);
    },
    setDraggedItemsParentDuid(parentDuid?: string) {
      const drag = this._draggingItems;
      if (!drag || drag.category !== drag.originalCategory) {
        return;
      }

      const firstItemParentDuid = drag.items[0].parentDuid;
      drag.items.forEach((item) => {
        if (item.parentDuid !== firstItemParentDuid || item.duid === parentDuid) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        item.parentDuid = parentDuid;
      });
    },
  },
});

export default useDragStore;
