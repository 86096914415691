import { AvailabilityFilterType, CommandId, KeyboardShortcutStrokeKind } from "~/shared/enums";
import type { Command, CommandCategory, KeyboardShortcutStroke } from "~/shared/types";

const OR = {
  kind: KeyboardShortcutStrokeKind.SEPARATOR,
  content: "or",
};
const THEN = {
  kind: KeyboardShortcutStrokeKind.SEPARATOR,
  content: "then",
};

const asText = (content: string) => ({ kind: KeyboardShortcutStrokeKind.TEXT, content });
const asMarkdown = (content: string) => ({
  kind: KeyboardShortcutStrokeKind.MARKDOWN,
  content: content.replace(/^ /, "\xa0").replace(/ $/, "\xa0"),
});

export const COMMANDS_LIST: CommandCategory[] = [
  {
    title: "AI recommendations",
    commands: [
      {
        id: CommandId.RECOMMEND_PROPERTIES,
        title: "Use AI to fill out properties",
        filters: [AvailabilityFilterType.IS_ONLINE],
        strokes: {
          default: ["Alt", "P"],
          mac: ["⌥", "P"],
        },
      },
      {
        id: CommandId.RECOMMEND_SUBTASKS,
        title: "Use AI to break into subtasks",
        filters: [AvailabilityFilterType.IS_ONLINE],
        strokes: {
          default: ["Alt", "S"],
          mac: ["⌥", "S"],
        },
      },
    ],
  },
  {
    title: "Dartboard",
    commands: [
      {
        id: CommandId.CREATE_TASK,
        title: "Create a task",
        strokes: {
          default: ["C"],
        },
        aliases: ["new", "make", "add"],
      },
      {
        id: CommandId.RESUME_DRAFT,
        title: "Resume work on the task draft",
        strokes: {
          default: ["C"],
        },
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CREATE_TASK_QUICKLY,
        title: "Quickly create a task",
        strokes: {
          default: ["⇧", "C"],
        },
        aliases: ["new", "make", "add", "fast", "nlp", "title"],
      },
      {
        id: CommandId.SET_LAYOUT_TO_LIST,
        title: "Switch to list layout",
        strokes: {
          default: ["V", THEN, "L"],
        },
        aliases: ["view", "visualization", "list", "spreadsheet"],
      },
      {
        id: CommandId.SET_LAYOUT_TO_BOARD,
        title: "Switch to board layout",
        strokes: {
          default: ["V", THEN, "B"],
        },
        aliases: ["view", "visualization", "board", "kanban"],
      },
      {
        id: CommandId.SET_LAYOUT_TO_CALENDAR,
        title: "Switch to calendar layout",
        strokes: {
          default: ["V", THEN, "C"],
        },
        aliases: ["view", "visualization", "calendar", "timeline"],
      },
      {
        id: CommandId.SET_LAYOUT_TO_ROADMAP,
        title: "Switch to roadmap layout",
        strokes: {
          default: ["V", THEN, "R"],
        },
        aliases: ["view", "visualization", "roadmap", "timeline", "gantt", "flowchart"],
      },
      {
        id: CommandId.SET_LAYOUT_TO_NEXT,
        title: "Switch to next layout",
        strokes: {
          default: ["V", THEN, "N"],
        },
        aliases: ["toggle", "view", "visualization", "next", "forward", "subtasks", "indented", "collapsed", "flat"],
      },
      {
        id: CommandId.SET_LAYOUT_TO_PREVIOUS,
        title: "Switch to previous layout",
        strokes: {
          default: ["V", THEN, "P"],
        },
        aliases: ["toggle", "view", "visualization", "previous", "back", "subtasks", "indented", "collapsed", "flat"],
      },
      {
        id: CommandId.TOGGLE_FILTERS_AND_SORTS,
        title: "Show or hide filters and sorts",
        strokes: {
          default: ["Y"],
        },
        aliases: ["toggle", "search", "find", "organize"],
      },
      {
        id: CommandId.TOGGLE_FILTERS_AND_SORTS_TO_HIDDEN,
        title: "Hide filters and sorts",
        shortcutsIgnore: true,
        strokes: {
          default: ["Y"],
        },
      },
      {
        id: CommandId.TOGGLE_FILTERS_TO_SHOWN,
        title: "Show filters",
        shortcutsIgnore: true,
        strokes: {
          default: ["Y"],
        },
      },
      {
        id: CommandId.TOGGLE_SORTS_TO_SHOWN,
        title: "Show sorts",
        shortcutsIgnore: true,
        strokes: {
          default: ["Y"],
        },
      },
      {
        id: CommandId.ADD_FILTER,
        title: "Add filter",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.REMOVE_FILTER,
        title: "Remove filter",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CLEAR_FILTER,
        title: "Clear filter",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CHANGE_FILTER_APPLICABILITY,
        title: "Change how filter applies",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CHANGE_FILTER_VALUES,
        title: "Change filter values",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CHANGE_FILTER_CONNECTOR,
        title: "Change how filter values are related",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.SAVE_TASK,
        title: "Save task",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.SAVE_TASK_OPEN_IN_CREATION_MODAL,
        title: "Save task",
        shortcutsIgnore: true,
        strokes: {
          default: ["Ctrl", "↵"],
          mac: ["⌘", "↵"],
        },
      },
      {
        id: CommandId.SAVE_TASK_OPEN_IN_CREATION_MODAL_AND_CREATE_ANOTHER,
        title: "Save task and create more",
        shortcutsIgnore: true,
        strokes: {
          default: ["Ctrl", "⇧", "↵"],
          mac: ["⌘", "⇧", "↵"],
        },
      },
      {
        id: CommandId.SAVE_COMMENT,
        title: "Save comment",
        shortcutsIgnore: true,
        strokes: {
          default: ["Ctrl", "↵"],
          mac: ["⌘", "↵"],
        },
      },
      {
        id: CommandId.SHARE_FEEDBACK,
        title: "Share feedback",
        shortcutsIgnore: true,
        strokes: {
          default: ["Ctrl", "↵"],
          mac: ["⌘", "↵"],
        },
      },
      {
        id: CommandId.EDIT_TITLE,
        title: "Start editing task title",
        strokes: {
          default: ["E", OR, "⌫"],
        },
        aliases: ["change", "rename"],
      },
      {
        id: CommandId.CREATE_TASK_UNDERNEATH,
        title: "Create task underneath",
        strokes: {
          default: ["Enter"],
          mac: ["Return"],
        },
        aliases: ["new", "make", "add", "fast", "title"],
      },
      {
        id: CommandId.INDENT_TO_SUBTASK,
        title: "Indent to subtask",
        strokes: {
          default: ["Tab"],
        },
        aliases: ["parent", "convert"],
      },
      {
        id: CommandId.OUTDENT_TO_UNSUBTASK,
        title: "Outdent to unsubtask",
        strokes: {
          default: ["⇧", "Tab"],
        },
        aliases: ["parent", "un-subtask", "convert"],
      },
      {
        id: CommandId.MOVE_TASK_DOWN,
        title: "Move task down",
        strokes: {
          default: ["Alt", "↓", OR, "Alt", "J"],
          mac: ["⌥", "↓", OR, "⌥", "J"],
        },
        aliases: ["rearrange", "reorder", "shift"],
      },
      {
        id: CommandId.MOVE_TASK_UP,
        title: "Move task up",
        strokes: {
          default: ["Alt", "↑", OR, "Alt", "K"],
          mac: ["⌥", "↑", OR, "⌥", "K"],
        },
        aliases: ["rearrange", "reorder", "shift"],
      },
      {
        id: CommandId.MOVE_TASK_TO_BOTTOM,
        title: "Move task to bottom",
        strokes: {
          default: ["Ctrl", "Alt", "↓"],
          mac: ["⌘", "⌥", "↓"],
        },
        aliases: ["rearrange", "reorder", "shift"],
      },
      {
        id: CommandId.MOVE_TASK_TO_TOP,
        title: "Move task to top",
        strokes: {
          default: ["Ctrl", "Alt", "↑"],
          mac: ["⌘", "⌥", "↑"],
        },
        aliases: ["rearrange", "reorder", "shift"],
      },
      {
        id: CommandId.CHANGE_DARTBOARD,
        shortcutsIgnore: true,
        title: "Change dartboard",
        strokes: {
          default: ["Alt", "D"],
          mac: ["⌥", "D"],
        },
        aliases: ["move", "reorganize", "drag"],
      },
      {
        id: CommandId.CHANGE_STATUS,
        title: "Change status",
        strokes: {
          default: ["S"],
        },
        aliases: ["update", "set", "mark"],
      },
      {
        id: CommandId.CHANGE_STATUS_TO_DEFAULT_FINISHED,
        title: "Change status to finished",
        strokes: {
          default: ["F"],
        },
        aliases: ["update", "set", "mark", "completed", "done"],
      },
      {
        id: CommandId.CYCLE_UNSTARTED_STATUSES,
        title: "Cycle through unstarted statuses",
        strokes: {
          default: ["1"],
        },
        aliases: ["update", "set", "mark", "to-do", "todo"],
      },
      {
        id: CommandId.CYCLE_STARTED_STATUSES,
        title: "Cycle through started statuses",
        strokes: {
          default: ["2"],
        },
        aliases: ["update", "set", "mark", "doing"],
      },
      {
        id: CommandId.CYCLE_BLOCKED_STATUSES,
        title: "Cycle through blocked statuses",
        strokes: {
          default: ["3"],
        },
        aliases: ["update", "set", "mark"],
      },
      {
        id: CommandId.CYCLE_FINISHED_STATUSES,
        title: "Cycle through finished statuses",
        strokes: {
          default: ["4"],
        },
        aliases: ["update", "set", "mark"],
      },
      {
        id: CommandId.CYCLE_CANCELED_STATUSES,
        title: "Cycle through canceled statuses",
        strokes: {
          default: ["5"],
        },
        aliases: ["update", "set", "mark"],
      },
      {
        id: CommandId.CHANGE_ASSIGNEE_TO_SELF,
        title: "Set assigned to me",
        strokes: {
          default: ["0"],
        },
        aliases: ["assignee", "change", "update", "set", "mark", "add", "reassign", "myself"],
      },
      {
        id: CommandId.CHANGE_ASSIGNEE_TO_UNASSIGNED,
        title: "Set unassigned",
        strokes: {
          default: ["9"],
        },
        aliases: ["assignee", "change", "update", "set", "mark", "remove"],
      },
      {
        id: CommandId.CHANGE_ASSIGNEE,
        title: "Change assignee",
        filters: [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_DISABLED],
        strokes: {
          default: ["A"],
        },
        aliases: ["update", "set", "mark"],
      },
      {
        id: CommandId.ADD_ASSIGNEES,
        title: "Add assignees",
        filters: [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_ENABLED],
        strokes: {
          default: ["A", THEN, "A"],
        },
        aliases: ["update", "set", "mark"],
      },
      {
        id: CommandId.REMOVE_ASSIGNEES,
        title: "Remove assignees",
        filters: [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_ENABLED],
        strokes: {
          default: ["A", THEN, "R"],
        },
        aliases: ["change", "update", "set", "mark"],
      },
      {
        id: CommandId.SWAP,
        title: "Swap",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.START_TIMER,
        title: "Start timer",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.SWAP_ASSIGNEE,
        title: "Swap assignees",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CHANGE_PRIORITY,
        title: "Change priority",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: ["P"],
        },
        aliases: ["importance", "urgency", "update", "set", "mark", "p0", "p1", "p2", "p3"],
      },
      {
        id: CommandId.CHANGE_PRIORITY_TO_UNPRIORITIZED,
        title: "Set as unprioritized",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: ["Alt", "`"],
          mac: ["⌥", "`"],
        },
        aliases: ["change", "update", "mark"],
      },
      {
        id: CommandId.CHANGE_PRIORITY_TO_CRITICAL,
        title: "Set priority to critical",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: ["Alt", "1"],
          mac: ["⌥", "1"],
        },
        aliases: ["change", "update", "mark", "p0"],
      },
      {
        id: CommandId.CHANGE_PRIORITY_TO_HIGH,
        title: "Set priority to high",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: ["Alt", "2"],
          mac: ["⌥", "2"],
        },
        aliases: ["change", "update", "mark", "p1"],
      },
      {
        id: CommandId.CHANGE_PRIORITY_TO_MEDIUM,
        title: "Set priority to medium",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: ["Alt", "3"],
          mac: ["⌥", "3"],
        },
        aliases: ["change", "update", "mark", "p2"],
      },
      {
        id: CommandId.CHANGE_PRIORITY_TO_LOW,
        title: "Set priority to low",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: ["Alt", "4"],
          mac: ["⌥", "5"],
        },
        aliases: ["change", "update", "mark", "p3"],
      },
      {
        id: CommandId.ADD_TAGS,
        title: "Add tags",
        strokes: {
          default: ["T", THEN, "A"],
        },
        aliases: ["labels", "components", "change", "update", "set", "mark"],
      },
      {
        id: CommandId.REMOVE_TAGS,
        title: "Remove tags",
        strokes: {
          default: ["T", THEN, "R"],
        },
        aliases: ["labels", "components", "change", "update", "set", "mark"],
      },
      {
        id: CommandId.CHANGE_START_DATE,
        title: "Change start date",
        filters: [AvailabilityFilterType.TENANT_HAS_START_DATE_ENABLED],
        strokes: {
          default: ["Alt", "R"],
          mac: ["⌥", "R"],
        },
        aliases: ["do", "update", "set", "mark"],
      },
      {
        id: CommandId.CHANGE_DUE_DATE,
        title: "Change due date",
        filters: [AvailabilityFilterType.TENANT_HAS_DUE_DATE_ENABLED],
        strokes: {
          default: ["R"],
        },
        aliases: ["end", "do", "update", "set", "mark"],
      },
      {
        id: CommandId.CHANGE_SIZE,
        title: "Change size",
        filters: [AvailabilityFilterType.TENANT_HAS_SIZE_ENABLED],
        strokes: {
          default: ["I"],
        },
        aliases: ["estimate", "story points", "update", "set", "mark"],
      },
      {
        id: CommandId.CREATE_SUBTASKS,
        title: "Add subtask",
        strokes: {
          default: ["L"],
        },
        aliases: ["children", "break down", "split", "create"],
      },
      {
        id: CommandId.CHANGE_PARENT,
        title: "Change parent",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.ADD_ATTACHMENT,
        title: "Add attachment",
        strokes: {
          default: ["N"],
        },
        aliases: ["file", "document", "upload"],
      },
      {
        id: CommandId.ADD_LINK,
        title: "Add link",
        strokes: {
          default: ["Ctrl", "L"],
          mac: ["⌘", "L"],
        },
        aliases: ["url", "website"],
      },
      {
        id: CommandId.SET_REMINDER,
        title: "Set reminder",
        strokes: {
          default: ["H"],
        },
        aliases: ["alert", "notification", "snooze"],
      },
      {
        id: CommandId.UNDO,
        title: "Undo",
        strokes: {
          default: ["Ctrl", "Z"],
          mac: ["⌘", "Z"],
        },
      },
      {
        id: CommandId.REDO,
        title: "Redo",
        strokes: {
          default: ["Ctrl", "⇧", "Z"],
          mac: ["⌘", "⇧", "Z"],
        },
      },
      {
        id: CommandId.START_NEXT_SPRINT,
        title: "Start next sprint",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.ZOOM_OUT,
        title: "Zoom out",
        strokes: {
          default: ["Ctrl", "Scroll out"],
          mac: ["⌘", "Scroll out"],
        },
        shortcutsIgnore: true,
      },
      {
        id: CommandId.ZOOM_IN,
        title: "Zoom in",
        strokes: {
          default: ["Ctrl", "Scroll in"],
          mac: ["⌘", "Scroll in"],
        },
        shortcutsIgnore: true,
      },
    ],
  },
  {
    title: "Docs",
    commands: [
      {
        id: CommandId.CREATE_DOC,
        title: "Create a doc",
        strokes: {
          default: ["C"],
        },
        aliases: ["new", "make", "add"],
      },
      {
        id: CommandId.CREATE_REPORT,
        title: "Create a report",
        strokes: {
          default: ["C"],
        },
        shortcutsIgnore: true,
      },
    ],
  },
  {
    title: "Navigation",
    commands: [
      {
        id: CommandId.GO_DOWN,
        title: "Go down",
        strokes: {
          default: ["↓", OR, "J"],
        },
      },
      {
        id: CommandId.GO_UP,
        title: "Go up",
        strokes: {
          default: ["↑", OR, "K"],
        },
      },
      {
        id: CommandId.GO_LEFT,
        title: "Previous task",
        shortcutsIgnore: true,
        strokes: {
          default: ["←"],
        },
      },
      {
        id: CommandId.GO_RIGHT,
        title: "Next task",
        shortcutsIgnore: true,
        strokes: {
          default: ["→"],
        },
      },
      {
        id: CommandId.GO_TO_MEDIA_PREVIOUS,
        title: "Previous",
        strokes: {
          default: ["←"],
        },
      },
      {
        id: CommandId.GO_TO_MEDIA_NEXT,
        title: "Next",
        strokes: {
          default: ["→"],
        },
      },

      {
        id: CommandId.GO_TO_BOTTOM_OF_DARTBOARD,
        title: "Go to bottom",
        strokes: {
          default: ["Ctrl", "↓"],
          mac: ["⌘", "↓"],
        },
      },
      {
        id: CommandId.GO_TO_TOP_OF_DARTBOARD,
        title: "Go to top",
        strokes: {
          default: ["Ctrl", "↑"],
          mac: ["⌘", "↑"],
        },
      },
      {
        id: CommandId.HIDE_SUBTASKS,
        title: "Hide subtasks",
        filters: [AvailabilityFilterType.LAYOUT_SUBTASKS_NOT_FLAT],
        strokes: {
          default: ["←"],
        },
        aliases: ["collapse", "fold"],
      },
      {
        id: CommandId.SHOW_SUBTASKS,
        title: "Show subtasks",
        filters: [AvailabilityFilterType.LAYOUT_SUBTASKS_NOT_FLAT],
        strokes: {
          default: ["→"],
        },
        aliases: ["uncollapse", "unfold"],
      },
      {
        id: CommandId.TOGGLE_TASK_OPEN_IN_DETAIL,
        title: "Open/close task",
        strokes: {
          default: ["Space"],
        },
        aliases: ["toggle"],
      },
      {
        id: CommandId.OPEN_TASK_IN_RIGHTBAR,
        title: "Open task",
        shortcutsIgnore: true,
        strokes: {
          default: ["Space"],
        },
      },
      {
        id: CommandId.RESET_VIEW,
        title: "Reset view",
        strokes: {
          default: ["Esc"],
        },
        aliases: ["close", "exit", "quit", "escape"],
      },
      {
        id: CommandId.RESET_VIEW_CLOSE,
        title: "Close",
        shortcutsIgnore: true,
        strokes: {
          default: ["Esc"],
        },
      },
      {
        id: CommandId.EDIT_DESCRIPTION,
        title: "Open description",
        strokes: {
          default: [";"],
        },
        aliases: ["update", "edit", "description", "notes"],
      },
      {
        id: CommandId.VIEW_COMMENTS,
        title: "View comments",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.VIEW_ATTACHMENTS,
        title: "View attachments",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.VIEW_LINKS,
        title: "View links",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.SELECT_TASK,
        title: "Toggle task selection",
        strokes: {
          default: ["X"],
          mac: ["X"],
        },
        aliases: ["unselect", "check", "mark"],
      },
      {
        id: CommandId.SELECT_ALL,
        title: "Select all",
        strokes: {
          default: ["Ctrl", "A"],
          mac: ["⌘", "A"],
        },
      },
      {
        id: CommandId.SELECT_NEXT_BELOW,
        title: "Select next below",
        strokes: {
          default: ["⇧", "↓"],
          mac: ["⇧", "↓"],
        },
      },
      {
        id: CommandId.SELECT_NEXT_ABOVE,
        title: "Select next above",
        strokes: {
          default: ["⇧", "↑"],
          mac: ["⇧", "↑"],
        },
      },
      {
        id: CommandId.SELECT_ALL_BELOW,
        title: "Select all below",
        strokes: {
          default: ["Ctrl", "⇧", "↓"],
          mac: ["⌘", "⇧", "↓"],
        },
      },
      {
        id: CommandId.SELECT_ALL_ABOVE,
        title: "Select all above",
        strokes: {
          default: ["Ctrl", "⇧", "↑"],
          mac: ["⌘", "⇧", "↑"],
        },
      },
      {
        id: CommandId.TOGGLE_SEARCH,
        title: "Search tasks",
        strokes: {
          default: ["/"],
        },
        aliases: ["find", "filter", "query"],
      },
      {
        id: CommandId.MOVE_TO_ACTIVE,
        title: "Move task to Active",
        strokes: {
          default: ["Alt", "A"],
          mac: ["⌥", "A"],
        },
      },
      {
        id: CommandId.MOVE_TO_NEXT,
        title: "Move task to Next",
        strokes: {
          default: ["Alt", "N"],
          mac: ["⌥", "N"],
        },
      },
      {
        id: CommandId.MOVE_TO_BACKLOG,
        title: "Move task to Backlog",
        filters: [AvailabilityFilterType.TENANT_HAS_BACKLOG_ENABLED],
        strokes: {
          default: ["Alt", "B"],
          mac: ["⌥", "B"],
        },
      },
      {
        id: CommandId.MOVE_TO_DARTBOARD,
        title: "Move task to any dartboard",
        strokes: {
          default: ["Alt", "D"],
          mac: ["⌥", "D"],
        },
      },
      {
        id: CommandId.DELETE_TASK,
        title: "Delete task",
        strokes: {
          default: ["Ctrl", "⌫"],
          mac: ["⌘", "⌫"],
        },
        aliases: ["remove", "trash", "erase"],
      },
      {
        id: CommandId.GO_TO_HOME,
        title: "Go Home",
        strokes: {
          default: ["G", THEN, "H"],
        },
      },
      {
        id: CommandId.GO_TO_INBOX,
        title: "Go to Inbox",
        strokes: {
          default: ["G", THEN, "I"],
        },
      },
      {
        id: CommandId.GO_TO_DASHBOARD,
        title: "Go to dashboard",
        strokes: {
          default: ["G", THEN, "A"],
        },
      },
      {
        id: CommandId.GO_TO_DASHBOARDS,
        title: "Go to Dashboards",
        aliases: ["analytics", "chart", "statistics", "stats", "insights", "progress", "report"],
        shortcutsIgnore: true,
      },
      {
        id: CommandId.GO_TO_REPORTS,
        title: "Go to Reports",
        aliases: ["standup", "changelog"],
        shortcutsIgnore: true,
      },
      {
        id: CommandId.GO_TO_MY_TASKS,
        title: "Go to My tasks",
        strokes: {
          default: ["G", THEN, "M"],
        },
      },
      {
        id: CommandId.GO_TO_VIEW,
        title: "Go to view",
        strokes: {
          default: ["G", THEN, "V"],
        },
      },
      {
        id: CommandId.GO_TO_VIEWS,
        title: "Go to Views",
        aliases: ["layouts", "visualizations"],
        shortcutsIgnore: true,
      },
      {
        id: CommandId.GO_TO_TASK,
        title: "Go to task",
        strokes: {
          default: ["G", THEN, "T"],
        },
      },
      {
        id: CommandId.GO_TO_DARTBOARD,
        title: "Go to dartboard",
        strokes: {
          default: ["G", THEN, "D"],
        },
      },
      {
        id: CommandId.GO_TO_FOLDER,
        title: "Go to folder",
        strokes: {
          default: ["G", THEN, "F"],
        },
      },
      {
        id: CommandId.GO_TO_DOC,
        title: "Go to doc",
        strokes: {
          default: ["G", THEN, "O"],
        },
      },
      {
        id: CommandId.GO_TO_TRASH,
        title: "Go to trash",
        strokes: {
          default: ["G", THEN, "R"],
        },
      },
      {
        id: CommandId.COPY_LINK,
        title: "Copy link",
        strokes: {
          default: ["U"],
        },
      },
      {
        id: CommandId.COPY_BRANCH,
        title: "Copy branch name",
        strokes: {
          default: ["B"],
        },
      },
      {
        id: CommandId.REPLICATE_TASK,
        title: "Replicate task",
        strokes: {
          default: ["Ctrl", "D"],
          mac: ["⌘", "D"],
        },
      },
      {
        id: CommandId.NAV_REFRESH,
        title: "Refresh",
        strokes: {
          default: ["Ctrl", "R"],
          mac: ["⌘", "R"],
        },
      },
      {
        id: CommandId.UPDATE_DART,
        title: "Update Dart version",
        shortcutsIgnore: true,
        strokes: {
          default: ["Ctrl", "R"],
          mac: ["⌘", "R"],
        },
      },
      {
        id: CommandId.NAV_BACK,
        title: "Back",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.NAV_FORWARD,
        title: "Forward",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CREATE_VIEW,
        title: "Create a view",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CREATE_SPACE,
        title: "Create a space",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CREATE_DARTBOARD,
        title: "Create a dartboard",
        shortcutsIgnore: true,
      },
      {
        id: CommandId.CREATE_FOLDER,
        title: "Create a folder",
        shortcutsIgnore: true,
      },
    ],
  },
  {
    title: "Quick add",
    commands: [
      {
        id: CommandId.QC_PREFIX_GENERAL,
        title: "Set any property",
        strokes: {
          default: [asMarkdown("/")],
        },
      },
      {
        id: CommandId.QC_PREFIX_DARTBOARD,
        title: "Choose dartboard",
        strokes: {
          default: [asMarkdown("dartboard")],
        },
      },
      {
        id: CommandId.QC_PREFIX_STATUS,
        title: "Choose status",
        strokes: {
          default: [asMarkdown("status")],
        },
      },
      {
        id: CommandId.QC_PREFIX_ASSIGNEE,
        title: "Choose assignee",
        filters: [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_DISABLED],
        strokes: {
          default: [asMarkdown("@ "), OR, asMarkdown(" assign")],
        },
      },
      {
        id: CommandId.QC_PREFIX_ASSIGNEES,
        title: "Choose assignee(s)",
        filters: [AvailabilityFilterType.TENANT_HAS_MULTIPLE_ASSIGNEES_ENABLED],
        strokes: {
          default: [asMarkdown("@ "), OR, asMarkdown(" assign")],
        },
      },
      {
        id: CommandId.QC_PREFIX_TAG,
        title: "Choose tag(s)",
        strokes: {
          default: [asMarkdown("tag")],
        },
      },
      {
        id: CommandId.QC_PREFIX_PRIORITY,
        title: "Choose priority",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: [asMarkdown("priority")],
        },
      },
      {
        id: CommandId.QC_PREFIX_DUE_DATE,
        title: "Choose due date",
        filters: [AvailabilityFilterType.TENANT_HAS_DUE_DATE_ENABLED],
        strokes: {
          default: [asMarkdown("due")],
        },
      },
      {
        id: CommandId.QC_PREFIX_SIZE,
        title: "Choose size",
        filters: [AvailabilityFilterType.TENANT_HAS_SIZE_ENABLED],
        strokes: {
          default: [asMarkdown("size")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_DARTBOARD,
        title: "Set dartboard",
        strokes: {
          default: [asMarkdown("in [active]")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_STATUS,
        title: "Set status",
        strokes: {
          default: [asMarkdown("[doing] status")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_ASSIGNEE,
        title: "Set assignee",
        strokes: {
          default: [asMarkdown("[email] "), OR, asMarkdown(" [name]")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_TAG,
        title: "Set tag",
        strokes: {
          default: [asMarkdown("[bug] tag")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_PRIORITY,
        title: "Set priority",
        filters: [AvailabilityFilterType.TENANT_HAS_PRIORITY_ENABLED],
        strokes: {
          default: [asMarkdown("p[0-3] "), OR, asMarkdown(" [high] priority")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_DUE_DATE,
        title: "Set due date",
        filters: [AvailabilityFilterType.TENANT_HAS_DUE_DATE_ENABLED],
        strokes: {
          default: [asMarkdown("[next week] "), OR, asMarkdown(" [7/21]")],
        },
      },
      {
        id: CommandId.QC_UNPREFIX_SIZE,
        title: "Set size",
        filters: [AvailabilityFilterType.TENANT_HAS_SIZE_ENABLED],
        strokes: {
          default: [asMarkdown("[small] size")],
        },
      },
      {
        id: CommandId.QC_IGNORE_SUGGESTION,
        title: "Ignore suggestion",
        strokes: {
          default: ["⌫"],
        },
      },
    ],
  },
  {
    title: "Description",
    commands: [
      {
        id: CommandId.EDITOR_SET_STANDARD_TEXT,
        title: "Standard text",
        strokes: {
          default: ["Ctrl", "Alt", "0"],
          mac: ["⌘", "⌥", "0"],
        },
      },
      {
        id: CommandId.EDITOR_SET_LARGE_HEADER,
        title: "Large header",
        strokes: {
          default: ["Ctrl", "Alt", "1"],
          mac: ["⌘", "⌥", "1"],
        },
      },
      {
        id: CommandId.EDITOR_SET_MEDIUM_HEADER,
        title: "Medium header",
        strokes: {
          default: ["Ctrl", "Alt", "2"],
          mac: ["⌘", "⌥", "2"],
        },
      },
      {
        id: CommandId.EDITOR_SET_SMALL_HEADER,
        title: "Small header",
        strokes: {
          default: ["Ctrl", "Alt", "3"],
          mac: ["⌘", "⌥", "3"],
        },
      },
      {
        id: CommandId.EDITOR_SET_CODE_BLOCK,
        title: "Code block",
        strokes: {
          default: ["Ctrl", "Alt", "4"],
          mac: ["⌘", "⌥", "4"],
        },
      },
      {
        id: CommandId.EDITOR_SET_QUOTE,
        title: "Quote",
        strokes: {
          default: ["Ctrl", "Alt", "5"],
          mac: ["⌘", "⌥", "5"],
        },
      },
      {
        id: CommandId.EDITOR_SET_TOGGLE_BLOCK,
        title: "Toggle block",
        strokes: {
          default: ["Ctrl", "Alt", "6"],
          mac: ["⌘", "⌥", "6"],
        },
      },
      {
        id: CommandId.EDITOR_SET_HORIZONTAL_RULE,
        title: "Horizontal rule",
        strokes: {
          default: ["Ctrl", "Alt", "7"],
          mac: ["⌘", "⌥", "7"],
        },
      },
      {
        id: CommandId.EDITOR_BOLD,
        title: "Bold",
        strokes: {
          default: ["Ctrl", "B"],
          mac: ["⌘", "B"],
        },
      },
      {
        id: CommandId.EDITOR_ITALICIZE,
        title: "Italicize",
        strokes: {
          default: ["Ctrl", "I"],
          mac: ["⌘", "I"],
        },
      },
      {
        id: CommandId.EDITOR_UNDERLINE,
        title: "Underline",
        strokes: {
          default: ["Ctrl", "U"],
          mac: ["⌘", "U"],
        },
      },
      {
        id: CommandId.EDITOR_STRIKETHROUGH,
        title: "Strikethrough",
        strokes: {
          default: ["Ctrl", "S"],
          mac: ["⌘", "S"],
        },
      },
      {
        id: CommandId.EDITOR_SET_CODE_SNIPPET,
        title: "Code snippet",
        strokes: {
          default: ["Ctrl", "⇧", "6"],
          mac: ["⌘", "⇧", "6"],
        },
      },
      {
        id: CommandId.EDITOR_ADD_LINK,
        title: "Link",
        strokes: {
          default: ["Ctrl", "L"],
          mac: ["⌘", "L"],
        },
      },
      {
        id: CommandId.EDITOR_SET_CHECKLIST,
        title: "Checklist",
        strokes: {
          default: ["Ctrl", "⇧", "9"],
          mac: ["⌘", "⇧", "9"],
        },
      },
      {
        id: CommandId.EDITOR_SET_NUMBERED_LIST,
        title: "Numbered list",
        strokes: {
          default: ["Ctrl", "⇧", "7"],
          mac: ["⌘", "⇧", "7"],
        },
      },
      {
        id: CommandId.EDITOR_SET_BULLETED_LIST,
        title: "Bulleted list",
        strokes: {
          default: ["Ctrl", "⇧", "8"],
          mac: ["⌘", "⇧", "8"],
        },
      },
      {
        id: CommandId.EDITOR_TOGGLE_CHECKED,
        title: "Check or uncheck item",
        strokes: {
          default: ["Alt", "X"],
          mac: ["⌥", "X"],
        },
      },
    ],
  },
  {
    title: "Markdown",
    commands: [
      {
        id: CommandId.EDITOR_AI_ACTIONS,
        title: "AI actions",
        filters: [AvailabilityFilterType.IS_ONLINE],
        strokes: {
          default: ["/"],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_LARGE_HEADER,
        title: "Large header",
        strokes: {
          default: [asMarkdown("# "), asText("text")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_MEDIUM_HEADER,
        title: "Medium header",
        strokes: {
          default: [asMarkdown("## "), asText("text")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_SMALL_HEADER,
        title: "Small header",
        strokes: {
          default: [asMarkdown("### "), asText("text")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_CODE_BLOCK,
        title: "Code block",
        strokes: {
          default: [asMarkdown("```"), asText("code")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_QUOTE,
        title: "Quote",
        strokes: {
          default: [asMarkdown("| "), asText("quote")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_TOGGLE_BLOCK,
        title: "Toggle block",
        strokes: {
          default: [asMarkdown("> "), asText("title")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_HORIZONTAL_RULE,
        title: "Horizontal rule",
        strokes: {
          default: [asMarkdown("---")],
        },
      },
      {
        id: CommandId.MARKDOWN_BOLD,
        title: "Bold",
        strokes: {
          default: [asMarkdown("*"), asText("text"), asMarkdown("*")],
        },
      },
      {
        id: CommandId.MARKDOWN_ITALICIZE,
        title: "Italicize",
        strokes: {
          default: [asMarkdown("-"), asText("text"), asMarkdown("-")],
        },
      },
      {
        id: CommandId.MARKDOWN_UNDERLINE,
        title: "Underline",
        strokes: {
          default: [asMarkdown("_"), asText("text"), asMarkdown("_")],
        },
      },
      {
        id: CommandId.MARKDOWN_STRIKETHROUGH,
        title: "Strikethrough",
        strokes: {
          default: [asMarkdown("~"), asText("text"), asMarkdown("~")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_CODE_SNIPPET,
        title: "Code snippet",
        strokes: {
          default: [asMarkdown("`"), asText("code"), asMarkdown("`")],
        },
      },
      {
        id: CommandId.MARKDOWN_ADD_LINK,
        title: "Add link",
        strokes: {
          default: [asMarkdown("["), asText("text"), asMarkdown("]("), asText("url"), asMarkdown(")")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_CHECKLIST,
        title: "Checklist",
        strokes: {
          default: [asMarkdown("[ ] "), OR, asMarkdown(" [x]")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_NUMBERED_LIST,
        title: "Numbered list",
        strokes: {
          default: [asMarkdown("1. "), OR, asMarkdown(" 1) "), OR, asMarkdown(" 1/")],
        },
      },
      {
        id: CommandId.MARKDOWN_SET_BULLETED_LIST,
        title: "Bulleted list",
        strokes: {
          default: [asMarkdown("- "), OR, asMarkdown(" * "), OR, asMarkdown(" +")],
        },
      },
    ],
  },
  {
    title: "Configuration",
    commands: [
      {
        id: CommandId.GO_TO_SETTINGS,
        title: "Go to settings",
        strokes: {
          default: ["G", THEN, "S"],
        },
      },
      {
        id: CommandId.TOGGLE_THEME,
        title: "Switch between light and dark modes",
        strokes: {
          default: ["Ctrl", "M"],
          mac: ["⌘", "M"],
        },
        aliases: ["toggle", "theme"],
      },
    ],
  },
  {
    title: "Help",
    commands: [
      {
        id: CommandId.TOGGLE_COMMAND_CENTER,
        title: "Command center",
        strokes: {
          default: ["Ctrl", "K"],
          mac: ["⌘", "K"],
        },
      },
      {
        id: CommandId.TOGGLE_KEYBOARD_SHORTCUTS,
        title: "Keyboard shortcuts",
        strokes: {
          default: ["?"],
        },
      },
      {
        id: CommandId.LOG_OUT,
        title: "Log out",
        strokes: {
          default: ["Alt", "⇧", "Q"],
          mac: ["⌥", "⇧", "Q"],
        },
      },
    ],
  },
];

export const COMMANDS_MAP = new Map(
  COMMANDS_LIST.reduce(
    (acc, category) => [...acc, ...category.commands.map((command) => [command.id, command])] as [CommandId, Command][],
    [] as [CommandId, Command][]
  )
);

export const getStrokesForPlatform = (commandId: CommandId, isMac: boolean): KeyboardShortcutStroke[] | undefined => {
  const strokesMap = COMMANDS_MAP.get(commandId)?.strokes;
  if (!strokesMap) {
    return undefined;
  }

  return isMac ? (strokesMap.mac ?? strokesMap.default) : strokesMap.default;
};
