<script setup lang="ts">
import { ref } from "vue";

import Input from "~/components/dumb/Input.vue";
import type { Option, ValidationFunctionResult } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  option: Option;
}>();

const emit = defineEmits<{
  rename: [value: string];
}>();

const dataStore = useDataStore();

const titleInput = ref<InstanceType<typeof Input> | null>(null);

const invalidOptionTitle = (title: string): ValidationFunctionResult => {
  if (title.trim() === "") {
    return { isValid: false, error: "" };
  }
  const option = dataStore.getOptionByTitle(title, dataStore.defaultTagsProperty);
  if (option !== undefined && option.duid !== props.option.duid) {
    return { isValid: false, error: "" };
  }
  return { isValid: true };
};

const rename = () => {
  if (!titleInput.value || !titleInput.value.isValid) {
    return;
  }
  emit("rename", titleInput.value.value.trim());

  // TODO turn this on to reorder other cells alphabetically on change
  // actions.visualization.refreshCellsByNode(actions.visualization.getAllRowTasks(), ["tagDuids"]);
};
</script>

<template>
  <div class="my-1 size-full px-2 pb-2">
    <Input
      ref="titleInput"
      :init-value="option.title"
      placeholder="Rename the option"
      label="Rename option"
      input-classes="!px-2 !py-0.5"
      class="w-full"
      hide-label
      hide-error
      :validate="invalidOptionTitle"
      @click.stop
      @keydown.stop
      @enter="rename"
      @focusout="rename" />
  </div>
</template>
