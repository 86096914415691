import type { DragStartedEvent, DragStoppedEvent } from "ag-grid-community";
import { ref } from "vue";

import actions from "~/actions";
import { NO_VALUE_GROUP_BY_KEY } from "~/common/groupBy";
import { PropertyKind } from "~/shared/enums";
import type { PropertyValue, Task } from "~/shared/types";
import { useDataStore } from "~/stores";

const NUMBER_PROPERTY_KINDS_SET = new Set([PropertyKind.DEFAULT_SIZE, PropertyKind.NUMBER]);

/**
 * Calculates the new property value.
 * @param groupValue - The group value to calculate the new property value from.
 * @param propertyKind - The kind of property.
 * @returns The new property value.
 */
export const calcPropertyValueFromGroupStr = (groupValue: string, propertyKind: PropertyKind): PropertyValue => {
  if (groupValue === NO_VALUE_GROUP_BY_KEY) {
    return null;
  }

  if (NUMBER_PROPERTY_KINDS_SET.has(propertyKind)) {
    return Number(groupValue);
  }

  if (propertyKind === PropertyKind.CHECKBOX) {
    return groupValue.toLowerCase() === "true";
  }

  return groupValue;
};

export const useListColumnChange = (callback: (newOrderDuids: string[]) => void) => {
  const columnOrderRef = ref<string[]>([]);
  const isDraggingColumn = ref(false);
  const dataStore = useDataStore();

  const onDragStarted = (event: DragStartedEvent<Task>) => {
    if (event.target.classList.contains("ag-full-width-row")) {
      actions.visualization.updateGhostStyle({ hide: true });
      return;
    }

    actions.visualization.updateGhostStyle();
    if (!event.target.classList.contains("ag-header-cell")) {
      return;
    }

    isDraggingColumn.value = true;
    columnOrderRef.value = event.api.getColumnState().map((c) => c.colId);
  };

  const onDragStopped = (event: DragStoppedEvent<Task>) => {
    if (!isDraggingColumn.value) {
      return;
    }

    isDraggingColumn.value = false;
    const newOrderDuids = event.api.getColumnState().map((c) => c.colId);
    const sameOrder = columnOrderRef.value.every((c, i) => c === newOrderDuids[i]);
    columnOrderRef.value = newOrderDuids;
    if (sameOrder) {
      return;
    }

    callback(columnOrderRef.value.filter((duid) => !!dataStore.getPropertyByDuid(duid)));
  };

  return { isDraggingColumn, onDragStarted, onDragStopped };
};
