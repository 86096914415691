import { notify } from "~/components/notifications";
import { EventKind, NotificationType, OperationKind, OperationModelKind, TransactionKind } from "~/shared/enums";
import type {
  AttachmentCreate,
  AttachmentUpdate,
  BrainstormCreate,
  BrainstormUpdate,
  CommentCreate,
  CommentReactionCreate,
  CommentUpdate,
  DartboardCreate,
  DartboardUpdate,
  DashboardCreate,
  DashboardUpdate,
  DocCreate,
  DocUpdate,
  EventAdtl,
  FolderCreate,
  FolderUpdate,
  FormCreate,
  FormFieldCreate,
  FormFieldUpdate,
  FormUpdate,
  LayoutCreate,
  LayoutUpdate,
  NotificationUpdate,
  Operation,
  OperationModelData,
  OptionCreate,
  OptionUpdate,
  PropertyCreate,
  PropertyUpdate,
  RelationshipCreate,
  SpaceCreate,
  SpaceUpdate,
  StatusCreate,
  StatusUpdate,
  TaskCreate,
  TaskDocRelationship,
  TaskDocRelationshipCreate,
  TaskKindCreate,
  TaskKindUpdate,
  TaskLinkCreate,
  TaskLinkUpdate,
  TaskUpdate,
  UserDartboardLayoutCreate,
  UserUpdate,
  ViewCreate,
  ViewUpdate,
  WebhookCreate,
  WebhookUpdate,
} from "~/shared/types";
import { useEnvironmentStore, usePageStore, useTenantStore, useUserStore } from "~/stores";

import { requestManager } from "./requestManager";
import { DartError } from "./shared";

const MODEL_AND_OPERATION_TO_TRANSACTION = new Map(
  (
    [
      [[OperationModelKind.ATTACHMENT, OperationKind.CREATE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.ATTACHMENT, OperationKind.UPDATE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.ATTACHMENT, OperationKind.DELETE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.BRAINSTORM, OperationKind.CREATE], TransactionKind.BRAINSTORM_CREATE],
      [[OperationModelKind.BRAINSTORM, OperationKind.UPDATE], TransactionKind.BRAINSTORM_UPDATE],
      [[OperationModelKind.COMMENT, OperationKind.CREATE], TransactionKind.COMMENT_CREATE],
      [[OperationModelKind.COMMENT, OperationKind.UPDATE], TransactionKind.COMMENT_UPDATE],
      [[OperationModelKind.COMMENT, OperationKind.DELETE], TransactionKind.COMMENT_DELETE],
      [[OperationModelKind.COMMENT_REACTION, OperationKind.CREATE], TransactionKind.COMMENT_REACTION_CREATE],
      [[OperationModelKind.COMMENT_REACTION, OperationKind.DELETE], TransactionKind.COMMENT_REACTION_DELETE],
      [[OperationModelKind.DARTBOARD, OperationKind.CREATE], TransactionKind.DARTBOARD_CREATE],
      [[OperationModelKind.DARTBOARD, OperationKind.UPDATE], TransactionKind.DARTBOARD_UPDATE],
      [[OperationModelKind.DARTBOARD, OperationKind.UPDATE_LIST_ADD], TransactionKind.DARTBOARD_UPDATE],
      [[OperationModelKind.DARTBOARD, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.DARTBOARD_UPDATE],
      [[OperationModelKind.DARTBOARD, OperationKind.DELETE], TransactionKind.DARTBOARD_DELETE],
      [[OperationModelKind.DASHBOARD, OperationKind.CREATE], TransactionKind.DASHBOARD_CREATE],
      [[OperationModelKind.DASHBOARD, OperationKind.UPDATE], TransactionKind.DASHBOARD_UPDATE],
      [[OperationModelKind.DASHBOARD, OperationKind.UPDATE_LIST_ADD], TransactionKind.DASHBOARD_UPDATE],
      [[OperationModelKind.DASHBOARD, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.DASHBOARD_UPDATE],
      [[OperationModelKind.DASHBOARD, OperationKind.DELETE], TransactionKind.DASHBOARD_DELETE],
      [[OperationModelKind.DOC, OperationKind.CREATE], TransactionKind.DOC_CREATE],
      [[OperationModelKind.DOC, OperationKind.UPDATE], TransactionKind.DOC_UPDATE],
      [[OperationModelKind.DOC, OperationKind.UPDATE_LIST_ADD], TransactionKind.DOC_UPDATE],
      [[OperationModelKind.DOC, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.DOC_UPDATE],
      [[OperationModelKind.DOC, OperationKind.DELETE], TransactionKind.DOC_DELETE],
      [[OperationModelKind.EVENT, OperationKind.CREATE], TransactionKind.EVENT_CREATE],
      [[OperationModelKind.FOLDER, OperationKind.CREATE], TransactionKind.FOLDER_CREATE],
      [[OperationModelKind.FOLDER, OperationKind.UPDATE], TransactionKind.FOLDER_UPDATE],
      [[OperationModelKind.FOLDER, OperationKind.UPDATE_LIST_ADD], TransactionKind.FOLDER_UPDATE],
      [[OperationModelKind.FOLDER, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.FOLDER_UPDATE],
      [[OperationModelKind.FOLDER, OperationKind.DELETE], TransactionKind.FOLDER_DELETE],
      [[OperationModelKind.FORM, OperationKind.CREATE], TransactionKind.FORM_CREATE],
      [[OperationModelKind.FORM, OperationKind.UPDATE], TransactionKind.FORM_UPDATE],
      [[OperationModelKind.FORM, OperationKind.DELETE], TransactionKind.FORM_DELETE],
      [[OperationModelKind.LAYOUT, OperationKind.CREATE], TransactionKind.LAYOUT_CREATE],
      [[OperationModelKind.LAYOUT, OperationKind.UPDATE], TransactionKind.LAYOUT_UPDATE],
      [[OperationModelKind.NOTIFICATION, OperationKind.UPDATE], TransactionKind.NOTIFICATION_UPDATE],
      [[OperationModelKind.OPTION, OperationKind.CREATE], TransactionKind.OPTION_CREATE],
      [[OperationModelKind.OPTION, OperationKind.UPDATE], TransactionKind.OPTION_UPDATE],
      [[OperationModelKind.OPTION, OperationKind.DELETE], TransactionKind.OPTION_DELETE],
      [[OperationModelKind.PROPERTY, OperationKind.CREATE], TransactionKind.PROPERTY_CREATE],
      [[OperationModelKind.PROPERTY, OperationKind.UPDATE], TransactionKind.PROPERTY_UPDATE],
      [[OperationModelKind.PROPERTY, OperationKind.DELETE], TransactionKind.PROPERTY_DELETE],
      [[OperationModelKind.RELATIONSHIP, OperationKind.CREATE], TransactionKind.RELATIONSHIP_CREATE],
      [[OperationModelKind.RELATIONSHIP, OperationKind.DELETE], TransactionKind.RELATIONSHIP_DELETE],
      [[OperationModelKind.SPACE, OperationKind.CREATE], TransactionKind.SPACE_CREATE],
      [[OperationModelKind.SPACE, OperationKind.UPDATE], TransactionKind.SPACE_UPDATE_OTHER],
      [[OperationModelKind.SPACE, OperationKind.UPDATE_LIST_ADD], TransactionKind.SPACE_UPDATE_PERMS],
      [[OperationModelKind.SPACE, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.SPACE_UPDATE_PERMS],
      [[OperationModelKind.SPACE, OperationKind.DELETE], TransactionKind.SPACE_DELETE],
      [[OperationModelKind.STATUS, OperationKind.CREATE], TransactionKind.STATUS_CREATE],
      [[OperationModelKind.STATUS, OperationKind.UPDATE], TransactionKind.STATUS_UPDATE],
      [[OperationModelKind.STATUS, OperationKind.DELETE], TransactionKind.STATUS_DELETE],
      [[OperationModelKind.TASK, OperationKind.CREATE], TransactionKind.TASK_CREATE],
      [[OperationModelKind.TASK, OperationKind.UPDATE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.TASK, OperationKind.UPDATE_LIST_ADD], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.TASK, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.TASK, OperationKind.DELETE], TransactionKind.TASK_DELETE],
      [[OperationModelKind.TASK_DOC_RELATIONSHIP, OperationKind.CREATE], TransactionKind.TASK_DOC_RELATIONSHIP_CREATE],
      [[OperationModelKind.TASK_DOC_RELATIONSHIP, OperationKind.DELETE], TransactionKind.TASK_DOC_RELATIONSHIP_DELETE],
      [[OperationModelKind.TASK_KIND, OperationKind.CREATE], TransactionKind.TASK_KIND_CREATE],
      [[OperationModelKind.TASK_KIND, OperationKind.UPDATE], TransactionKind.TASK_KIND_UPDATE],
      [[OperationModelKind.TASK_KIND, OperationKind.DELETE], TransactionKind.TASK_KIND_DELETE],
      [[OperationModelKind.TASK_LINK, OperationKind.CREATE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.TASK_LINK, OperationKind.UPDATE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.TASK_LINK, OperationKind.DELETE], TransactionKind.TASK_UPDATE],
      [[OperationModelKind.USER, OperationKind.UPDATE], TransactionKind.USER_UPDATE],
      [[OperationModelKind.VIEW, OperationKind.CREATE], TransactionKind.VIEW_CREATE],
      [[OperationModelKind.VIEW, OperationKind.UPDATE], TransactionKind.VIEW_UPDATE],
      [[OperationModelKind.VIEW, OperationKind.UPDATE_LIST_ADD], TransactionKind.VIEW_UPDATE],
      [[OperationModelKind.VIEW, OperationKind.UPDATE_LIST_REMOVE], TransactionKind.VIEW_UPDATE],
      [[OperationModelKind.VIEW, OperationKind.DELETE], TransactionKind.VIEW_DELETE],
      [[OperationModelKind.WEBHOOK, OperationKind.CREATE], TransactionKind.WEBHOOK_CREATE],
      [[OperationModelKind.WEBHOOK, OperationKind.UPDATE], TransactionKind.WEBHOOK_UPDATE],
      [[OperationModelKind.WEBHOOK, OperationKind.DELETE], TransactionKind.WEBHOOK_DELETE],
    ] as [[OperationModelKind, OperationKind], TransactionKind][]
  ).map(([k, v]) => [JSON.stringify(k), v])
);

const backend = {
  _getTransactionKind: (modelKind: OperationModelKind, operationKind: OperationKind) => {
    const transactionKind = MODEL_AND_OPERATION_TO_TRANSACTION.get(JSON.stringify([modelKind, operationKind]));
    if (!transactionKind) {
      throw new Error(`Could not find TransactionKind for [${modelKind}, ${operationKind}]`);
    }
    return transactionKind;
  },
  _do: async (
    transactionKind: TransactionKind,
    operations: Operation[],
    options?: { authOverride?: boolean; nonBlocking?: boolean }
  ) => {
    const environmentStore = useEnvironmentStore();
    const pageStore = usePageStore();
    const tenantStore = useTenantStore();

    if (pageStore.isPublic && options?.authOverride !== true) {
      throw new DartError(`Transaction disallowed on public page: ${transactionKind}`);
    }

    const result = await requestManager.transact(transactionKind, operations, options?.nonBlocking);
    if (result && !result.success) {
      if (environmentStore.isLocal || tenantStore.isDart) {
        notify({
          message: `Operation failed: ${result.message}`,
          type: NotificationType.ERROR,
        });
      }
      throw new DartError(result.message);
    }
    return result;
  },
  _doOne: (
    modelKind: OperationModelKind,
    operationKind: OperationKind,
    data: OperationModelData,
    transactionKind?: TransactionKind
  ) =>
    backend._do(transactionKind ?? backend._getTransactionKind(modelKind, operationKind), [
      { kind: operationKind, model: modelKind, data },
    ]),
  _doMany: (
    modelKind: OperationModelKind,
    operationKind: OperationKind,
    data: OperationModelData[],
    transactionKind?: TransactionKind
  ) =>
    backend._do(
      transactionKind ?? backend._getTransactionKind(modelKind, operationKind),
      data.map((d) => ({ kind: operationKind, model: modelKind, data: d }))
    ),
  _create: (modelKind: OperationModelKind, data: OperationModelData, transactionKind?: TransactionKind) =>
    backend._doOne(modelKind, OperationKind.CREATE, data, transactionKind),
  _createMany: (modelKind: OperationModelKind, data: OperationModelData[], transactionKind?: TransactionKind) =>
    backend._doMany(modelKind, OperationKind.CREATE, data, transactionKind),
  _update: (modelKind: OperationModelKind, data: OperationModelData, transactionKind?: TransactionKind) =>
    backend._doOne(modelKind, OperationKind.UPDATE, data, transactionKind),
  _updateMany: (modelKind: OperationModelKind, data: OperationModelData[], transactionKind?: TransactionKind) =>
    backend._doMany(modelKind, OperationKind.UPDATE, data, transactionKind),
  _updateListAdd: (modelKind: OperationModelKind, data: OperationModelData, transactionKind?: TransactionKind) =>
    backend._doOne(modelKind, OperationKind.UPDATE_LIST_ADD, data, transactionKind),
  _updateListAddMany: (modelKind: OperationModelKind, data: OperationModelData[], transactionKind?: TransactionKind) =>
    backend._doMany(modelKind, OperationKind.UPDATE_LIST_ADD, data, transactionKind),
  _updateListRemove: (modelKind: OperationModelKind, data: OperationModelData, transactionKind?: TransactionKind) =>
    backend._doOne(modelKind, OperationKind.UPDATE_LIST_REMOVE, data, transactionKind),
  _updateListRemoveMany: (
    modelKind: OperationModelKind,
    data: OperationModelData[],
    transactionKind?: TransactionKind
  ) => backend._doMany(modelKind, OperationKind.UPDATE_LIST_REMOVE, data, transactionKind),
  _delete: (modelKind: OperationModelKind, duid: string, transactionKind?: TransactionKind) =>
    backend._doOne(modelKind, OperationKind.DELETE, { duid }, transactionKind),
  _deleteMany: (modelKind: OperationModelKind, duids: string[], transactionKind?: TransactionKind) =>
    backend._doMany(
      modelKind,
      OperationKind.DELETE,
      duids.map((duid) => ({ duid })),
      transactionKind
    ),
  attachment: {
    createMany: (attachments: AttachmentCreate[]) => backend._createMany(OperationModelKind.ATTACHMENT, attachments),
    update: (attachment: AttachmentUpdate) => backend._update(OperationModelKind.ATTACHMENT, attachment),
    delete: (duid: string) => backend._delete(OperationModelKind.ATTACHMENT, duid),
  },
  brainstorm: {
    create: (brainstorm: BrainstormCreate) => backend._create(OperationModelKind.BRAINSTORM, brainstorm),
    update: (brainstorm: BrainstormUpdate) => backend._update(OperationModelKind.BRAINSTORM, brainstorm),
  },
  comment: {
    create: (comment: CommentCreate) => backend._create(OperationModelKind.COMMENT, comment),
    update: (comment: CommentUpdate) => backend._update(OperationModelKind.COMMENT, comment),
    delete: (duid: string) => backend._delete(OperationModelKind.COMMENT, duid),
  },
  commentReaction: {
    create: (reaction: CommentReactionCreate) => backend._create(OperationModelKind.COMMENT_REACTION, reaction),
    delete: (duid: string) => backend._delete(OperationModelKind.COMMENT_REACTION, duid),
  },
  dartboard: {
    create: (dartboard: DartboardCreate, layout: LayoutCreate, userDartboardLayout: UserDartboardLayoutCreate) =>
      backend._do(TransactionKind.DARTBOARD_CREATE, [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.DARTBOARD,
          data: dartboard,
        },
        { kind: OperationKind.CREATE, model: OperationModelKind.LAYOUT, data: layout },
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.USER_DARTBOARD_LAYOUT,
          data: userDartboardLayout,
        },
      ]),
    update: (dartboard: DartboardUpdate) => backend._update(OperationModelKind.DARTBOARD, dartboard),
    updateListAdd: (dartboard: DartboardUpdate) => backend._updateListAdd(OperationModelKind.DARTBOARD, dartboard),
    updateListRemove: (dartboard: DartboardUpdate) =>
      backend._updateListRemove(OperationModelKind.DARTBOARD, dartboard),
    rollover: (
      tasksUpdates: TaskUpdate[],
      dartboardUpdates: DartboardUpdate[],
      dartboardCreate: DartboardCreate,
      layoutUpdates: LayoutUpdate[],
      layoutCreates: LayoutCreate[],
      userDartboardLayoutCreates: UserDartboardLayoutCreate[]
    ) =>
      backend._do(TransactionKind.SPRINT_ROLLOVER, [
        ...tasksUpdates.map((taskUpdate) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.TASK,
          data: taskUpdate,
        })),
        ...dartboardUpdates.map((dartboardUpdate) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.DARTBOARD,
          data: dartboardUpdate,
        })),
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.DARTBOARD,
          data: dartboardCreate,
        },
        ...layoutUpdates.map((layoutUpdate) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.LAYOUT,
          data: layoutUpdate,
        })),
        ...layoutCreates.map((layoutCreate) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.LAYOUT,
          data: layoutCreate,
        })),
        ...userDartboardLayoutCreates.map((userDartboardLayoutCreate) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.USER_DARTBOARD_LAYOUT,
          data: userDartboardLayoutCreate,
        })),
      ]),
    delete: (duid: string, layoutUpdates: LayoutUpdate[]) =>
      backend._do(TransactionKind.DARTBOARD_DELETE, [
        {
          kind: OperationKind.DELETE,
          model: OperationModelKind.DARTBOARD,
          data: { duid },
        },
        ...layoutUpdates.map((e) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.LAYOUT,
          data: e,
        })),
      ]),
  },
  dashboard: {
    create: (dashboard: DashboardCreate, layout: LayoutCreate) =>
      backend._do(TransactionKind.DASHBOARD_CREATE, [
        { kind: OperationKind.CREATE, model: OperationModelKind.LAYOUT, data: layout },
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.DASHBOARD,
          data: dashboard,
        },
      ]),
    update: (dashboard: DashboardUpdate) => backend._update(OperationModelKind.DASHBOARD, dashboard),
    updateListAdd: (dashboard: DashboardUpdate) => backend._updateListAdd(OperationModelKind.DASHBOARD, dashboard),
    updateListRemove: (dashboard: DashboardUpdate) =>
      backend._updateListRemove(OperationModelKind.DASHBOARD, dashboard),
    delete: (duid: string) => backend._delete(OperationModelKind.DASHBOARD, duid),
  },
  doc: {
    create: (doc: DocCreate, taskDocRelationships: TaskDocRelationshipCreate[]) =>
      backend._do(TransactionKind.DOC_CREATE, [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.DOC,
          data: doc,
        },
        ...taskDocRelationships.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.TASK_DOC_RELATIONSHIP,
          data: e,
        })),
      ]),
    update: (doc: DocUpdate) => backend._update(OperationModelKind.DOC, doc),
    updateListAdd: (doc: DocUpdate) => backend._updateListAdd(OperationModelKind.DOC, doc),
    updateListRemove: (doc: DocUpdate) => backend._updateListRemove(OperationModelKind.DOC, doc),
    delete: (duid: string) => backend._delete(OperationModelKind.DOC, duid),
    updateUpdateListAddMany: (docUpdates: DocUpdate[], docUpdateListAdds: DocUpdate[]) =>
      backend._do(TransactionKind.DOC_UPDATE, [
        ...docUpdateListAdds.map((e) => ({
          kind: OperationKind.UPDATE_LIST_ADD,
          model: OperationModelKind.DOC,
          data: e,
        })),
        ...docUpdates.map((e) => ({ kind: OperationKind.UPDATE, model: OperationModelKind.DOC, data: e })),
      ]),
  },
  event: {
    create: (kind: EventKind, adtl?: EventAdtl) =>
      backend._create(OperationModelKind.EVENT, { kind, actorDuid: useUserStore().duid, adtl }),
  },
  folder: {
    create: (folder: FolderCreate) => backend._create(OperationModelKind.FOLDER, folder),
    update: (folder: FolderUpdate) => backend._update(OperationModelKind.FOLDER, folder),
    updateListAdd: (folder: FolderUpdate) => backend._updateListAdd(OperationModelKind.FOLDER, folder),
    updateListRemove: (folder: FolderUpdate) => backend._updateListRemove(OperationModelKind.FOLDER, folder),
    delete: (duid: string) => backend._delete(OperationModelKind.FOLDER, duid),
  },
  form: {
    create: (form: FormCreate, formFields: FormFieldCreate[]) =>
      backend._do(TransactionKind.FORM_CREATE, [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.FORM,
          data: form,
        },
        ...formFields.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.FORM_FIELD,
          data: e,
        })),
      ]),
    update: (form: FormUpdate) => backend._update(OperationModelKind.FORM, form),
    delete: (duid: string) => backend._delete(OperationModelKind.FORM, duid),
  },
  formField: {
    create: (formField: FormFieldCreate) =>
      backend._create(OperationModelKind.FORM_FIELD, formField, TransactionKind.FORM_UPDATE),
    update: (formField: FormFieldUpdate) =>
      backend._update(OperationModelKind.FORM_FIELD, formField, TransactionKind.FORM_UPDATE),
    delete: (duid: string) => backend._delete(OperationModelKind.FORM_FIELD, duid, TransactionKind.FORM_UPDATE),
  },
  layout: {
    create: (layout: LayoutCreate, userDartboardLayout: UserDartboardLayoutCreate) =>
      backend._do(TransactionKind.LAYOUT_CREATE, [
        { kind: OperationKind.CREATE, model: OperationModelKind.LAYOUT, data: layout },
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.USER_DARTBOARD_LAYOUT,
          data: userDartboardLayout,
        },
      ]),
    update: (layout: LayoutUpdate) => backend._update(OperationModelKind.LAYOUT, layout),
    delete: (duid: string) => backend._delete(OperationModelKind.LAYOUT, duid),
  },
  property: {
    create: (property: PropertyCreate, status?: StatusCreate) => {
      const operations: Operation[] = [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.PROPERTY,
          data: property,
        },
      ];
      if (status) {
        operations.push({
          kind: OperationKind.CREATE,
          model: OperationModelKind.STATUS,
          data: status,
        });
      }
      return backend._do(TransactionKind.PROPERTY_CREATE, operations);
    },
    update: (property: PropertyUpdate) => backend._update(OperationModelKind.PROPERTY, property),
    delete: (duid: string, layoutUpdates: LayoutUpdate[]) =>
      backend._do(TransactionKind.PROPERTY_DELETE, [
        {
          kind: OperationKind.DELETE,
          model: OperationModelKind.PROPERTY,
          data: { duid },
        },
        ...layoutUpdates.map((e) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.LAYOUT,
          data: e,
        })),
      ]),
  },
  relationship: {
    create: (relationship: RelationshipCreate) => backend._create(OperationModelKind.RELATIONSHIP, relationship),
    delete: (duid: string) => backend._delete(OperationModelKind.RELATIONSHIP, duid),
    deleteAndCreate: (duidDelete: string, relationshipCreate: RelationshipCreate) =>
      backend._do(TransactionKind.RELATIONSHIP_UPDATE, [
        {
          kind: OperationKind.DELETE,
          model: OperationModelKind.RELATIONSHIP,
          data: { duid: duidDelete },
        },
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.RELATIONSHIP,
          data: relationshipCreate,
        },
      ]),
    deleteAndCreateAndUpdateTask: (
      duidDelete?: string,
      relationshipCreate?: RelationshipCreate,
      taskUpdate?: TaskUpdate
    ) => {
      const operations: Operation[] = [];
      if (duidDelete) {
        operations.push({
          kind: OperationKind.DELETE,
          model: OperationModelKind.RELATIONSHIP,
          data: { duid: duidDelete },
        });
      }
      if (relationshipCreate) {
        operations.push({
          kind: OperationKind.CREATE,
          model: OperationModelKind.RELATIONSHIP,
          data: relationshipCreate,
        });
      }
      if (taskUpdate) {
        operations.push({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.TASK,
          data: taskUpdate,
        });
      }
      return backend._do(TransactionKind.RELATIONSHIP_UPDATE, operations);
    },
  },
  space: {
    create: (space: SpaceCreate, reportsFolder: FolderCreate) =>
      backend._do(TransactionKind.SPACE_CREATE, [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.SPACE,
          data: space,
        },
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.FOLDER,
          data: reportsFolder,
        },
      ]),
    update: (space: SpaceUpdate) => backend._update(OperationModelKind.SPACE, space),
    updateListAdd: (space: SpaceUpdate) => backend._updateListAdd(OperationModelKind.SPACE, space),
    updateListRemove: (space: SpaceUpdate) => backend._updateListRemove(OperationModelKind.SPACE, space),
    updateSprintMode: (
      space: SpaceUpdate,
      dartboardCreates?: DartboardCreate[],
      layoutCreates?: LayoutCreate[],
      userDartboardLayoutCreates?: UserDartboardLayoutCreate[],
      dartboardDeletes?: string[]
    ) =>
      backend._do(TransactionKind.SPACE_UPDATE_OTHER, [
        {
          kind: OperationKind.UPDATE,
          model: OperationModelKind.SPACE,
          data: space,
        },
        ...(dartboardCreates ?? []).map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.DARTBOARD,
          data: e,
        })),
        ...(layoutCreates ?? []).map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.LAYOUT,
          data: e,
        })),
        ...(userDartboardLayoutCreates ?? []).map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.USER_DARTBOARD_LAYOUT,
          data: e,
        })),
        ...(dartboardDeletes ?? []).map((e) => ({
          kind: OperationKind.DELETE,
          model: OperationModelKind.DARTBOARD,
          data: { duid: e },
        })),
      ]),
    delete: (duid: string) => backend._delete(OperationModelKind.SPACE, duid),
  },
  status: {
    create: (status: StatusCreate) => backend._create(OperationModelKind.STATUS, status),
    update: (status: StatusUpdate) => backend._update(OperationModelKind.STATUS, status),
    delete: (duid: string, layoutUpdates: LayoutUpdate[]) =>
      backend._do(TransactionKind.STATUS_DELETE, [
        {
          kind: OperationKind.DELETE,
          model: OperationModelKind.STATUS,
          data: { duid },
        },
        ...layoutUpdates.map((e) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.LAYOUT,
          data: e,
        })),
      ]),
  },
  notification: {
    updateMany: (tasks: NotificationUpdate[]) => backend._updateMany(OperationModelKind.NOTIFICATION, tasks),
  },
  option: {
    create: (option: OptionCreate) => backend._create(OperationModelKind.OPTION, option),
    createAndTaskUpdateMany: (option: OptionCreate, taskUpdates: TaskUpdate[]) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.OPTION,
          data: option,
        },
        ...taskUpdates.map((e) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.TASK,
          data: e,
        })),
      ]),
    createAndTaskUpdateListAddMany: (option: OptionCreate, taskUpdates: TaskUpdate[]) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.OPTION,
          data: option,
        },
        ...taskUpdates.map((e) => ({
          kind: OperationKind.UPDATE_LIST_ADD,
          model: OperationModelKind.TASK,
          data: e,
        })),
      ]),
    update: (option: OptionUpdate) => backend._update(OperationModelKind.OPTION, option),
    delete: (duid: string, layoutUpdates: LayoutUpdate[]) =>
      backend._do(TransactionKind.OPTION_DELETE, [
        {
          kind: OperationKind.DELETE,
          model: OperationModelKind.OPTION,
          data: { duid },
        },
        ...layoutUpdates.map((e) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.LAYOUT,
          data: e,
        })),
      ]),
  },
  task: {
    createMany: (
      tasks: TaskCreate[],
      relationships: RelationshipCreate[] = [],
      taskDocRelationships: TaskDocRelationshipCreate[] = [],
      links: TaskLinkCreate[] = [],
      attachments: AttachmentCreate[] = []
    ) =>
      backend._do(TransactionKind.TASK_CREATE, [
        ...attachments.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.ATTACHMENT,
          data: e,
        })),
        ...tasks.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.TASK,
          data: e,
        })),
        ...relationships.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.RELATIONSHIP,
          data: e,
        })),
        ...taskDocRelationships.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.TASK_DOC_RELATIONSHIP,
          data: e,
        })),
        ...links.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.TASK_LINK,
          data: e,
        })),
      ]),
    update: (task: TaskUpdate) => backend._update(OperationModelKind.TASK, task),
    updateMany: (tasks: TaskUpdate[]) => backend._updateMany(OperationModelKind.TASK, tasks),
    updateListAdd: (task: TaskUpdate) => backend._updateListAdd(OperationModelKind.TASK, task),
    updateListAddMany: (tasks: TaskUpdate[]) => backend._updateListAddMany(OperationModelKind.TASK, tasks),
    updateListRemove: (task: TaskUpdate) => backend._updateListRemove(OperationModelKind.TASK, task),
    updateListRemoveMany: (tasks: TaskUpdate[]) => backend._updateListRemoveMany(OperationModelKind.TASK, tasks),
    updateAndUpdateListAdd: (taskUpdate: TaskUpdate, taskUpdateListAdd: TaskUpdate) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        { kind: OperationKind.UPDATE_LIST_ADD, model: OperationModelKind.TASK, data: taskUpdateListAdd },
        { kind: OperationKind.UPDATE, model: OperationModelKind.TASK, data: taskUpdate },
      ]),
    updateAndUpdateListAddMany: (taskUpdates: TaskUpdate[], taskUpdateListAdds: TaskUpdate[]) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        ...taskUpdateListAdds.map((e) => ({
          kind: OperationKind.UPDATE_LIST_ADD,
          model: OperationModelKind.TASK,
          data: e,
        })),
        ...taskUpdates.map((e) => ({ kind: OperationKind.UPDATE, model: OperationModelKind.TASK, data: e })),
      ]),
    deleteMany: (duids: string[]) => backend._deleteMany(OperationModelKind.TASK, duids),
    updateUpdateListAddAndDeleteMany: (
      taskUpdates: TaskUpdate[],
      duidDeletes: string[],
      taskUpdateListAdds: TaskUpdate[]
    ) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        ...taskUpdateListAdds.map((e) => ({
          kind: OperationKind.UPDATE_LIST_ADD,
          model: OperationModelKind.TASK,
          data: e,
        })),
        ...taskUpdates.map((e) => ({ kind: OperationKind.UPDATE, model: OperationModelKind.TASK, data: e })),
        ...duidDeletes.map((e) => ({ kind: OperationKind.DELETE, model: OperationModelKind.TASK, data: { duid: e } })),
      ]),
    createAttachmentsAndAdd: (attachmentCreates: AttachmentCreate[], taskUpdateListAdd: TaskUpdate) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        ...attachmentCreates.map((e) => ({
          kind: OperationKind.CREATE,
          model: OperationModelKind.ATTACHMENT,
          data: e,
        })),
        {
          kind: OperationKind.UPDATE_LIST_ADD,
          model: OperationModelKind.TASK,
          data: taskUpdateListAdd,
        },
      ]),
    removeAttachmentAndDelete: (taskUpdateListRemove: TaskUpdate, attachmentDuid: string) =>
      backend._do(TransactionKind.TASK_UPDATE, [
        {
          kind: OperationKind.UPDATE_LIST_REMOVE,
          model: OperationModelKind.TASK,
          data: taskUpdateListRemove,
        },
        { kind: OperationKind.DELETE, model: OperationModelKind.ATTACHMENT, data: { duid: attachmentDuid } },
      ]),
    link: {
      create: (taskLink: TaskLinkCreate) => backend._create(OperationModelKind.TASK_LINK, taskLink),
      update: (taskLink: TaskLinkUpdate) => backend._update(OperationModelKind.TASK_LINK, taskLink),
      delete: (duid: string) => backend._delete(OperationModelKind.TASK_LINK, duid),
      addUpdateAndDeleteMany: (creates: TaskLinkCreate[], updates: TaskLinkUpdate[], deletes: string[]) =>
        backend._do(TransactionKind.TASK_UPDATE, [
          ...creates.map((e) => ({ kind: OperationKind.CREATE, model: OperationModelKind.TASK_LINK, data: e })),
          ...updates.map((e) => ({ kind: OperationKind.UPDATE, model: OperationModelKind.TASK_LINK, data: e })),
          ...deletes.map((e) => ({
            kind: OperationKind.DELETE,
            model: OperationModelKind.TASK_LINK,
            data: { duid: e },
          })),
        ]),
    },
  },
  taskDocRelationship: {
    create: (relationship: TaskDocRelationship) =>
      backend._create(OperationModelKind.TASK_DOC_RELATIONSHIP, relationship),
    delete: (duid: string) => backend._delete(OperationModelKind.TASK_DOC_RELATIONSHIP, duid),
  },
  taskKind: {
    create: (taskKind: TaskKindCreate) => backend._create(OperationModelKind.TASK_KIND, taskKind),
    update: (taskKind: TaskKindUpdate) => backend._update(OperationModelKind.TASK_KIND, taskKind),
    updateMany: (taskKind: TaskKindUpdate[]) => backend._updateMany(OperationModelKind.TASK_KIND, taskKind),
    delete: (duid: string, layoutUpdates: LayoutUpdate[]) =>
      backend._do(TransactionKind.TASK_KIND_DELETE, [
        {
          kind: OperationKind.DELETE,
          model: OperationModelKind.TASK_KIND,
          data: { duid },
        },
        ...layoutUpdates.map((e) => ({
          kind: OperationKind.UPDATE,
          model: OperationModelKind.LAYOUT,
          data: e,
        })),
      ]),
  },
  user: {
    update: (user: UserUpdate) => backend._update(OperationModelKind.USER, user),
  },
  view: {
    create: (view: ViewCreate, layout: LayoutCreate) =>
      backend._do(TransactionKind.VIEW_CREATE, [
        { kind: OperationKind.CREATE, model: OperationModelKind.LAYOUT, data: layout },
        {
          kind: OperationKind.CREATE,
          model: OperationModelKind.VIEW,
          data: view,
        },
      ]),
    update: (view: ViewUpdate) => backend._update(OperationModelKind.VIEW, view),
    updateListAdd: (view: ViewUpdate) => backend._updateListAdd(OperationModelKind.VIEW, view),
    updateListRemove: (view: ViewUpdate) => backend._updateListRemove(OperationModelKind.VIEW, view),
    delete: (duid: string) => backend._delete(OperationModelKind.VIEW, duid),
  },
  webhook: {
    create: (webhook: WebhookCreate) => backend._create(OperationModelKind.WEBHOOK, webhook),
    update: (webhook: WebhookUpdate) => backend._update(OperationModelKind.WEBHOOK, webhook),
    delete: (duid: string) => backend._delete(OperationModelKind.WEBHOOK, duid),
  },
};

export default backend;
