<script setup lang="ts">
import { computed } from "vue";

import { getPropertyValueFromTask } from "~/common/properties";
import { NumberChartAggregation } from "~/shared/enums";
import type { StatisticConfigNumber, Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

const props = defineProps<{
  tasks: Task[];
  config: StatisticConfigNumber;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const property = computed(() => dataStore.getPropertyByDuid(props.config.adtl.propertyDuid ?? ""));
const value = computed<null | number>(() => {
  const { aggregation, filterByValueId, propertyDuid } = props.config.adtl;
  if (!property.value) {
    return propertyDuid === null ? props.tasks.length : null;
  }

  const valueDefinition = (
    appStore.groupByDefinitionList.find((e) => e.property.duid === props.config.adtl.propertyDuid)?.groups ?? []
  ).find((e) => e.id === filterByValueId);
  const filteredTasks =
    filterByValueId !== undefined
      ? props.tasks.filter(
          (e) => property.value && getPropertyValueFromTask(property.value, e) === valueDefinition?.value
        )
      : props.tasks;

  switch (true) {
    case aggregation === NumberChartAggregation.SUM: {
      return filteredTasks.reduce((acc, task) => {
        if (!property.value) {
          return acc;
        }
        const taskValue = getPropertyValueFromTask(property.value, task);
        return acc + (typeof taskValue === "number" ? taskValue : 0);
      }, 0);
    }
    case aggregation === NumberChartAggregation.AVG: {
      return (
        filteredTasks.reduce((acc, task) => {
          if (!property.value) {
            return acc;
          }
          const taskValue = getPropertyValueFromTask(property.value, task);
          return acc + (typeof taskValue === "number" ? taskValue : 0);
        }, 0) / filteredTasks.length
      );
    }
    case aggregation === NumberChartAggregation.COUNT: {
      return filteredTasks.length;
    }
    default: {
      return null;
    }
  }
});
const valueNorm = computed(() => (value.value === null ? "-" : value.value.toFixed(0)));
</script>

<template>
  <div class="flex size-full grow select-none items-center justify-center overflow-hidden">
    <svg width="100%" height="100%" :viewBox="`0 0 ${valueNorm.length * 10} 20`" preserveAspectRatio="xMidYMid meet">
      <text
        class="fill-gray-700 font-semibold dark:fill-zinc-300"
        x="50%"
        y="55%"
        dominant-baseline="middle"
        text-anchor="middle">
        {{ valueNorm }}
      </text>
    </svg>
  </div>
</template>
