<script setup lang="ts">
import { computed, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import { getPropertyConfig } from "~/common/properties";
import Drag from "~/components/drag/Drag.vue";
import BoardTaskCard from "~/components/visualization/board/BoardTaskCard.vue";
import { COLUMN_WIDTH } from "~/components/visualization/board/common";
import GroupHeader from "~/components/visualization/list/GroupHeader.vue";
import { colorsByTheme } from "~/constants/style";
import { EditorMode, SubtaskDisplayMode } from "~/shared/enums";
import type { GroupByGroup, TaskAbsenteeMaybe, TaskAbsenteeMaybeParentMaybe } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";

const props = defineProps<{
  column: GroupByGroup;
  tasks: TaskAbsenteeMaybe[];
  createTask: () => void;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const dragArea = ref<ComponentExposed<typeof Drag<TaskAbsenteeMaybeParentMaybe, typeof BoardTaskCard>> | null>(null);

const colors = computed(() => colorsByTheme[pageStore.theme]);

const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);

const tasksNormalized = computed(() => {
  if (!notFlat.value) {
    return props.tasks;
  }

  // Make a set of all roots while making a traditional parent -> Set<child> map
  const rootDuids = new Set<string>();
  const taskDuidToChildDuids = new Map<string, Set<string>>();
  const nonAbsenteeTasks = props.tasks.filter((e) => !e.absentee);
  const nonAbsenteeDuids = new Set(nonAbsenteeTasks.map((e) => e.duid));
  nonAbsenteeTasks.forEach((task) => {
    let ancestorDuids = dataStore.getAncestorDuids(task);
    ancestorDuids.push(task.duid);
    if (!appStore.showAbsentees) {
      const firstAbsent = ancestorDuids.findLastIndex((e) => !nonAbsenteeDuids.has(e));
      ancestorDuids = ancestorDuids.slice(firstAbsent + 1);
    }
    rootDuids.add(ancestorDuids[0]);
    ancestorDuids.forEach((ancestor, index) => {
      if (!taskDuidToChildDuids.has(ancestor)) {
        taskDuidToChildDuids.set(ancestor, new Set<string>());
      }
      if (index === ancestor.length - 1) {
        return;
      }
      const childDuids = taskDuidToChildDuids.get(ancestor);
      if (!childDuids) {
        return;
      }
      childDuids.add(ancestorDuids[index + 1]);
    });
  });
  // convert all of the tasks to TaskWithParent
  const tasksWithParent = new Map<string, TaskAbsenteeMaybeParentMaybe>();
  taskDuidToChildDuids.forEach((_, taskDuid) => {
    const task = dataStore.getTaskByDuid(taskDuid);
    if (!task) {
      return;
    }
    tasksWithParent.set(taskDuid, { ...task });
  });
  taskDuidToChildDuids.forEach((childDuids, taskDuid) => {
    const task = tasksWithParent.get(taskDuid);
    if (!task) {
      return;
    }
    childDuids.forEach((childDuid) => {
      const childTask = tasksWithParent.get(childDuid);
      if (!childTask) {
        return;
      }
      if (!task.expanded) {
        tasksWithParent.delete(childDuid);
        return;
      }
      tasksWithParent.set(childDuid, { ...childTask, parentDuid: taskDuid });
    });
  });
  tasksWithParent.forEach((task) => {
    if (nonAbsenteeDuids.has(task.duid)) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    task.absentee = true;
  });

  return Array.from(tasksWithParent.values());
});

const dragging = computed(() => appStore.dragging?.group === "board");
const noTasks = computed(() => props.tasks.length === 0);
const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
const backgroundColor = computed(() => fromHexToHexWithAlpha(props.column.colorHex, 0.2 + modeAlphaMod.value));

const editTask = (duid: string) => {
  const boardTaskCards = dragArea.value?.itemRefs;
  if (!boardTaskCards) {
    return;
  }
  const boardTaskCard = boardTaskCards.get(duid);
  if (!boardTaskCard) {
    return;
  }
  boardTaskCard.startEditingTitle();
  boardTaskCard.$el.scrollIntoView(false);
};

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

const getComponentProps = (task: TaskAbsenteeMaybeParentMaybe) => ({
  task,
  column: props.column,
  class: "task",
});

const groupByDefinition = computed(() => appStore.groupByDefinition);
const propertyConfig = computed(() => getPropertyConfig(groupByDefinition.value.property.kind));
const change = (columnId: string, tasks: TaskAbsenteeMaybeParentMaybe[]) => {
  if (!groupByDefinition.value) {
    return;
  }

  // TODO Improve when we support multiselect
  tasks.forEach((task) => {
    const newParentDuid = task.parentDuid ?? "";
    if (newParentDuid === task.duid) {
      return;
    }
    const { property, groups: items } = groupByDefinition.value;

    /* Find the column */
    const column = items.find((e) => e.id === columnId);
    if (!column) {
      return;
    }

    const newParent = dataStore.getTaskByDuid(newParentDuid) ?? null;
    dataStore.changeParent(
      [{ task, order: task.order, partialTask: propertyConfig.value.getPartialTask(property, {}, column.value) }],
      newParent
    );
  });
};

defineExpose({
  editTask,
});
</script>

<template>
  <div
    class="flex h-fit max-h-full flex-col content-start gap-1 rounded-lg px-1 pb-2 pt-1"
    :class="{
      'bg-[--backgroundColor]': !noTasks || dragging,
      'min-h-[300px] bg-gradient-to-b from-[--backgroundColor]': noTasks && !dragging,
      'h-full': dragging,
    }"
    :style="{
      '--backgroundColor': backgroundColor,
      '--highlight': colors.bgStd,
    }">
    <GroupHeader :group="column" :editor-mode="EditorMode.BOARD" @create-task="createTask" />
    <Drag
      ref="dragArea"
      group="board"
      class="px-1"
      :category="column.id"
      :items="tasksNormalized"
      :component="BoardTaskCard"
      :get-component-props="getComponentProps"
      drop-area-classes="dart-none"
      :min-item-size="42"
      :style="{ maxWidth: COLUMN_WIDTH }"
      :disable-parent-change="!notFlat"
      @click="deselectAll"
      @keydown.enter="deselectAll"
      @change="change">
      <slot />
    </Drag>
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border: none;
}
</style>
