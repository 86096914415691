<script setup lang="ts">
import Toggle from "~/components/dumb/Toggle.vue";
import { ButtonSize, PropertyKind } from "~/shared/enums";
import type { PropertyAnyUser, PropertyConfig, PropertyUpdate } from "~/shared/types";
import { useDataStore } from "~/stores";

const SINGLE_TITLE = "Assignee";
const MULTIPLE_TITLE = "Assignees";

const props = defineProps<{
  property: PropertyAnyUser;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const updateNameShown = (isNameShown: boolean) => {
  dataStore.updateProperty({ duid: props.property.duid, adtl: { ...props.property.adtl, isNameShown } });
};

const updateIsMultiple = (isMultiple: boolean) => {
  const propertyUpdate: PropertyUpdate = { duid: props.property.duid, adtl: { ...props.property.adtl, isMultiple } };
  if (props.property.kind === PropertyKind.DEFAULT_ASSIGNEES) {
    if (isMultiple && props.property.title === SINGLE_TITLE) {
      propertyUpdate.title = MULTIPLE_TITLE;
    } else if (!isMultiple && props.property.title === MULTIPLE_TITLE) {
      propertyUpdate.title = SINGLE_TITLE;
    }
  }
  dataStore.updateProperty(propertyUpdate);
};
</script>

<template>
  <div class="flex w-full flex-col items-center gap-4">
    <Toggle
      v-if="property.kind === PropertyKind.USER || property.kind === PropertyKind.DEFAULT_ASSIGNEES"
      class="w-full"
      :size="ButtonSize.SMALL"
      :value="'isMultiple' in property.adtl && property.adtl.isMultiple"
      text-sm
      text-lt
      label="Multiple users"
      description="This will allow you to assign multiple users to this property"
      @update="(e) => updateIsMultiple(e)" />
    <Toggle
      class="w-full"
      :size="ButtonSize.SMALL"
      :value="!!props.property.adtl.isNameShown"
      label="Show name"
      text-sm
      text-lt
      description="Always show the name of the user rather than just their avatar"
      @update="(e) => updateNameShown(e)" />
  </div>
</template>
