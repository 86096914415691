<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import Button from "~/components/dumb/Button.vue";
import EmptyState from "~/components/dumb/EmptyState.vue";
import {
  BrainstormIcon,
  DartboardIcon,
  DashboardIcon,
  EmptyTrashIcon,
  FilterIcon,
  FolderIcon,
  InboxIcon,
  PlusIcon,
  PremiumFeatureIcon,
  ResetIcon,
  SearchIcon,
  SpaceFieldIcon,
  ViewIcon,
} from "~/icons";
import { ButtonSize, ButtonStyle, CommandId, DartboardKind, IconSize, PageKind, ViewKind } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";

import UpgradeConfirmationDialog from "./UpgradeConfirmationDialog.vue";

const props = defineProps<{
  isFilterMode?: boolean;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const isSearch = computed(() => appStore.currentPage?.kind === ViewKind.SEARCH);
const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isDartboard = computed(() => appStore.currentPage?.pageKind === PageKind.DARTBOARD);
const isDashboardsRoot = computed(() => appStore.currentPage?.pageKind === PageKind.DASHBOARDS_ROOT);
const isView = computed(() => appStore.currentPage?.pageKind === PageKind.VIEW);
const isSpace = computed(() => appStore.currentPage?.pageKind === PageKind.SPACE);
const isFolder = computed(() => appStore.currentPage?.pageKind === PageKind.FOLDER);
const isInbox = computed(() => appStore.currentPage?.pageKind === PageKind.INBOX);
const isFilterNorm = computed(() => props.isFilterMode || isView.value);
const isPublicView = computed(() => pageStore.isPublicView);
const isViewsRoot = computed(() => appStore.currentPage?.pageKind === PageKind.VIEWS_ROOT);
const isFinishedDartboard = computed(() => appStore.currentPage?.kind === DartboardKind.FINISHED);

const entityName = computed(() => appStore.currentPage?.pageKind ?? "page");
const unitName = computed(() => (isSpace.value ? "report" : isFolder.value ? "doc" : "task"));
const pageDisplayName = computed(() => getPageDisplayName(appStore.currentPage, dataStore.getSpaceByDuid));

const mainIcon = computed(() =>
  isSearch.value
    ? SearchIcon
    : isTrash.value
      ? EmptyTrashIcon
      : isSpace.value
        ? SpaceFieldIcon
        : isFolder.value
          ? FolderIcon
          : isViewsRoot.value
            ? ViewIcon
            : isInbox.value
              ? InboxIcon
              : isFilterNorm.value && !isPublicView.value
                ? FilterIcon
                : isDashboardsRoot.value
                  ? DashboardIcon
                  : DartboardIcon
);
const title = computed(() =>
  isTrash.value
    ? "So clean!"
    : isInbox.value
      ? "Great job!"
      : isViewsRoot.value
        ? "No views"
        : isDashboardsRoot.value
          ? "No dashboards"
          : (isSearch.value || isFilterNorm.value) && !isPublicView.value
            ? `No ${unitName.value}s found`
            : `Empty ${entityName.value}`
);
const description = computed(() =>
  isTrash.value
    ? "The trash is empty."
    : isInbox.value
      ? "You have no unread notifications in your inbox."
      : isViewsRoot.value
        ? "You haven't made any views yet. Get started by creating a new one!"
        : isDashboardsRoot.value
          ? "You haven't made any dashboards yet. Get started by creating a new one!"
          : isTrash.value
            ? undefined
            : `${(isSearch.value || isView.value) && !isPublicView.value ? "There are no" : `${pageDisplayName.value} doesn't have any`} ${
                unitName.value
              }s${
                (isSearch.value || isFilterNorm.value) && !isPublicView.value
                  ? ` that match the current ${isSearch.value ? "search" : "filters"}.`
                  : isFinishedDartboard.value
                    ? "."
                    : ` yet.${!isPublicView.value ? " Get started by creating a new one!" : ""}`
              }`
);

const buttonText = computed(() =>
  isSearch.value || isTrash.value || isInbox.value || isPublicView.value
    ? undefined
    : isViewsRoot.value
      ? "Create view"
      : isDashboardsRoot.value
        ? "Create dashboard"
        : isFilterNorm.value && !appStore.usingDefaultFilters
          ? "Reset filters"
          : isFinishedDartboard.value
            ? undefined
            : `New ${unitName.value}`
);
const buttonIcon = computed(() => (isFilterNorm.value && !appStore.usingDefaultFilters ? ResetIcon : PlusIcon));
const onButtonClick = () => {
  if (isFilterNorm.value && !appStore.usingDefaultFilters) {
    appStore.resetFilters();
  } else {
    actions.visualization.contextualCreate();
  }
};
const buttonCommand = computed(() =>
  isFilterNorm.value
    ? undefined
    : isSpace.value
      ? CommandId.CREATE_REPORT
      : isFolder.value
        ? CommandId.CREATE_DOC
        : isViewsRoot.value
          ? CommandId.CREATE_VIEW
          : isDashboardsRoot.value
            ? CommandId.CREATE_DASHBOARD
            : CommandId.CREATE_TASK
);
</script>

<template>
  <EmptyState
    :title="title"
    :description="description"
    :main-icon="mainIcon"
    :button-command="buttonCommand"
    :button-icon="buttonIcon"
    :button-text="buttonText"
    @click="onButtonClick">
    <template v-if="isDartboard && !isFilterNorm && !isFinishedDartboard" #extraButton>
      <UpgradeConfirmationDialog feature-action="brainstorm with AI" :disabled="tenantStore.isPremium">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :size="ButtonSize.SMALL"
          :icon-size="IconSize.S"
          text="Brainstorm"
          :icon="BrainstormIcon"
          :icon-after="tenantStore.isPremium ? undefined : PremiumFeatureIcon"
          class="pointer-events-auto !pl-2.5"
          @click="() => tenantStore.isPremium && appStore.setBrainstormModalOpen(true)" />
      </UpgradeConfirmationDialog>
    </template>
  </EmptyState>
</template>
