<script setup lang="ts">
import { computed, ref } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import IconOrEmojiPicker from "~/components/dumb/IconOrEmojiPicker.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { DEFAULT_SKIN_TONE_EMOJI, getEmojiWithSkinTone, removeSkinTone } from "~/constants/iconAndEmoji";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { EmojiIcon } from "~/icons";
import { DropdownMenuItemKind, EmojiSkinTone, IconKind, Placement } from "~/shared/enums";
import type { Comment } from "~/shared/types";
import { useAppStore, useDataStore, useUserStore } from "~/stores";

const BUTTON_STYLE = "flex items-center rounded p-0.5 text-lt focus-ring-std print:hidden";

const props = defineProps<{
  comment: Comment;
  isContrast?: boolean;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const userStore = useUserStore();

const emojiPicker = ref<InstanceType<typeof DropdownMenu> | null>(null);

const sections = computed(() => [
  {
    title: "React",
    items: [
      {
        title: "React",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: IconOrEmojiPicker,
        componentArgs: {
          iconKind: IconKind.EMOJI,
          iconNameOrEmoji: DEFAULT_SKIN_TONE_EMOJI,
          colorHex: DEFAULT_CHIP_COLOR,
          emojisOnly: true,
          onSelectSkinTone: (newSkinTone: EmojiSkinTone): void => {
            appStore.setSkinTone(newSkinTone);
          },
          onSelectIconOrEmoji: (_: IconKind, emoji: string) => {
            const stripped = removeSkinTone(emoji);
            const existingReaction = props.comment.reactions.find(
              (e) => e.authorDuid === userStore.duid && removeSkinTone(e.emoji) === stripped
            );
            if (existingReaction) {
              dataStore.deleteCommentReaction(props.comment.duid, existingReaction.duid);
            } else {
              dataStore.createCommentReaction(props.comment.duid, getEmojiWithSkinTone(emoji, appStore.skinTone));
            }
          },
          onAfterSelect: () => {
            emojiPicker.value?.close();
          },
        },
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu
    ref="emojiPicker"
    :sections="sections"
    :placement="Placement.BOTTOM_RIGHT"
    :max-height-pixels="480"
    :width-pixels="383"
    :distance="0">
    <Tooltip text="Add reaction">
      <button type="button" :class="[BUTTON_STYLE, isContrast ? 'hover:bg-lt' : 'hover:bg-md']">
        <span class="sr-only">Add reaction</span>
        <EmojiIcon class="icon-sm" aria-hidden="true" />
      </button>
    </Tooltip>
  </DropdownMenu>
</template>
