<script setup lang="ts">
import { computed } from "vue";

import AttachmentEditor from "~/components/dumb/AttachmentEditor.vue";
import AttachmentInputButton from "~/components/dumb/AttachmentInputButton.vue";
import { EditorMode } from "~/shared/enums";
import type { Attachment } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  attachmentDuids: string[];
  isSubmitMode?: boolean;
}>();

const emit = defineEmits<{
  update: [value: string[]];
}>();

const dataStore = useDataStore();

const attachments = computed(() => dataStore.getAttachmentsByDuidsOrdered(props.attachmentDuids));

const addAttachments = async (fileList: File[]) => {
  const orders = getOrdersBetween(
    dataStore.attachmentList[dataStore.attachmentList.length - 1]?.order,
    undefined,
    fileList.length
  );
  const res = await dataStore.createAttachments(fileList.map((file, index) => ({ file, order: orders[index] })));
  const updatedDuidSet = new Set([...res.map(({ attachment }) => attachment.duid), ...props.attachmentDuids]);
  emit("update", [...updatedDuidSet]);
};

const removeAttachment = (attachment: Attachment) => {
  dataStore.deleteAttachment(attachment);
  emit(
    "update",
    props.attachmentDuids.filter((duid) => duid !== attachment.duid)
  );
};
</script>

<template>
  <div
    class="flex w-full flex-col rounded"
    :class="{
      'border border-md hover:bg-lt': attachments.length === 0 || !isSubmitMode,
    }">
    <AttachmentInputButton
      v-if="!props.isSubmitMode || attachments.length === 0"
      :editor-mode="EditorMode.FORM"
      :num-of-attachments="attachments.length"
      @input="addAttachments" />

    <AttachmentEditor
      v-else
      class="-ml-3"
      :attachments="attachments"
      @add="addAttachments"
      @remove="removeAttachment" />
  </div>
</template>
