<script setup lang="ts">
import Toggle from "~/components/dumb/Toggle.vue";
import StatusWorkflow from "~/components/StatusWorkflow.vue";
import { ButtonSize, PropertyKind } from "~/shared/enums";
import type { PropertyAnyStatus, PropertyConfig } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  property: PropertyAnyStatus;
  propertyConfig: PropertyConfig<PropertyKind.DEFAULT_STATUS> | PropertyConfig<PropertyKind.STATUS>;
}>();

const dataStore = useDataStore();

const updateNameShown = (isNameShown: boolean) => {
  dataStore.updateProperty({
    duid: props.property.duid,
    adtl: { ...props.property.adtl, isNameShown },
  });
};
</script>

<template>
  <div class="flex flex-col gap-3">
    <Toggle
      class="w-full"
      :size="ButtonSize.SMALL"
      :value="!!props.property.adtl.isNameShown"
      label="Show name"
      text-sm
      text-lt
      description="Always show the name of the status rather than just the icon"
      @update="(e) => updateNameShown(e)" />
    <StatusWorkflow class="pt-4" :property="property" :property-config="propertyConfig" />
  </div>
</template>
