<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import { getPropertyConfig } from "~/common/properties";
import DropZone from "~/components/dumb/DropZone.vue";
import { EditorMode } from "~/shared/enums";
import type { GroupByGroup, Task, TaskUpdate } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";

import CollapsedGroupHeader from "../list/CollapsedGroupHeader.vue";

const props = defineProps<{
  column: GroupByGroup;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();

const groupByDefinition = computed(() => appStore.groupByDefinition);
const propertyConfig = computed(() => getPropertyConfig(groupByDefinition.value.property.kind));

const onDrop = (_: string, item: unknown) => {
  const task = item as Partial<Task>;
  if (!task.duid || !groupByDefinition.value) {
    return;
  }

  actions.visualization.selectRowByIdAndScroll(task.duid);
  const update: TaskUpdate = {
    duid: task.duid,
  };

  const { property } = groupByDefinition.value;
  Object.assign(update, propertyConfig.value.getPartialTask(property, {}, props.column.value));

  dataStore.updateTasks([update]);
};
</script>

<template>
  <DropZone v-slot="{ hovering }" :group="['board']" @on-drop="onDrop">
    <CollapsedGroupHeader :editor-mode="EditorMode.BOARD" :group="column" :hovering="hovering" />
  </DropZone>
</template>
