<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import type { Option } from "~/shared/types";

const props = defineProps<{
  option: Option;
}>();

const dropdownSections = computed(() => actions.context.option(props.option));
</script>

<template>
  <DropdownMenu :sections="dropdownSections" :distance="0" :max-height-pixels="566" @click.stop @keydown.enter.stop>
    <Tooltip text="Modify option">
      <slot />
    </Tooltip>
  </DropdownMenu>
</template>
