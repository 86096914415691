<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { Dropdown, Menu } from "floating-vue";
import moment from "moment";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import Avatar from "~/components/dumb/Avatar.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { PlayIcon, StopIcon, TimeTrackingFieldIcon } from "~/icons";
import { ButtonStyle, CommandId, EditorMode, Placement } from "~/shared/enums";
import type { PropertyAnyTimeTracking, PropertyValueForKind, Task, TimeTracking, User } from "~/shared/types";
import { useDataStore, usePageStore, useUserStore } from "~/stores";
import { getDurationText, getTimeTrackingDurationText } from "~/utils/time";

type UserTimeElapsedAggregate = {
  user: User;
  userTimeElapsed: string;
};

type Value = PropertyValueForKind<PropertyAnyTimeTracking>;
const props = defineProps<{
  property: PropertyAnyTimeTracking;
  tasks: Task[];
  editorMode: EditorMode;
  value?: Value;
}>();

const emit = defineEmits<{
  update: [value?: Value];
}>();

const currentInstance = getCurrentInstance();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);

// TODO this only works for one value
const timeTracking = computed(() => {
  if (isFormMode.value) {
    return props.value ?? [];
  }
  return getPropertyValueFromTask(props.property, props.tasks[0]);
});

const hasTimeTracking = computed(() => timeTracking.value.length > 0);

const defaultDistance = computed(() => (isFormMode.value ? 28 : 0));
const calcDistance = ref(defaultDistance.value);
const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);
const buttonRef = ref<HTMLButtonElement | null>(null);
const recalculateDistance = () => {
  calcDistance.value = defaultDistance.value;
  if (!buttonRef.value) {
    return;
  }
  calcDistance.value -= buttonRef.value.getBoundingClientRect().height;
};
const assignButtonRef = (elem: HTMLButtonElement | null) => {
  buttonRef.value = elem;
  recalculateDistance();
};
useResizeObserver(buttonRef, recalculateDistance);

// Time elapsed for all time tracking entries
const totalTimeElapsed = computed(() => getTimeTrackingDurationText(timeTracking.value));

const now = ref(new Date());

const focusDiv = ref<HTMLDivElement | null>(null);
const onShow = () => {
  nextTick(() => {
    focusDiv.value?.focus();
  });
};

const currentUserEntry = computed(() =>
  timeTracking.value.find((entry) => entry.userDuid === userStore.duid && !entry.finishedAt)
);
const timeElapsedText = computed(() =>
  currentUserEntry.value
    ? getDurationText(moment.duration(moment(now.value).diff(moment(currentUserEntry.value.startedAt))))
    : totalTimeElapsed.value
);

const stopTimeTracking = () => {
  if (!currentUserEntry.value) {
    return;
  }

  currentUserEntry.value.finishedAt = new Date().toISOString();

  if (isFormMode.value) {
    const updatedTimeTracking = timeTracking.value.map((entry) => {
      if (!currentUserEntry.value || entry !== currentUserEntry.value) {
        return entry;
      }
      return currentUserEntry.value;
    });
    emit("update", updatedTimeTracking);
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, timeTracking.value),
    }))
  );
};

const startOrStopTimeTracking = () => {
  if (currentUserEntry.value) {
    stopTimeTracking();
    return;
  }

  const newEntry = {
    userDuid: userStore.duid,
    startedAt: new Date().toISOString(),
    finishedAt: null,
  };

  if (isFormMode.value) {
    emit("update", [...timeTracking.value, newEntry]);
    return;
  }

  dataStore.updateTasks(
    props.tasks.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, [...timeTracking.value, newEntry]),
    }))
  );
};

const userTimeElapsedAggregate = computed(() => {
  const userDuidToTimeTrackingMap = new Map<string, TimeTracking>();
  timeTracking.value.forEach((entry: TimeTracking[number]) => {
    const userEntries: TimeTracking = userDuidToTimeTrackingMap.get(entry.userDuid) ?? [];
    userEntries.push(entry);
    userDuidToTimeTrackingMap.set(entry.userDuid, userEntries);
  });

  const result: UserTimeElapsedAggregate[] = [];
  userDuidToTimeTrackingMap.forEach((value, userDuid) => {
    const user = dataStore.getUserByDuid(userDuid);
    if (!user) {
      return;
    }

    const userTimeElapsed = getTimeTrackingDurationText(value);
    result.push({ user, userTimeElapsed });
  });

  return result;
});

let interval: ReturnType<typeof setInterval> | undefined;

const startTimer = () => {
  if (!currentUserEntry.value) {
    return;
  }

  if (interval) {
    clearInterval(interval);
  }

  now.value = new Date();
  const elapsedMs = moment().diff(currentUserEntry.value.startedAt);
  const timeoutMs = Math.ceil(elapsedMs / 1000) * 1000 - elapsedMs;
  setTimeout(() => {
    now.value = new Date();
    interval = setInterval(() => {
      now.value = new Date();
    }, 1000);
  }, timeoutMs);
};

watch(
  () => currentUserEntry.value,
  (newValue) => {
    if (!newValue) {
      return;
    }
    startTimer();
  }
);

onMounted(() => {
  if (!currentInstance) {
    return;
  }
  const instanceElem = currentInstance.subTree.el;
  if (!instanceElem?.children) {
    return;
  }

  assignButtonRef(instanceElem.children[0]);
});

onMounted(() => {
  if (!currentUserEntry.value) {
    return;
  }
  startTimer();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>
  <Dropdown
    v-if="!isBoardMode || hasTimeTracking"
    ref="dropdownRef"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :disabled="isBoardMode"
    :triggers="isContextMenuMode ? ['hover', 'focus'] : undefined"
    :popper-triggers="isContextMenuMode ? ['hover', 'focus'] : undefined"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :distance="calcDistance"
    :skidding="isFormMode ? -1 : undefined"
    no-auto-focus
    :theme="`dropdown-${pageStore.theme}`"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    class="flex cursor-pointer"
    :class="{ 'w-full max-w-full': isListMode || isFormMode }"
    @apply-show="onShow">
    <template #default>
      <DropdownMenuItemContent
        v-if="isContextMenuMode"
        :icon="TimeTrackingFieldIcon"
        :title="`${currentUserEntry ? 'Stop' : 'Start'} timer`"
        is-submenu />

      <Tooltip
        v-else
        :disabled="isFormMode"
        :command-id="!isBoardMode ? CommandId.START_TIMER : undefined"
        :text="isBoardMode ? property.title : undefined"
        :block="isListMode || isFormMode"
        :height-block="isListMode || isFormMode"
        :class="isTaskDetailMode && 'w-full'">
        <div
          ref="feedbackTooltipNode"
          class="group/time-tracking flex size-full max-w-full cursor-pointer items-center text-sm"
          :class="{
            'px-1.5': isListMode,
            'justify-start rounded hover:bg-lt': isTaskDetailMode,
          }">
          <div
            class="flex select-none items-center justify-center rounded text-md"
            :class="{
              'border border-oncolor': isChipMode || isBoardMode,
              'h-[26px] gap-1 py-0.5 text-sm hover:bg-opposite/10': isChipMode,
              'h-4': isBoardMode,
              'gap-2': !isChipMode,
              'h-5 text-xs': !isChipMode && !isTaskDetailMode && !isFormMode,
              'px-2 text-sm hover:bg-lt': isFormMode || isTaskDetailMode,
              'px-1': !isFormMode && !isTaskDetailMode,
            }">
            <TimeTrackingFieldIcon v-if="isChipMode && !hasTimeTracking" class="icon-sm" />
            <Button
              v-if="isTaskDetailMode"
              :btn-style="ButtonStyle.SECONDARY"
              is-contrast
              :icon="currentUserEntry ? StopIcon : PlayIcon"
              :icon-args="{ class: 'text-primary-base' }"
              borderless
              :a11y-label="`${currentUserEntry ? 'Stop' : 'Start'} timer`"
              class="-mx-1 cursor-pointer"
              @click.stop="startOrStopTimeTracking" />
            <span
              :class="[
                ((isTaskDetailMode && !hasTimeTracking) || (isFormMode && !hasTimeTracking)) && 'text-vlt',
                hasTimeTracking && 'tabular-nums',
              ]">
              {{
                hasTimeTracking
                  ? timeElapsedText
                  : isChipMode
                    ? "Timer"
                    : isFormMode
                      ? `${currentUserEntry ? "Stop" : "Start"} timer`
                      : isListMode
                        ? ""
                        : "None"
              }}
            </span>
          </div>
          <div
            v-if="isListMode && !hasTimeTracking"
            class="flex size-full items-center justify-center text-transparent group-hover/time-tracking:text-vlt">
            <TimeTrackingFieldIcon class="icon-xs" aria-hidden="true" />
          </div>
        </div>
      </Tooltip>
    </template>

    <template #popper>
      <Menu no-auto-focus>
        <div
          focus
          class="w-56 max-w-56 divide-y divide-gray-200 overflow-hidden rounded border app-drag-none bg-lt border-md focus-ring-none dark:divide-zinc-700"
          @click.stop
          @keydown.stop>
          <div ref="focusDiv" tabindex="0" class="focus:outline-none" />
          <div class="flex items-center gap-2 px-2 py-1">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :a11y-label="`${currentUserEntry ? 'Stop' : 'Start'} timer`"
              is-contrast
              :icon="currentUserEntry ? StopIcon : PlayIcon"
              :icon-args="{ class: 'text-primary-base' }"
              borderless
              @click="startOrStopTimeTracking" />

            <div class="tabular-nums text-lt">{{ currentUserEntry ? timeElapsedText : "00:00:00" }}</div>
          </div>
          <div v-if="userTimeElapsedAggregate.length" class="flex w-full flex-col gap-2 px-3 pb-1.5 pt-2">
            <div v-for="{ user, userTimeElapsed } in userTimeElapsedAggregate" :key="user.duid" class="flex gap-2">
              <Avatar
                :abrev="user.abrev"
                :image-url="user.imageUrl"
                circle
                :color-hex="user.colorHex"
                class="mr-0.5 size-6"
                borderless />

              <span class="tabular-nums text-lt">{{ userTimeElapsed }}</span>
            </div>
          </div>
        </div>
      </Menu>
    </template>
  </Dropdown>
</template>

<style>
.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-dropdown .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-dropdown .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
