<script setup lang="ts">
import { GridItem, GridLayout, type LayoutItem } from "grid-layout-plus";
import { capitalize, computed, ref, watch } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { colorsByTheme } from "~/constants/style";
import { PlusIcon } from "~/icons";
import {
  DropdownMenuItemKind,
  NumberChartAggregation,
  PieChartDisplayMetric,
  Placement,
  StatisticType,
} from "~/shared/enums";
import type { StatisticConfig, Task } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";
import { makeDuid } from "~/utils/common";

import { getStatisticDefinition, STATISTIC_DEFINITIONS } from "./constants";
import TaskStatisticItem from "./TaskStatisticItem.vue";

type StatisticItem = LayoutItem & { duid: string; config: StatisticConfig };

const COL_NUM = 12;
const ADD_CHART_WIDTH = 2;
const ADD_CHART_HEIGHT = 1;

const statisticsToLayout = (statistics: StatisticConfig[]): StatisticItem[] =>
  statistics.map((e) => {
    const definition = getStatisticDefinition(e.type);
    return {
      duid: e.duid,
      i: e.duid,
      x: e.x,
      y: e.y,
      w: e.width,
      h: e.height,
      minW: definition?.sizes?.minW,
      minH: definition?.sizes?.minH,
      maxW: definition?.sizes?.maxW,
      maxH: definition?.sizes?.maxH,
      config: e,
    };
  });

const props = defineProps<{
  tasks: Task[];
  statistics: StatisticConfig[];
}>();

const emit = defineEmits<{
  update: [configs: StatisticConfig[]];
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const grid = ref<InstanceType<typeof GridLayout> | null>(null);
const editing = computed(() => grid.value?.state.isDragging);
const layout = ref<StatisticItem[]>(statisticsToLayout(props.statistics));
watch(
  () => props.statistics,
  (newValue) => {
    layout.value = statisticsToLayout(newValue);
  }
);

const addChartPosition = computed(() => {
  const furthestY = layout.value.reduce((acc, item) => Math.max(acc, item.y + item.h), 0);
  return { x: 0, y: furthestY };
});

const onAddConfig = (type: StatisticType) => {
  const definition = getStatisticDefinition(type);
  if (!definition) {
    return;
  }

  const newConfig = {
    duid: makeDuid(),
    type,
    title: "Chart",
    x: addChartPosition.value.x,
    y: addChartPosition.value.y,
    width: definition.sizes?.defaultW ?? 6,
    height: definition.sizes?.defaultH ?? 4,
    adtl:
      type === StatisticType.BAR
        ? {
            xPropertyDuid: dataStore.defaultAssigneesProperty.duid,
            stackPropertyDuid: null,
          }
        : type === StatisticType.BURN_UP
          ? {
              startDate: null,
              endDate: null,
            }
          : type === StatisticType.LINE
            ? {
                xPropertyDuid: dataStore.defaultAssigneesProperty.duid,
                groupByPropertyDuid: dataStore.defaultStatusProperty.duid,
              }
            : type === StatisticType.NUMBER
              ? {
                  propertyDuid: null,
                  aggregation: NumberChartAggregation.COUNT,
                  filterByValueId: null,
                }
              : type === StatisticType.PIE
                ? {
                    propertyDuid: dataStore.defaultAssigneesProperty.duid,
                    displayMetric: PieChartDisplayMetric.COUNT,
                  }
                : {
                    columnPropertyDuid: dataStore.defaultAssigneesProperty.duid,
                    rowPropertyDuid: dataStore.defaultStatusProperty.duid,
                  },
  } as StatisticConfig;
  newConfig.title = definition.getDefaultTitle(newConfig);

  emit("update", props.statistics.concat([newConfig]));
};

const onUpdateConfig = (config: StatisticConfig) => {
  emit(
    "update",
    props.statistics.map((e) => (e.duid === config.duid ? config : e))
  );
};

const onDeleteConfig = (config: StatisticConfig) => {
  emit(
    "update",
    props.statistics.filter((e) => e.duid !== config.duid)
  );
};

const onLayoutChanged = () => {
  emit(
    "update",
    props.statistics.map((e) => {
      const item = layout.value.find((i) => i.duid === e.duid);
      return item ? { ...e, x: item.x, y: item.y, width: item.w, height: item.h } : e;
    })
  );
};

const dropdownSections = computed(() => [
  {
    title: "Add chart",
    items: Object.values(STATISTIC_DEFINITIONS).map(({ type, icon }) => ({
      title: capitalize(type),
      kind: DropdownMenuItemKind.BUTTON,
      icon,
      onClick: () => onAddConfig(type),
    })),
  },
]);
</script>

<template>
  <div class="w-full">
    <GridLayout
      ref="grid"
      :layout="layout"
      :col-num="COL_NUM"
      :cols="{ lg: COL_NUM, md: COL_NUM, sm: COL_NUM, xs: COL_NUM / 2, xxs: COL_NUM / 2 }"
      :row-height="40"
      :margin="[16, 16]"
      class="dart-task-statistic"
      is-draggable
      is-resizable
      is-bounded
      responsive
      vertical-compact>
      <div v-if="editing" class="dart-layout-grid absolute inset-4 flex gap-4">
        <div v-for="i in COL_NUM" :key="i" class="box-content size-full border-x border-lt" />
        <div class="absolute inset-0 flex flex-col gap-4 overflow-hidden text-clip">
          <div v-for="i in addChartPosition.y + 100" :key="i" class="h-[40px] w-full shrink-0 border-y border-lt" />
        </div>
      </div>
      <GridItem
        v-for="item in layout"
        :key="item.duid"
        v-bind="item"
        class="[&>span]:hover:!block"
        drag-allow-from=".dart-drag-handle"
        @moved="() => onLayoutChanged()"
        @resized="() => onLayoutChanged()">
        <TaskStatisticItem :tasks="tasks" :config="item.config" @update="onUpdateConfig" @delete="onDeleteConfig" />
      </GridItem>
      <GridItem
        v-if="!editing"
        i="add"
        :x="addChartPosition.x"
        :y="addChartPosition.y"
        :w="ADD_CHART_WIDTH"
        :h="ADD_CHART_HEIGHT"
        static>
        <DropdownMenu
          class="size-full select-none rounded bg-std border-md hover:bg-lt"
          :sections="dropdownSections"
          :distance="2"
          :placement="Placement.RIGHT_TOP">
          <button type="button" class="flex size-full items-center justify-center gap-2">
            <PlusIcon class="text-md icon-lg" />
            <span class="font-medium text-md">Add chart</span>
          </button>
        </DropdownMenu>
      </GridItem>
    </GridLayout>
  </div>
</template>

<style scoped>
.vgl-layout {
  @apply rounded-lg bg-std;
  --vgl-resizer-size: 12px;
  --vgl-resizer-border-color: v-bind("colors.borderMd");
  --vgl-resizer-border-width: 2px;
}

:deep(.vgl-item) {
  @apply !transition-none;
}

:deep(.vgl-item--placeholder) {
  @apply rounded-lg bg-primary-base opacity-60;
}

:deep(.vgl-item--resizing) {
  @apply opacity-80;
}

:deep(.vgl-item__resizer) {
  @apply m-2 hidden;
}

.dart-task-statistic {
  :deep(.apexcharts-gridline) {
    box-shadow: 0 0 1px transparent !important;
  }
  :deep(.apexcharts-tooltip) {
    @apply min-w-52 !rounded-xl border !bg-white p-3 !shadow border-md dark:!bg-zinc-850;
  }
  :deep(.apexcharts-tooltip-title) {
    @apply !mb-2 !border-none !bg-transparent !p-0 text-sm font-semibold text-hvy;
  }
  :deep(.apexcharts-tooltip-y-group) {
    @apply flex w-full items-center gap-1 !py-1;
  }
  :deep(.apexcharts-tooltip-text-y-label) {
    @apply w-full flex-1 text-xs font-medium leading-tight text-md;
  }
  :deep(.apexcharts-tooltip-text-y-value) {
    @apply !m-0 font-semibold leading-tight text-lt;
  }
}
</style>
