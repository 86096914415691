import { type Component, computed, nextTick } from "vue";

import { getPropertyConfig } from "~/common/properties";
import ColorPicker from "~/components/dumb/ColorPicker.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownDeleteMenuItem from "~/components/dumb/DropdownDeleteMenuItem.vue";
import ExportDropdown from "~/components/dumb/ExportDropdown.vue";
import TaskUpdateDropdown from "~/components/dumb/TaskUpdateDropdown.vue";
import { copyAndNotify } from "~/components/notifications";
import OptionTitleEditor from "~/components/options/OptionTitleEditor.vue";
import PagePropertiesEditorDartboardInfoSummary from "~/components/PagePropertiesEditorDartboardInfoSummary.vue";
import {
  AttachmentFieldIcon,
  ChevronLeftDoubleIcon,
  ChildRelationshipIcon,
  DashboardIcon,
  DescriptionFieldIcon,
  DuidFieldIcon,
  EditIcon,
  FavoriteIcon,
  GitBranchIcon,
  LinkIcon,
  MailIcon,
  NotificationIcon,
  OpenExternalLinkIcon,
  OutdentIcon,
  PublicIcon,
  ReplicateIcon,
  ReportsIcon,
  RestoreIcon,
  SprintRolloverIcon,
  TitleFieldIcon,
  TrashIcon,
  UnfavoriteIcon,
} from "~/icons";
import {
  CommandId,
  DartboardKind,
  DropdownMenuItemKind,
  EditorMode,
  FolderKind,
  PageKind,
  SpaceKind,
  SprintMode,
  UserRole,
  ViewKind,
} from "~/shared/enums";
import type {
  Comment,
  Doc,
  DropdownMenuSection,
  Form,
  Option,
  Page,
  PageWithPermissions,
  Property,
  Task,
  TaskKind,
} from "~/shared/types";
import { useAppStore, useDataStore, useEnvironmentStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getDocLink, getPageDisplayName, getReportsLink, getText, makeDartboardEmail } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

import type { Actions } from ".";

const PAGE_KIND_TO_DELETE_DIALOG_DESCRIPTION = new Map([
  [
    PageKind.DARTBOARD,
    "Permanently deleting a dartboard will remove it for everyone in the workspace and move all its tasks to the trash. This can't be undone. Are you sure you want to proceed?",
  ],
  [
    PageKind.DASHBOARD,
    "Permanently deleting a dashboard will remove it for everyone in the workspace. This can't be undone. Are you sure you want to proceed?",
  ],
  [
    PageKind.DOC,
    "Permanently deleting a doc will remove it for everyone in the workspace. This can't be undone. Are you sure you want to proceed?",
  ],
  [
    PageKind.FOLDER,
    "Permanently deleting a folder will remove it and all of its docs for everyone in the workspace. This can't be undone. Are you sure you want to proceed?",
  ],
  [
    PageKind.SPACE,
    "Permanently deleting a space will remove it and everything within it for everyone in the workspace. This can't be undone. Are you sure you want to proceed?",
  ],
  [
    PageKind.VIEW,
    "Permanently deleting a view will remove it for everyone in the workspace. This can't be undone. Are you sure you want to proceed?",
  ],
]);
const PAGE_KINDS_WITHOUT_CONTEXT_MENUS = new Set([
  PageKind.DASHBOARDS_ROOT,
  PageKind.HOME,
  PageKind.INBOX,
  PageKind.VIEWS_ROOT,
]);
const OPTION_DELETE_DIALOG_DESCRIPTION =
  "Permanently deleting an option will remove it from any tasks it is on. This can't be undone. Are you sure you want to proceed?";

let LinkToRelationshipsDropdown: Component;

/** Context menu actions */
export default (actions: Actions) => ({
  init(linkToRelationshipsDropdown: Component) {
    LinkToRelationshipsDropdown = linkToRelationshipsDropdown;
  },
  comment(comment: Comment, emit: (e: string) => void) {
    const environmentStore = useEnvironmentStore();
    const userStore = useUserStore();

    const items = [
      {
        title: "Copy Link",
        kind: DropdownMenuItemKind.BUTTON,
        icon: LinkIcon,
        onClick: () => {
          const text = getText(comment.text);
          const title = text.length > 50 ? `${text.slice(0, 50)}…` : text;
          const url = environmentStore.getCommentUrl(comment);
          copyAndNotify("Link to comment", url, `<a href=${url}>${title}</a>`);
        },
      },
      {
        title: "Copy ID",
        kind: DropdownMenuItemKind.BUTTON,
        icon: DuidFieldIcon,
        onClick: () => copyAndNotify("ID of comment", comment.duid),
      },
    ];

    if (comment.authorDuid === userStore.duid && !comment.authoredByAi) {
      items.push({
        title: "Edit",
        kind: DropdownMenuItemKind.BUTTON,
        icon: EditIcon,
        onClick: () => emit("startEdit"),
      });
      items.push({
        title: "Delete",
        kind: DropdownMenuItemKind.BUTTON,
        icon: TrashIcon,
        onClick: () => emit("delete"),
      });
    }

    return [
      {
        title: "Options",
        items,
      },
    ];
  },
  doc(doc: Doc) {
    const dataStore = useDataStore();
    const router = dataStore.$router;

    return [
      {
        title: "Copy",
        items: [
          {
            title: "Copy link",
            kind: DropdownMenuItemKind.BUTTON,
            icon: LinkIcon,
            onClick: () => actions.doc.copyLinks([doc]),
          },
          {
            title: "Copy ID",
            kind: DropdownMenuItemKind.BUTTON,
            icon: DuidFieldIcon,
            onClick: () => copyAndNotify("Doc ID", doc.duid),
          },
        ],
      },
      {
        title: "Meta",
        items: [
          {
            title: "Export as",
            kind: DropdownMenuItemKind.COMPONENT,
            component: ExportDropdown,
            componentArgs: {
              doc,
            },
          },
          {
            title: "Replicate doc",
            kind: DropdownMenuItemKind.BUTTON,
            icon: ReplicateIcon,
            onClick: async () => {
              const siblings = dataStore.getDocsByFolderDuidOrdered(doc.folderDuid);
              const prevSibling = siblings[siblings.findIndex((e) => e.duid === doc.duid) - 1];
              const order = getOrdersBetween(prevSibling?.order, doc.order)[0];

              const replicate = await dataStore.replicateDoc(doc, order);
              if (!replicate) {
                return;
              }
              router.push(getDocLink(replicate));
            },
          },
          {
            title: "Delete",
            kind: DropdownMenuItemKind.COMPONENT,
            component: DropdownDeleteMenuItem,
            componentArgs: {
              title: `Delete ${getPageDisplayName(doc, dataStore.getSpaceByDuid)}`,
              description:
                "Permanently deleting a doc will remove it for everyone in the workspace. This can't be undone. Are you sure you want to proceed?",
              onDelete: () => dataStore.trashDoc(doc),
            },
          },
        ],
      },
    ];
  },
  form(form: Form, dialog: InstanceType<typeof ConfirmationDialog> | null) {
    const environmentStore = useEnvironmentStore();

    return [
      {
        title: "Options",
        items: [
          {
            title: "Open preview",
            kind: DropdownMenuItemKind.EXTERNAL_LINK,
            icon: OpenExternalLinkIcon,
            navigate: {
              to: environmentStore.getFormUrl(form),
              newTab: true,
            },
          },
          {
            title: "Copy link",
            kind: DropdownMenuItemKind.BUTTON,
            icon: LinkIcon,
            onClick: () => copyAndNotify("Link to form", environmentStore.getFormUrl(form)),
          },
          {
            title: "Copy ID",
            kind: DropdownMenuItemKind.BUTTON,
            icon: DuidFieldIcon,
            onClick: () => copyAndNotify("Form ID", form.duid),
          },
          {
            title: "Delete",
            kind: DropdownMenuItemKind.COMPONENT,
            component: DropdownDeleteMenuItem,
            componentArgs: {
              dialog: dialog ?? undefined,
            },
          },
        ],
      },
    ];
  },
  option(option: Option, options: { showTitleEditor?: boolean; addSubitem?: () => void } = {}) {
    const { showTitleEditor, addSubitem } = options;
    const dataStore = useDataStore();
    const userStore = useUserStore();

    return [
      {
        title: "Title",
        items: [
          {
            title: "Rename",
            kind: DropdownMenuItemKind.COMPONENT,
            noFocus: true,
            hidden: showTitleEditor,
            component: OptionTitleEditor,
            componentArgs: {
              option,
              onRename: (title: string) => dataStore.updateOption({ duid: option.duid, title }),
            },
          },
        ],
      },
      {
        title: "Modify",
        items: [
          {
            title: "Change color",
            kind: DropdownMenuItemKind.COMPONENT,
            noFocus: true,
            component: ColorPicker,
            componentArgs: {
              value: option.colorHex,
              onSelect: (colorHex: string) => dataStore.updateOption({ duid: option.duid, colorHex }),
            },
          },
        ],
      },
      {
        title: "Meta",
        items: [
          {
            title: "Add sub-item",
            kind: DropdownMenuItemKind.BUTTON,
            icon: ChildRelationshipIcon,
            hidden: !addSubitem,
            onClick: () => addSubitem?.(),
          },
          {
            title: "Copy ID",
            kind: DropdownMenuItemKind.BUTTON,
            icon: DuidFieldIcon,
            onClick: () => copyAndNotify("Option ID", option.duid),
          },
          {
            title: "Delete",
            kind: DropdownMenuItemKind.COMPONENT,
            hidden: !userStore.isRoleGreaterOrEqual(UserRole.ADMIN),
            component: DropdownDeleteMenuItem,
            componentArgs: {
              title: `Delete ${option.title}`,
              description: OPTION_DELETE_DIALOG_DESCRIPTION,
              onDelete: () => dataStore.deleteOption(option),
            },
          },
        ],
      },
    ];
  },
  page(page: Page, showDescriptionOption: boolean, emit: (e: string) => void) {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const environmentStore = useEnvironmentStore();
    const pageStore = usePageStore();
    const tenantStore = useTenantStore();
    const userStore = useUserStore();

    if (PAGE_KINDS_WITHOUT_CONTEXT_MENUS.has(page.pageKind)) {
      return [];
    }

    const renameItem = {
      title: "Rename",
      kind: DropdownMenuItemKind.BUTTON,
      icon: TitleFieldIcon,
      onClick: () => emit("startEditingTitle"),
    };

    const replicatePageItem = computed(() => ({
      title: `Replicate ${page.pageKind}`,
      kind: DropdownMenuItemKind.BUTTON,
      icon: ReplicateIcon,
      onClick: () => dataStore.replicatePage(page),
    }));

    const getManageSharingItem = (pageWithPermissions: PageWithPermissions) => ({
      title: "Manage sharing",
      kind: DropdownMenuItemKind.BUTTON,
      icon: PublicIcon,
      onClick: () => {
        if (!pageWithPermissions) {
          return;
        }

        appStore.setPageInPermissionsModal(pageWithPermissions);
      },
    });

    const pageName = getPageDisplayName(page, dataStore.getSpaceByDuid);
    const pageUrl = environmentStore.getPageUrl(page);
    const editDescriptionItemMaybe = showDescriptionOption
      ? [
          {
            title: "Edit description",
            kind: DropdownMenuItemKind.BUTTON,
            icon: DescriptionFieldIcon,
            onClick: () => emit("startEditingDescription"),
            hidden: pageStore.isMobile,
          },
        ]
      : [];

    const copyLinkItem = {
      title: "Copy link",
      kind: DropdownMenuItemKind.BUTTON,
      icon: LinkIcon,
      onClick: () => copyAndNotify(`Link to ${page.pageKind}`, pageUrl, `<a href=${pageUrl}>${pageName}</a>`),
    };

    const copyIdItem = {
      title: "Copy ID",
      kind: DropdownMenuItemKind.BUTTON,
      icon: DuidFieldIcon,
      onClick: () => copyAndNotify(`${page.pageKind} ID`, page.duid),
    };

    const exportItem = {
      title: "Export as",
      kind: DropdownMenuItemKind.COMPONENT,
      component: ExportDropdown,
      componentArgs: {
        page,
      },
    };

    const deleteItem = {
      title: "Delete",
      kind: DropdownMenuItemKind.COMPONENT,
      component: DropdownDeleteMenuItem,
      componentArgs: {
        title: `Delete ${pageName}`,
        description: PAGE_KIND_TO_DELETE_DIALOG_DESCRIPTION.get(page.pageKind),
        onDelete: () => dataStore.deletePage(page),
      },
    };

    switch (page.pageKind) {
      case PageKind.DARTBOARD: {
        const infoSection = {
          title: "Info",
          items: [
            {
              title: "Info",
              kind: DropdownMenuItemKind.COMPONENT,
              component: PagePropertiesEditorDartboardInfoSummary,
              componentArgs: {
                dartboard: page,
              },
              noFocus: true,
            },
          ],
        };

        const linksSection: DropdownMenuSection = {
          title: "Links",
          items: [
            {
              title: "Go to dashboards",
              kind: DropdownMenuItemKind.INTERNAL_LINK,
              icon: DashboardIcon,
              navigate: { to: { name: "dashboards" } },
            },
          ],
        };
        const space = dataStore.getSpaceByDuid(page.spaceDuid);
        if (space) {
          linksSection.items.push({
            title: "Go to reports",
            kind: DropdownMenuItemKind.INTERNAL_LINK,
            icon: ReportsIcon,
            navigate: { to: getReportsLink(space) },
          });
        }

        const copyDartboardEmailItem = tenantStore.emailIntegrationEnabled
          ? [
              {
                title: "Copy dartboard email",
                kind: DropdownMenuItemKind.BUTTON,
                icon: MailIcon,
                onClick: () =>
                  copyAndNotify(`Email for ${pageName}`, makeDartboardEmail(page, environmentStore.isProd)),
              },
            ]
          : [];

        if (page.kind === DartboardKind.FINISHED) {
          return [
            infoSection,
            { title: "Modify", items: [...editDescriptionItemMaybe] },
            linksSection,
            { title: "Copy", items: [copyLinkItem, copyIdItem] },
            { title: "Meta", items: [exportItem, deleteItem] },
          ];
        }

        if (page.kind === DartboardKind.BACKLOG) {
          return [
            { title: "Modify", items: [...editDescriptionItemMaybe] },
            linksSection,
            { title: "Copy", items: [copyLinkItem, ...copyDartboardEmailItem, copyIdItem] },
            { title: "Meta", items: [exportItem] },
          ];
        }

        if (page.kind !== DartboardKind.CUSTOM) {
          return [
            infoSection,
            { title: "Modify", items: [...editDescriptionItemMaybe] },
            linksSection,
            { title: "Copy", items: [copyLinkItem, ...copyDartboardEmailItem, copyIdItem] },
            { title: "Meta", items: [exportItem] },
          ];
        }

        return [
          { title: "Modify", items: [renameItem, ...editDescriptionItemMaybe] },
          linksSection,
          { title: "Copy", items: [copyLinkItem, ...copyDartboardEmailItem, copyIdItem] },
          { title: "Meta", items: [replicatePageItem.value, exportItem, deleteItem] },
        ];
      }
      case PageKind.DASHBOARD: {
        const isFavorited = page.favoritedByUserDuids.includes(userStore.duid);
        const toggleFavoriteItem = {
          title: isFavorited ? "Unfavorite" : "Favorite",
          kind: DropdownMenuItemKind.BUTTON,
          icon: isFavorited ? FavoriteIcon : UnfavoriteIcon,
          onClick: () => {
            if (!page) {
              return;
            }
            dataStore.updateDashboardFavorite(page.duid, !isFavorited);
          },
        };

        return [
          { title: "Favorite", items: [toggleFavoriteItem] },
          { title: "Modify", items: [renameItem, ...editDescriptionItemMaybe, getManageSharingItem(page)] },
          { title: "Copy", items: [copyLinkItem, copyIdItem] },
          { title: "Meta", items: [replicatePageItem.value, deleteItem] },
        ];
      }
      case PageKind.DOC: {
        return [
          { title: "Modify", items: [renameItem] },
          { title: "Copy", items: [copyLinkItem, copyIdItem] },
          { title: "Meta", items: [replicatePageItem.value, deleteItem] },
        ];
      }
      case PageKind.FOLDER: {
        if (page.kind === FolderKind.DEFAULT) {
          return [
            { title: "Modify", items: [renameItem, ...editDescriptionItemMaybe] },
            { title: "Copy", items: [copyLinkItem, copyIdItem] },
            { title: "Meta", items: [replicatePageItem.value] },
          ];
        }

        return [
          { title: "Modify", items: [renameItem, ...editDescriptionItemMaybe] },
          { title: "Copy", items: [copyLinkItem, copyIdItem] },
          { title: "Meta", items: [replicatePageItem.value, deleteItem] },
        ];
      }
      case PageKind.SPACE: {
        return [
          {
            title: "Manage",
            items: [
              ...(page.sprintMode === SprintMode.NONE
                ? []
                : [
                    {
                      title: "Start next sprint",
                      kind: DropdownMenuItemKind.BUTTON,
                      icon: SprintRolloverIcon,
                      onClick: () => emit("startNextSprint"),
                    },
                  ]),
              {
                title: "Configure space",
                kind: DropdownMenuItemKind.BUTTON,
                icon: EditIcon,
                onClick: () => emit("startEditingSpace"),
              },
            ],
          },
          {
            title: "Links",
            items: [
              {
                title: "Go to reports",
                kind: DropdownMenuItemKind.INTERNAL_LINK,
                icon: ReportsIcon,
                navigate: { to: getReportsLink(page) },
              },
            ],
          },
          { title: "Copy", items: [copyLinkItem, copyIdItem] },
          {
            title: "Meta",
            items: [
              replicatePageItem.value,
              ...(!(page.kind === SpaceKind.WORKSPACE || page.kind === SpaceKind.PERSONAL) ? [deleteItem] : []),
            ],
          },
        ];
      }
      case PageKind.VIEW: {
        const isFavorited = page.favoritedByUserDuids.includes(userStore.duid);
        const toggleFavoriteItem = {
          title: isFavorited ? "Unfavorite" : "Favorite",
          kind: DropdownMenuItemKind.BUTTON,
          icon: isFavorited ? FavoriteIcon : UnfavoriteIcon,
          onClick: () => {
            if (!page) {
              return;
            }
            dataStore.updateViewFavorite(page.duid, !isFavorited);
          },
        };

        if (page.kind === ViewKind.MY_TASKS) {
          return [
            { title: "Favorite", items: [toggleFavoriteItem] },
            { title: "Copy", items: [copyLinkItem, copyIdItem] },
            { title: "Meta", items: [replicatePageItem.value, exportItem] },
          ];
        }

        return [
          { title: "Favorite", items: [toggleFavoriteItem] },
          { title: "Modify", items: [renameItem, ...editDescriptionItemMaybe, getManageSharingItem(page)] },
          { title: "Copy", items: [copyLinkItem, copyIdItem] },
          { title: "Meta", items: [replicatePageItem.value, exportItem, deleteItem] },
        ];
      }
      default: {
        throw new Error(`Unknown page kind: ${page.pageKind}`);
      }
    }
  },
  property(property: Property, dialog: InstanceType<typeof ConfirmationDialog> | null) {
    const propertyConfig = getPropertyConfig(property.kind);

    return [
      {
        title: "Options",
        items: [
          {
            title: "Copy ID",
            kind: DropdownMenuItemKind.BUTTON,
            icon: DuidFieldIcon,
            onClick: () => copyAndNotify("Property ID", property.duid),
          },
          {
            title: "Delete",
            kind: DropdownMenuItemKind.COMPONENT,
            component: DropdownDeleteMenuItem,
            disabled: propertyConfig.isDefault,
            componentArgs: {
              dialog: dialog ?? undefined,
            },
          },
        ],
      },
    ];
  },
  task(
    targetTask: Task,
    editorMode: EditorMode,
    shouldSelectTask = true,
    close: (() => void) | undefined = undefined
  ): DropdownMenuSection[] {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const tenantStore = useTenantStore();
    const userStore = useUserStore();

    // Setup to get selected tasks
    const visualization = appStore.getActiveVisualization();
    const previouslySelectedRows = visualization.getSelectedRows();

    const rowIsSelected = !!previouslySelectedRows.find((r) => r.id === targetTask.duid);
    const isListMiniTcmMode = editorMode === EditorMode.LIST_MINI_TCM;
    const isListMiniNonRoadmapMode = editorMode === EditorMode.LIST_MINI || isListMiniTcmMode;

    if (shouldSelectTask) {
      if (isListMiniNonRoadmapMode) {
        appStore.getSubtasksListVisualization()?.selectAndScrollTo(targetTask.duid, { deselectOthers: !rowIsSelected });
      } else {
        actions.visualization.selectRowByIdAndScroll(targetTask.duid, !rowIsSelected);
      }
    }

    // Task variables
    const selectedTasks = visualization
      .getSelectedRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
    const selectedTaskDuids = selectedTasks.map((e) => e.duid);

    const isMultipleTasks = selectedTasks.length > 1;
    const plural = isMultipleTasks ? "s" : "";

    const userIsSubscriber = selectedTasks.every((e) => e.subscriberDuids.includes(userStore.duid));
    const isTrash = selectedTasks.every((e) => e.inTrash);

    // Dropdown menu sections
    const openTaskDetailSection: DropdownMenuSection = {
      title: "Actions",
      items: [
        {
          title: `Open task${plural}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: ChevronLeftDoubleIcon,
          commandId: CommandId.OPEN_TASK_IN_RIGHTBAR,
          hidden: editorMode === EditorMode.DETAIL,
          disabled: isMultipleTasks || appStore.taskOpenInDetail?.duid === targetTask.duid,
          onClick: () => {
            if (isListMiniNonRoadmapMode) {
              actions.visualization.navigateToTask(targetTask.duid);
              return;
            }
            appStore.setTaskDetailOpen(true);
          },
        },
        {
          title: `Convert to normal task${plural}`,
          kind: DropdownMenuItemKind.BUTTON,
          disabled: isMultipleTasks,
          icon: OutdentIcon,
          hidden: !isListMiniNonRoadmapMode,
          onClick: () => actions.visualization.convertToNormalTask(targetTask),
        },
      ],
    };

    const copySection: DropdownMenuSection = {
      title: "Copy",
      items: [
        {
          title: `Copy link${plural}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: LinkIcon,
          commandId: CommandId.COPY_LINK,
          onClick: actions.visualization.copyTaskLinks,
        },
        {
          title: `Copy branch name${plural}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: GitBranchIcon,
          hidden: !tenantStore.githubIntegrationEnabled,
          commandId: CommandId.COPY_BRANCH,
          onClick: actions.visualization.copyTaskBranchNames,
        },
        {
          title: `Copy ID${plural}`,
          kind: DropdownMenuItemKind.BUTTON,
          icon: DuidFieldIcon,
          onClick: () =>
            copyAndNotify(`Task ID${selectedTaskDuids.length > 1 ? "s" : ""}`, selectedTaskDuids.join("\n")),
        },
      ],
    };

    const deleteItem = {
      title: "Delete",
      kind: DropdownMenuItemKind.BUTTON,
      icon: TrashIcon,
      commandId: CommandId.DELETE_TASK,
      onClick: () => actions.visualization.trashTasks(),
    };

    if (isListMiniTcmMode) {
      return [
        {
          title: "Meta",
          items: [deleteItem],
        },
      ];
    }

    if (isTrash) {
      return [
        openTaskDetailSection,
        copySection,
        {
          title: "Actions",
          items: [
            {
              title: "Restore",
              kind: DropdownMenuItemKind.BUTTON,
              icon: RestoreIcon,
              onClick: () => actions.visualization.restoreTasks(),
            },
            {
              title: "Delete permanently",
              kind: DropdownMenuItemKind.BUTTON,
              commandId: CommandId.DELETE_TASK,
              icon: TrashIcon,
              onClick: () => actions.visualization.deleteTasks(),
            },
          ],
        },
      ];
    }

    return [
      openTaskDetailSection,
      {
        title: "Modify",
        items: [
          {
            title: "Change task",
            kind: DropdownMenuItemKind.COMPONENT,
            component: TaskUpdateDropdown,
            componentArgs: {
              targetTask,
              selectedTasks,
            },
          },
          {
            title: "Connect a task, doc, and more",
            kind: DropdownMenuItemKind.COMPONENT,
            disabled: isMultipleTasks,
            component: LinkToRelationshipsDropdown,
            componentArgs: {
              targetTask,
              selectedTasks,
            },
          },
          {
            title: "Add subtask",
            kind: DropdownMenuItemKind.BUTTON,
            icon: ChildRelationshipIcon,
            commandId: CommandId.CREATE_SUBTASKS,
            onClick: () => actions.visualization.createSubtask(),
          },
          {
            title: "Add attachment",
            disabled: isMultipleTasks,
            kind: DropdownMenuItemKind.BUTTON,
            icon: AttachmentFieldIcon,
            commandId: CommandId.ADD_ATTACHMENT,
            onClick: async () => {
              appStore.setTaskDetailOpen(true);
              await nextTick();
              appStore.taskDetail?.openFilePicker();
              close?.();
            },
          },
          {
            title: userIsSubscriber ? "Unsubscribe" : "Subscribe",
            kind: DropdownMenuItemKind.BUTTON,
            icon: NotificationIcon,
            onClick: () => {
              if (userIsSubscriber) {
                dataStore.removeSubscribers(selectedTaskDuids, [userStore.duid]);
              } else {
                dataStore.addSubscribers(selectedTaskDuids, [userStore.duid]);
              }
            },
          },
        ],
      },
      copySection,
      {
        title: "Meta",
        items: [
          {
            title: "Export as",
            kind: DropdownMenuItemKind.COMPONENT,
            disabled: isMultipleTasks,
            component: ExportDropdown,
            componentArgs: {
              task: targetTask,
            },
          },
          {
            title: `Replicate task${plural}`,
            kind: DropdownMenuItemKind.BUTTON,
            icon: ReplicateIcon,
            commandId: CommandId.REPLICATE_TASK,
            onClick: () => actions.visualization.replicateTasks(),
          },
          deleteItem,
        ],
      },
    ];
  },
  taskKind(taskKind: TaskKind, dialog: InstanceType<typeof ConfirmationDialog> | null) {
    return [
      {
        title: "Options",
        items: [
          {
            title: "Copy ID",
            kind: DropdownMenuItemKind.BUTTON,
            icon: DuidFieldIcon,
            onClick: () => copyAndNotify("Type ID", taskKind.duid),
          },
          {
            title: "Delete",
            kind: DropdownMenuItemKind.COMPONENT,
            component: DropdownDeleteMenuItem,
            disabled: taskKind.locked,
            componentArgs: {
              dialog: dialog ?? undefined,
            },
          },
        ],
      },
    ];
  },
});
