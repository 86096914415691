import type { Notification, NotificationUpdate } from "~/shared/types";
import { notificationComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  notificationList: Notification[];
  unreadNotificationList: Notification[];
  unreadNotificationCount: number;
  getNotificationByDuid: (duid: string) => Notification | undefined;
  getNotificationsByDuidsOrdered: (duids: string[]) => Notification[];
};

export type Actions = {
  /* Update an existing notification. */
  updateNotification: (update: NotificationUpdate, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Update existing notifications. */
  updateNotifications: (updates: NotificationUpdate[], options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort notifications.
   * @PRIVATE */
  $sortNotifications: () => void;
  /** Set notifications internally.
   * @PRIVATE */
  $setNotifications: (notifications: Notification[]) => void;
  /** Create or update notification from WS.
   * @PRIVATE */
  $createOrUpdateNotification: (notification: Notification) => void;
  /** Delete notification from WS.
   * @PRIVATE */
  $deleteNotification: (notification: Notification) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  notificationList() {
    return Array.from(this._duidsToNotifications.values());
  },
  unreadNotificationList() {
    return this.notificationList.filter((e) => !e.read);
  },
  unreadNotificationCount() {
    return this.unreadNotificationList.length;
  },
  getNotificationByDuid() {
    return (duid) => this._duidsToNotifications.get(duid);
  },
  getNotificationsByDuidsOrdered() {
    return (duids) => this.notificationList.filter((e) => duids.includes(e.duid));
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async updateNotification(update, options) {
    this.updateNotifications([update], options);
  },
  async updateNotifications(updates, options = {}) {
    const { awaitBackend = false } = options;
    updates.forEach((update) => {
      const notification = this.getNotificationByDuid(update.duid);
      if (!notification) {
        return;
      }

      Object.assign(notification, update);
    });

    this.$sortNotifications();

    const backendAction = this.$backend.notification.updateMany(updates);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortNotifications() {
    this._duidsToNotifications = new Map(
      [...this._duidsToNotifications].sort((a, b) => notificationComparator(a[1], b[1]))
    );
  },
  $setNotifications(notifications) {
    this._duidsToNotifications = new Map(notifications.map((e) => [e.duid, e]));
    this.$sortNotifications();
  },
  $createOrUpdateNotification(notification) {
    // TODO remove this check when we are filtering out spurious updates on BE of WS
    if (notification.userDuid !== this.$useUserStore().duid) {
      return;
    }

    const currentNotification = this.getNotificationByDuid(notification.duid);

    if (!currentNotification) {
      this._duidsToNotifications.set(notification.duid, notification);
    } else {
      Object.assign(currentNotification, notification);
    }

    this.$sortNotifications();
  },
  $deleteNotification(notification) {
    this._duidsToNotifications.delete(notification.duid);
  },
};

export { actions, getters };
