<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { computed, nextTick, onUnmounted, ref, watch } from "vue";

import actions from "~/actions";
import { getPropertyConfig, getPropertyValueFromTask } from "~/common/properties";
import AiSpinner from "~/components/dumb/AiSpinner.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import Chip from "~/components/filters/ValueFilter/Chip.vue";
import { notify } from "~/components/notifications";
import OptionComponent from "~/components/options/Option.vue";
import OptionDropdownItem from "~/components/options/OptionDropdownItem.vue";
import { colorsByTheme } from "~/constants/style";
import { PlusIcon, TagsFieldIcon, XIcon } from "~/icons";
import { CommandId, EditorMode, NotificationType, Placement } from "~/shared/enums";
import type { Option, PropertyAnyMultiselect, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { intersectionOfAllSets, makeValidateOptionTitle, unionOfAllSets } from "~/utils/common";
import { generateFieldRecommendation } from "~/utils/recommendation";

const props = defineProps<{
  property: PropertyAnyMultiselect;
  tasks: Task[];
  defaultValue?: PropertyValueForKind<PropertyAnyMultiselect>;
  editorMode: EditorMode;
  values?: string[];
}>();

const emit = defineEmits<{
  update: [values?: string[]];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const feedbackTooltipNode = ref<HTMLDivElement | null>(null);

const propertyConfig = computed(() => getPropertyConfig(props.property.kind));
const isDefault = computed(() => propertyConfig.value.isDefault);

const loading = computed(() => appStore.isLoadingTasksProperty(props.tasks, props.property.duid));

const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isBaseChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isChipMode = computed(() => isBaseChipMode.value || isChipRecommendationMode.value);
const isDetailBaseMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => isDetailBaseMode.value || isDetailRecommendationMode.value);
const isRecommendationMode = computed(() => isChipRecommendationMode.value || isDetailRecommendationMode.value);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);
const selectedTaskDuids = computed(() => selectedTasks.value.map((e) => e.duid));

const optionDuids = computed(
  () =>
    props.values ?? [
      ...unionOfAllSets(props.tasks.map((e) => getPropertyValueFromTask(props.property, e)).map((e) => new Set(e))),
    ]
);

const optionItems = computed(() => {
  const selectedOptionDuids = props.values ?? [
    ...intersectionOfAllSets(
      selectedTasks.value.map((e) => getPropertyValueFromTask(props.property, e)).map((e) => new Set(e))
    ),
  ];

  return dataStore
    .getOptionList(props.property)
    .filter((e) => !e.parentDuid || selectedOptionDuids.includes(e.duid))
    .map((option) => {
      const descendants = dataStore.getOptionList(props.property).filter((e) => e.parentDuid === option.duid);
      return {
        value: option.duid,
        label: option.title,
        selected: selectedOptionDuids.includes(option.duid),
        alwaysShowInList: descendants.some((descendant) => !selectedOptionDuids.includes(descendant.duid)),
        component: OptionDropdownItem,
        componentArgs: { option, selectedOptionDuids, isNested: false, showDescendants: true },
      };
    });
});

const activeOptions = computed(() =>
  optionDuids.value
    .filter((duid) => duid !== null)
    .map((duid) => dataStore.getOptionByDuid(duid))
    .filter((option): option is Option => !!option)
);

const replaceOptions = (newOptionDuids: string[]) => {
  emit("update", newOptionDuids);
  if (isFormMode.value) {
    return;
  }

  dataStore.replaceOptions(props.property, selectedTaskDuids.value, newOptionDuids);
};

const onAdd = (optionDuid: string) => {
  if (isFormMode.value) {
    emit("update", [...new Set([...optionDuids.value, optionDuid])]);
    return;
  }

  dataStore.addOptions(props.property, selectedTaskDuids.value, [optionDuid]);
  emit("update");
};

const onRemove = (optionDuid: string) => {
  if (isFormMode.value) {
    emit(
      "update",
      optionDuids.value.filter((e) => e !== optionDuid)
    );
    return;
  }

  dataStore.removeOptions(props.property, selectedTaskDuids.value, [optionDuid]);
  emit("update");
};

const onCreate = (optionDuid: string) => {
  if (isFormMode.value) {
    emit("update", [...optionDuids.value, optionDuid]);
    return;
  }
  dataStore.addOptions(props.property, selectedTaskDuids.value, [], { createTitle: optionDuid });
  emit("update");
};

const generateRecommendation = async () => {
  if (selectedTaskDuids.value.length > 100) {
    notify({ message: "Dart AI cannot fill out more than 100 tasks at once", type: NotificationType.ERROR });
    return;
  }

  appStore.startLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);
  const [taskUpdates, recDuids] = await generateFieldRecommendation(
    selectedTasks.value,
    "tagDuids",
    props.defaultValue ?? []
  );
  dataStore.updateTasks(taskUpdates);
  appStore.finishLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);

  nextTick(() => {
    actions.visualization.showFeedbackTooltipForTask(
      selectedTaskDuids.value,
      recDuids,
      props.editorMode,
      feedbackTooltipNode.value ?? null
    );
  });
};

const rejectRecommendation = () => {
  replaceOptions(props.defaultValue ?? []);
  emit("reject");
};

const validateOptionTitle = computed(() =>
  makeValidateOptionTitle((e) => dataStore.getOptionByTitle(e, props.property))
);

const colors = computed(() => colorsByTheme[pageStore.theme]);

const hiddenOptionsCount = ref(0);

const isOverflowing = () => {
  if (!feedbackTooltipNode.value) {
    return false;
  }
  return feedbackTooltipNode.value.scrollWidth > feedbackTooltipNode.value.clientWidth;
};

const displayedOptions = computed(() =>
  activeOptions.value.slice(0, activeOptions.value.length - hiddenOptionsCount.value)
);

const isAllOptionsHidden = computed(() => hiddenOptionsCount.value >= activeOptions.value.length);

const firstHiddenOption = computed(() => (isAllOptionsHidden.value ? activeOptions.value[0] : undefined));

const options = computed(() => {
  if (firstHiddenOption.value && isAllOptionsHidden.value) {
    return [firstHiddenOption.value, ...displayedOptions.value];
  }

  return displayedOptions.value;
});

const optionsCount = computed(() =>
  isAllOptionsHidden.value ? hiddenOptionsCount.value - 1 : hiddenOptionsCount.value
);

const hideOptions = async () => {
  await nextTick();

  if (!feedbackTooltipNode.value) {
    return;
  }
  // Binary search to find the number of hidden options
  let lowerBound = 0;
  let upperBound = activeOptions.value.length;
  while (lowerBound < upperBound) {
    // Calculate the middle index between the current bounds
    const midIndex = Math.floor((lowerBound + upperBound) / 2);
    hiddenOptionsCount.value = midIndex;
    // eslint-disable-next-line no-await-in-loop
    await nextTick();
    if (isOverflowing()) {
      lowerBound = midIndex + 1;
    } else {
      upperBound = midIndex;
    }
  }
  // Set the number of hidden options to the lower bound
  hiddenOptionsCount.value = lowerBound;
};

watch([activeOptions, feedbackTooltipNode], () => {
  hiddenOptionsCount.value = 0;
  hideOptions();
});

useResizeObserver(feedbackTooltipNode, hideOptions);

const hiddenOptionsText = computed(() => [
  {
    title: `${props.property.title}`,
    items: activeOptions.value.slice(-optionsCount.value).map((option) => option.title),
  },
]);

onUnmounted(() => {
  appStore.showFeedbackTooltip(null, []);
});
</script>

<template>
  <MultiselectDropdownMenu
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :items="optionItems"
    :placeholder="`Add an option...`"
    :block="isFormMode"
    :height-block="isFormMode"
    :show-on-hover="isContextMenuMode"
    :skidding="isFormMode ? -1 : undefined"
    :distance="isFormMode ? 2 : undefined"
    :cover="!isContextMenuMode && !isFormMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :validate="validateOptionTitle"
    :show-recommendation-button="isDefault && !isFormMode"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    @recommend="generateRecommendation"
    @remove="onRemove"
    @add="onAdd"
    @create="onCreate">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="TagsFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isChipRecommendationMode || isFormMode"
      :command-ids="isDefault ? [CommandId.ADD_TAGS, CommandId.REMOVE_TAGS] : undefined"
      :text="isDefault ? undefined : `Change ${property.title.toLowerCase()}`"
      :block="isListMode || isFormMode"
      :height-block="isListMode || isFormMode"
      class="w-full">
      <div
        v-if="isChipMode"
        ref="feedbackTooltipNode"
        class="flex items-center gap-1 rounded border px-1 py-0.5 text-md"
        :class="
          isChipRecommendationMode
            ? 'border-recommendation-base/50 bg-recommendation-base/30 hover:bg-recommendation-base/40'
            : 'border-oncolor hover:bg-md'
        ">
        <TagsFieldIcon
          :class="{
            'text-recommendation-base': isChipRecommendationMode,
            'text-primary-base': isBaseChipMode && optionDuids.length !== 0,
          }"
          class="icon-sm" />
        <div v-if="loading" class="flex size-full items-center justify-center">
          <AiSpinner class="icon-sm" />
        </div>
        <span v-else class="max-w-xs select-none truncate">
          {{ optionDuids.length === 0 ? property.title : `${optionDuids.length} ${property.title.toLowerCase()}` }}
        </span>
        <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation">
          <span
            class="rounded hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
            @click.stop="rejectRecommendation"
            @keydown.enter.stop="rejectRecommendation">
            <XIcon class="icon-xs" />
          </span>
        </Tooltip>
      </div>

      <div
        v-else
        ref="feedbackTooltipNode"
        class="group/options flex size-full cursor-pointer items-center gap-1"
        :class="{
          'truncate px-2 text-sm hover:bg-lt': isFormMode || isTaskDetailMode,
          'overflow-x-hidden': isListMode,
          'flex-wrap rounded py-0.5': !isListMode,
          'hover:bg-md': !isListMode && !isTaskDetailMode && !isRecommendationMode && !isFormMode,
          'px-1': activeOptions.length !== 0,
          'justify-start py-1 text-left': isTaskDetailMode,
          'bg-recommendation-base/10 hover:bg-recommendation-base/20 dark:hover:bg-recommendation-base/20':
            isDetailRecommendationMode,
        }">
        <div v-if="loading" class="flex size-full items-center justify-center py-0.5">
          <AiSpinner class="icon-sm" />
        </div>
        <template v-else>
          <OptionComponent
            v-for="option in options"
            :key="option.duid"
            show-ancestors
            :show-hidden-truncated-option="isAllOptionsHidden"
            :option="option"
            :truncate="!isTaskDetailMode && !isChipMode" />
          <Tooltip v-if="optionsCount > 0" :sections="hiddenOptionsText" :placement="Placement.TOP">
            <Chip id="chip" :label="`+${optionsCount}`" />
          </Tooltip>
          <template v-if="!isListMode && !activeOptions.length">
            <div v-if="isTaskDetailMode || isFormMode" class="select-none truncate text-vlt">
              {{ isTaskDetailMode ? "None" : `Set ${property.title.toLowerCase()}` }}
            </div>
            <PlusIcon v-else class="icon-sm" />
          </template>
          <div
            v-if="isListMode && !activeOptions.length"
            class="flex size-full items-center justify-center text-transparent group-hover/options:text-vlt">
            <TagsFieldIcon class="icon-xs" aria-hidden="true" />
          </div>
        </template>
        <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation">
          <span
            class="rounded text-md hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
            @click.stop="rejectRecommendation"
            @keydown.enter.stop="rejectRecommendation">
            <XIcon class="icon-xs" />
          </span>
        </Tooltip>
      </div>
    </Tooltip>
  </MultiselectDropdownMenu>
</template>
