<script setup lang="ts">
import type { EditorState, LexicalEditor } from "lexical";
import { LexicalOnChangePlugin } from "lexical-vue";

const props = defineProps<{
  hasFocus: boolean;
}>();

const emit = defineEmits<{
  change: [editorState: EditorState, editor: LexicalEditor];
}>();

const onChange = (editorState: EditorState, editor: LexicalEditor) => {
  if (!props.hasFocus) {
    return;
  }

  emit("change", editorState, editor);
};
</script>

<template>
  <LexicalOnChangePlugin @change="onChange" />
</template>
