<script setup lang="ts">
import { Dropdown } from "floating-vue";
import { computed, ref } from "vue";

import { TUTORIAL_NAMES_TO_STEPS } from "~/constants/tutorial";
import { XIcon } from "~/icons";
import { Placement, TutorialName } from "~/shared/enums";
import { useAppStore, usePageStore, useUserStore } from "~/stores";

const props = defineProps<{
  name: TutorialName;
  step: number;
  alternate?: number;
  disabled?: boolean;
  completeOnClick?: boolean;
  placement?: Placement;
  skidding?: number;
  arrowPadding?: number;
  block?: boolean;
  heightBlock?: boolean;
  shadowStyles?: string;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const isOnStep = computed(() => userStore.getTutorialStatus(props.name) === props.step);
const closed = ref(false);
// TODO add more modal checks here and more cases where these should not be shown because other stuff is going on
const shown = computed(
  () =>
    !appStore.onboardingModalOpen &&
    !appStore.trialStartedModalOpen &&
    !appStore.settingsModalOpen &&
    isOnStep.value &&
    !closed.value
);

const allSteps = computed(() => TUTORIAL_NAMES_TO_STEPS.get(props.name));
const stepCount = computed(() => allSteps.value?.length);
const stepDetails = computed(() => allSteps.value?.[props.step - 1]);
const title = computed(() => stepDetails.value?.title);
const content = computed(() =>
  props.alternate === undefined
    ? stepDetails.value?.content
    : stepDetails.value?.alternateContent?.[props.alternate - 1]
);

const close = () => {
  closed.value = true;
};

const moveToNextStep = () => userStore.updateTutorialStatuses([{ name: props.name, status: props.step + 1 }]);

const onClick = () => {
  if (!isOnStep.value || props.disabled || !props.completeOnClick) {
    return;
  }

  moveToNextStep();
};
</script>

<template>
  <Dropdown
    class="flex"
    :class="{
      'max-w-full grow': block,
      'h-full': heightBlock,
    }"
    :shown="shown"
    :disabled="disabled"
    :placement="placement ?? Placement.BOTTOM_LEFT"
    :skidding="skidding"
    :distance="12"
    :arrow-padding="arrowPadding"
    :triggers="[]"
    :auto-hide="false"
    :delay="{ show: 700, hide: 0 }"
    :theme="`tutorial-${pageStore.theme}`">
    <div
      class="flex max-w-full grow rounded"
      :class="[shadowStyles, shown && !disabled && 'shadow-[0_0_4px_4px] shadow-primary-base/20']"
      @click="onClick"
      @keydown.enter="onClick">
      <slot />
    </div>

    <template #popper>
      <div class="flex max-w-[300px] rounded bg-std text-hvy">
        <div
          class="flex size-full select-none flex-col gap-2 rounded border border-primary-base/40 bg-primary-base/20 px-2.5 py-2">
          <div class="flex justify-between">
            <div class="flex items-center gap-3">
              <div class="text-base font-semibold">{{ title }}</div>
              <div class="text-xs">({{ step }}/{{ stepCount }})</div>
            </div>
            <button
              type="button"
              class="flex items-center rounded p-0.5 text-primary-base focus-ring-std icon-md hover:bg-primary-base/20"
              aria-label="Close"
              @click="close"
              @keydown.enter="close">
              <XIcon class="icon-sm" aria-hidden="true" />
            </button>
          </div>
          <div class="text-sm">{{ content }}</div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<style>
.v-popper--theme-tutorial-light .v-popper__wrapper,
.v-popper--theme-tutorial-dark .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(99 102 241 / 0.2),
    0 4px 6px -4px rgb(99 102 241 / 0.2);
}
.v-popper--theme-tutorial .v-popper__inner {
  padding: 0px;
  border-radius: 4px;
  background-color: transparent;
}

.v-popper--theme-tutorial-light .v-popper__arrow-outer {
  border-width: 12px;
  border-color: rgb(178 178 247);
}
.v-popper--theme-tutorial-dark .v-popper__arrow-outer {
  border-width: 12px;
  border-color: rgb(68 68 149);
}

.v-popper--theme-tutorial-light.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer,
.v-popper--theme-tutorial-dark.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  left: -12px;
  top: -6px;
}
.v-popper--theme-tutorial-light.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer,
.v-popper--theme-tutorial-dark.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  top: -12px;
  left: -6px;
}
</style>
