import moment from "moment";

import { DocKind, FolderKind, IconKind, PageKind, StoreDataPreservationMode } from "~/shared/enums";
import type {
  Doc,
  DocUpdate,
  Folder,
  FolderUpdate,
  Task,
  TaskDocRelationship,
  TaskDocRelationshipCreate,
} from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { getNextTitleInSequence, makeDuid, makeEmptyLexicalState, randomSample } from "~/utils/common";
import { makeDocComparator, makeFolderComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  folderList: Folder[];
  getFolderByDuid: (duid: string) => Folder | undefined;
  getFoldersBySpaceDuidOrdered: (spaceDuid: string) => Folder[];
  defaultFolder: Folder;
  reportsFolder: (spaceDuid: string) => Folder;
  getDocList: (options?: { includeTrashed?: boolean; includeDraft?: boolean }) => Doc[];
  getDocByDuid: (duid: string) => Doc | undefined;
  getDocsByDuidsOrdered: (duids: string[]) => Doc[];
  getDocsByFolderDuidOrdered: (
    folderDuid: string,
    options?: { includeTrashed?: boolean; includeDraft?: boolean }
  ) => Doc[];
  getDocsRelatedToTaskOrdered: (taskDuid: string) => Doc[];
  getTasksRelatedToDocOrdered: (docDuid: string) => Task[];
};

export type Actions = {
  /** FOLDERS */
  /** Create a new folder. */
  createFolder: (spaceDuid: string, order: string, options?: { awaitBackend?: boolean }) => Promise<Folder>;
  /** Update an existing folder. */
  updateFolder: (update: FolderUpdate, options?: { awaitBackend?: boolean }) => Promise<Folder | undefined>;
  /* Replicate a folder. */
  replicateFolder: (
    folder: Folder,
    order: string,
    partialFolder?: Partial<Folder>,
    options?: { awaitBackend?: boolean; maintainTitle?: boolean }
  ) => Promise<Folder | undefined>;
  /** Delete a folder. */
  deleteFolder: (folder: Folder, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort folders.
   * @PRIVATE */
  $sortFolders: () => void;
  /** Set folders initially.
   * @PRIVATE */
  $setFolders: (folders: Folder[]) => void;
  /** Create or update folder from WS.
   * @PRIVATE */
  $createOrUpdateFolder: (folder: Folder) => void;
  /** Delete folder from WS.
   * @PRIVATE */
  $deleteFolder: (folder: Folder) => void;
  /** DOCS */
  /** Create a new doc. */
  createDoc: (
    title: string,
    folderDuid: string,
    order: string,
    partialDoc?: Partial<Doc>,
    options?: { relatedTaskDuids?: string[]; awaitBackend?: boolean }
  ) => Promise<Doc>;
  /* Update existing docs. */
  updateDocs: (updates: DocUpdate[], options?: { awaitBackend?: boolean }) => Promise<Doc[] | undefined>;
  /* Add subscribers to doc. */
  addDocSubscribers: (
    docDuid: string,
    subscriberDuids: string[],
    options?: { awaitBackend?: boolean }
  ) => Promise<void>;
  /* Remove subscribers from doc. */
  removeDocSubscribers: (
    docDuid: string,
    subscriberDuids: string[],
    options?: { awaitBackend?: boolean }
  ) => Promise<void>;
  /* Replicate a doc. */
  replicateDoc: (
    doc: Doc,
    order: string,
    partialDoc?: Partial<Doc>,
    options?: { awaitBackend?: boolean; maintainTitle?: boolean }
  ) => Promise<Doc | undefined>;
  /** Move doc to trash */
  trashDoc: (doc: Doc, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Get which of the subscribers need be added to the BE.
   * @PRIVATE */
  $updateDocEditorsAndSubscribersNoBackend: (
    doc: Doc,
    userDuids: string[]
  ) => { newEditorDuids: string[]; newSubscriberDuids: string[] };
  /** Sort docs.
   * @PRIVATE */
  $sortDocs: () => void;
  /** Set doc initially.
   * @PRIVATE */
  $setDocs: (docs: Doc[], preservationMode: StoreDataPreservationMode) => void;
  /** Create or update doc from WS.
   * @PRIVATE */
  $createOrUpdateDoc: (doc: Doc) => void;
  /** Delete doc from WS.
   * @PRIVATE */
  $deleteDoc: (doc: Doc) => void;
  /* TASK-DOC RELATIONSHIPS */
  /** Create a new task-doc relationship. */
  createTaskDocRelationship: (
    taskDuid: string,
    docDuid: string,
    options?: { awaitBackend?: boolean }
  ) => Promise<TaskDocRelationship>;
  /** Delete a task-doc relationship. */
  deleteTaskDocRelationship: (taskDuid: string, docDuid: string, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Add attachments FE only.
   * @PRIVATE */
  $addTaskDocRelationshipNoBackend: (taskDocRelationshipCreate: TaskDocRelationshipCreate) => void;
  /** Set task-doc relationships initially.
   * @PRIVATE */
  $setTaskDocRelationships: (taskDocRelationships: TaskDocRelationship[]) => void;
  /** Create task-doc relationship from WS.
   * @PRIVATE */
  $createTaskDocRelationship: (taskDocRelationship: TaskDocRelationship) => void;
  /** Delete task-doc relationship from WS.
   * @PRIVATE */
  $deleteTaskDocRelationship: (taskDocRelationship: TaskDocRelationship) => void;
};

const addValueToMapMap = (map: Map<string, Map<string, string>>, key1: string, key2: string, value: string) => {
  if (!map.has(key1)) {
    map.set(key1, new Map([[key2, value]]));
    return;
  }
  map.get(key1)?.set(key2, value);
};
const removeValueFromMapSet = (map: Map<string, Map<string, string>>, key1: string, key2: string) => {
  map.get(key1)?.delete(key2);
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  folderList() {
    return Array.from(this._duidsToFolders.values());
  },
  getFolderByDuid() {
    return (duid) => this._duidsToFolders.get(duid);
  },
  getFoldersBySpaceDuidOrdered() {
    return (spaceDuid) => this.folderList.filter((e) => e.spaceDuid === spaceDuid);
  },
  defaultFolder() {
    const folder = this.folderList.filter((e) => e.kind === FolderKind.DEFAULT);
    if (folder.length !== 1) {
      throw new Error("Expected exactly one default folder");
    }
    return folder[0];
  },
  reportsFolder() {
    return (spaceDuid) => {
      const res = this.folderList.find((e) => e.spaceDuid === spaceDuid && e.kind === FolderKind.REPORTS);
      if (!res) {
        throw new Error("No reports folder found");
      }
      return res;
    };
  },
  getDocList() {
    return (options = {}) => {
      const { includeTrashed = false, includeDraft = false } = options;
      return [...this._duidsToDocs.values()].filter(
        (e) => (includeTrashed || !e.inTrash) && (includeDraft || !e.drafterDuid)
      );
    };
  },
  getDocByDuid() {
    return (duid) => this._duidsToDocs.get(duid);
  },
  getDocsByDuidsOrdered() {
    return (duids) => this.getDocList().filter((e) => duids.includes(e.duid));
  },
  getDocsByFolderDuidOrdered() {
    return (folderDuid, options) => this.getDocList(options).filter((e) => e.folderDuid === folderDuid);
  },
  getDocsRelatedToTaskOrdered() {
    return (taskDuid) =>
      this.getDocsByDuidsOrdered(Array.from(this._taskDuidsToDocDuidsToRelationships.get(taskDuid)?.keys() ?? []));
  },
  getTasksRelatedToDocOrdered() {
    return (docDuid) =>
      this.getTasksByDuidsOrdered(Array.from(this._docDuidsToTaskDuidsToRelationships.get(docDuid)?.keys() ?? []));
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  /* FOLDERS */
  async createFolder(spaceDuid, order, options = {}) {
    const { awaitBackend = false } = options;
    const appStore = this.$useAppStore();

    const newFolder: Folder = {
      pageKind: PageKind.FOLDER,
      duid: makeDuid(),
      spaceDuid,
      kind: FolderKind.OTHER,
      order,
      title: "",
      description: "",
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
    };
    this.$createOrUpdateFolder(newFolder);

    const backendAction = this.$backend.folder.create(newFolder);
    if (awaitBackend) {
      await backendAction;
    }
    return newFolder;
  },
  async updateFolder(update, options = {}) {
    const { awaitBackend = false } = options;
    const folder = this.getFolderByDuid(update.duid);
    if (!folder) {
      return undefined;
    }

    Object.assign(folder, update);
    this.$sortFolders();

    const backendAction = this.$backend.folder.update(update);
    if (awaitBackend) {
      await backendAction;
    }

    return folder;
  },
  async replicateFolder(folder, order, partialFolder, options = {}) {
    const { awaitBackend = false, maintainTitle = false } = options;
    const now = new Date().toISOString();
    const newFolder: Folder = {
      ...JSON.parse(JSON.stringify(folder)),
      duid: makeDuid(),
      createdAt: now,
      updatedAt: now,
      kind: FolderKind.OTHER,
      order,
      ...partialFolder,
    };
    if (!maintainTitle && partialFolder?.title === undefined) {
      newFolder.title = getNextTitleInSequence(
        folder.title,
        this.getFoldersBySpaceDuidOrdered(newFolder.spaceDuid).map((e) => e.title)
      );
    }

    this.$createOrUpdateFolder(newFolder);

    const folderCreate = this.$backend.folder.create(newFolder);
    const backendPromises: Promise<unknown>[] = [folderCreate];

    const docs = this.getDocsByFolderDuidOrdered(folder.duid);
    if (docs.length > 0) {
      const docReplicates = Promise.all(
        docs.map((doc) => this.replicateDoc(doc, doc.order, { folderDuid: newFolder.duid }, { maintainTitle: true }))
      );
      backendPromises.push(docReplicates);
    }

    if (awaitBackend) {
      await Promise.all(backendPromises);
    }

    return newFolder;
  },
  async deleteFolder(folder, options = {}) {
    const { awaitBackend = false } = options;
    const defaultFolderDuid = this.defaultFolder.duid;
    if (!defaultFolderDuid) {
      return;
    }

    // TODO transactionify
    const updates = this.getDocsByFolderDuidOrdered(folder.duid, { includeTrashed: true }).map((e) => {
      const res: DocUpdate = {
        duid: e.duid,
        folderDuid: defaultFolderDuid,
      };
      if (!e.inTrash) {
        res.inTrash = true;
      }
      return res;
    });

    this.updateDocs(updates);

    this.$deleteFolder(folder);

    const backendAction = this.$backend.folder.delete(folder.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortFolders() {
    this._duidsToFolders = new Map(
      [...this._duidsToFolders].sort((a, b) => makeFolderComparator(this.getSpaceByDuid)(a[1], b[1]))
    );
  },
  $setFolders(folders) {
    this._duidsToFolders = new Map(folders.map((e) => [e.duid, { ...e, pageKind: PageKind.FOLDER }]));
    this.$sortFolders();
  },
  async $createOrUpdateFolder(folder) {
    const currentFolder = this.getFolderByDuid(folder.duid);

    // TODO remove this check when we are filtering out spurious updates on BE of WS
    const space = this.getSpaceByDuid(folder.spaceDuid);
    if (!space) {
      return;
    }
    if (!(space.accessibleByTeam || space.accessibleByUserDuids.includes(this.$useUserStore().duid))) {
      if (currentFolder) {
        this.$deleteFolder(folder);
      }
      return;
    }

    // eslint-disable-next-line no-param-reassign
    folder.pageKind = PageKind.FOLDER;

    if (!currentFolder) {
      this._duidsToFolders.set(folder.duid, folder);
    } else {
      Object.assign(currentFolder, folder);
    }

    this.$sortFolders();

    /* Create */
    // TODO shouldn't rely on client to get the docs separately, this should probably come over WS
    if (!currentFolder) {
      const { data } = await this.$backendOld.docs.getByFolder(folder.duid);
      data.items.forEach((doc: Doc) => {
        this.$createOrUpdateDoc(doc);
      });
    }
  },
  $deleteFolder(folder) {
    const deletingCurrentPage = folder.duid === this.$useAppStore().currentPage?.duid;

    this._duidsToFolders.delete(folder.duid);

    // TODO shouldn't rely on client to delete these, this should probably come over WS; also this is bad because it can be bad for the normal delete flow
    this.getDocsByFolderDuidOrdered(folder.duid, { includeTrashed: true, includeDraft: true }).forEach(this.$deleteDoc);

    if (deletingCurrentPage) {
      this.$routerUtils.goHome();
    }
  },
  /* DOCS */
  async createDoc(title, folderDuid, order, partialDoc, options = {}) {
    const { relatedTaskDuids = [], awaitBackend = false } = options;

    const appStore = this.$useAppStore();
    const userStore = this.$useUserStore();

    const now = new Date().toISOString();

    const newDoc: Doc = {
      pageKind: PageKind.DOC,
      duid: makeDuid(),
      kind: DocKind.DOC,
      createdAt: now,
      updatedAt: now,
      drafterDuid: null,
      inTrash: false,
      folderDuid,
      reportKind: null,
      order,
      title,
      text: makeEmptyLexicalState(),
      editedByAi: false,
      recommendationDuid: null,
      editorDuids: [userStore.duid],
      subscriberDuids: [userStore.duid],
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      ...partialDoc,
    };
    this.$createOrUpdateDoc(newDoc);
    appStore.addRecentDoc(newDoc);

    const relationships = relatedTaskDuids.map((taskDuid) => {
      const taskDocRelationshipCreate = { duid: makeDuid(), taskDuid, docDuid: newDoc.duid };
      this.$createTaskDocRelationship(taskDocRelationshipCreate);
      return taskDocRelationshipCreate;
    });

    const backendAction = this.$backend.doc.create(newDoc, relationships);
    if (awaitBackend) {
      await backendAction;
    }
    return newDoc;
  },
  async updateDocs(updates, options = {}) {
    const appStore = this.$useAppStore();

    const { awaitBackend = false } = options;
    if (updates.length === 0) {
      return undefined;
    }

    const updaterDuid = this.$useUserStore().duid;

    const listAdds: DocUpdate[] = [];
    const updatedDocs: Doc[] = [];

    updates.forEach((update) => {
      const doc = this.getDocByDuid(update.duid);
      if (!doc) {
        return;
      }

      appStore.addRecentDoc(doc);

      if (doc.recommendationDuid) {
        // eslint-disable-next-line no-param-reassign
        update.recommendationDuid = null;
      }

      const { newEditorDuids, newSubscriberDuids } = this.$updateDocEditorsAndSubscribersNoBackend(doc, [updaterDuid]);
      if (newEditorDuids.length > 0 || newSubscriberDuids.length > 0) {
        const listAdd: DocUpdate = { duid: doc.duid };
        if (newEditorDuids.length > 0) {
          listAdd.editorDuids = newEditorDuids;
        }
        if (newSubscriberDuids.length > 0) {
          listAdd.subscriberDuids = newSubscriberDuids;
        }
        listAdds.push(listAdd);
      }

      Object.assign(doc, update);
      doc.updatedAt = new Date().toISOString();
      updatedDocs.push(doc);
    });

    this.$sortDocs();

    const backendAction = this.$backend.doc.updateUpdateListAddMany(updates, listAdds);
    if (awaitBackend) {
      await backendAction;
    }
    return updatedDocs;
  },
  async addDocSubscribers(docDuid, subscriberDuids, options = {}) {
    const { awaitBackend = false } = options;
    const doc = this.getDocByDuid(docDuid);
    if (!doc) {
      return;
    }

    doc.subscriberDuids = [...new Set([...doc.subscriberDuids, ...subscriberDuids])];
    doc.updatedAt = new Date().toISOString();

    this.$sortDocs();

    const backendAction = this.$backend.doc.updateListAdd({ duid: docDuid, subscriberDuids });
    if (awaitBackend) {
      await backendAction;
    }
  },
  async removeDocSubscribers(docDuid, subscriberDuids, options = {}) {
    const { awaitBackend = false } = options;
    const doc = this.getDocByDuid(docDuid);
    if (!doc) {
      return;
    }

    doc.subscriberDuids = doc.subscriberDuids.filter((e) => !subscriberDuids.includes(e));
    doc.updatedAt = new Date().toISOString();

    this.$sortDocs();

    const backendAction = this.$backend.doc.updateListRemove({ duid: docDuid, subscriberDuids });
    if (awaitBackend) {
      await backendAction;
    }
  },
  async replicateDoc(doc, order, partialDoc, options = {}) {
    const { awaitBackend = false, maintainTitle = false } = options;
    const folder = this.getFolderByDuid(doc.folderDuid);
    if (!folder) {
      return undefined;
    }

    const now = new Date().toISOString();
    const newDoc: Doc = {
      ...JSON.parse(JSON.stringify(doc)),
      duid: makeDuid(),
      createdAt: now,
      updatedAt: now,
      order,
      editorDuids: [this.$useUserStore().duid],
      ...partialDoc,
    };
    if (!maintainTitle && partialDoc?.title === undefined) {
      newDoc.title = getNextTitleInSequence(
        doc.title,
        this.getDocsByFolderDuidOrdered(newDoc.folderDuid).map((e) => e.title)
      );
    }

    this.$createOrUpdateDoc(newDoc);

    const taskDocRelationshipCreates = this.getTasksRelatedToDocOrdered(doc.duid).map((task) => {
      const taskDocRelationshipCreate = { duid: makeDuid(), taskDuid: task.duid, docDuid: newDoc.duid };
      this.$addTaskDocRelationshipNoBackend(taskDocRelationshipCreate);
      return taskDocRelationshipCreate;
    });

    const backendAction = this.$backend.doc.create(newDoc, taskDocRelationshipCreates);
    if (awaitBackend) {
      await backendAction;
    }

    return newDoc;
  },
  async trashDoc(doc, options = {}) {
    const { awaitBackend = false } = options;
    // eslint-disable-next-line no-param-reassign
    doc.inTrash = true;
    // eslint-disable-next-line no-param-reassign
    doc.updatedAt = new Date().toISOString();

    const backendAction = this.$backend.doc.update({ duid: doc.duid, inTrash: true });
    if (awaitBackend) {
      await backendAction;
    }
  },
  $updateDocEditorsAndSubscribersNoBackend(doc, userDuids) {
    const currentEditorDuids = new Set(doc.editorDuids);
    const newEditorDuids = userDuids.filter((e) => !currentEditorDuids.has(e));
    // eslint-disable-next-line no-param-reassign
    doc.editorDuids = [...currentEditorDuids, ...newEditorDuids];

    const currentSubscriberDuids = new Set(doc.subscriberDuids);
    const newSubscriberDuids = userDuids.filter((e) => !currentSubscriberDuids.has(e));
    // eslint-disable-next-line no-param-reassign
    doc.subscriberDuids = [...currentSubscriberDuids, ...newSubscriberDuids];

    return { newEditorDuids, newSubscriberDuids };
  },
  $sortDocs() {
    this._duidsToDocs = new Map(
      [...this._duidsToDocs].sort((a, b) => makeDocComparator(this.getSpaceByDuid, this.getFolderByDuid)(a[1], b[1]))
    );
  },
  $setDocs(docs, preservationMode) {
    const recentCutoff = moment().subtract(30, "second");

    const newDocs: Doc[] = [];
    docs.forEach((newDoc) => {
      const oldDoc = this.getDocByDuid(newDoc.duid);
      if (!oldDoc) {
        newDocs.push(newDoc);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.NONE) {
        Object.assign(oldDoc, newDoc);
        newDocs.push(oldDoc);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.ALL) {
        newDocs.push(oldDoc);
        return;
      }
      if (moment(oldDoc.updatedAt).isAfter(recentCutoff)) {
        newDocs.push(oldDoc);
        return;
      }
      Object.assign(oldDoc, newDoc);
      newDocs.push(oldDoc);
    });
    const newDocDuids = new Set(newDocs.map((e) => e.duid));
    newDocs.push(
      ...[...this._duidsToDocs.values()].filter(
        (e) => !newDocDuids.has(e.duid) && moment(e.createdAt).isAfter(recentCutoff)
      )
    );
    this._duidsToDocs = new Map(newDocs.map((e) => [e.duid, { ...e, pageKind: PageKind.DOC, kind: DocKind.DOC }]));

    this.$sortDocs();
  },
  $createOrUpdateDoc(doc) {
    const currentDoc = this.getDocByDuid(doc.duid);

    // TODO remove this check when we are filtering out spurious tasks on BE of WS
    if (!this.getFolderByDuid(doc.folderDuid)) {
      if (currentDoc) {
        this.$deleteDoc(doc);
      }
      return;
    }

    // eslint-disable-next-line no-param-reassign
    doc.pageKind = PageKind.DOC;
    // eslint-disable-next-line no-param-reassign
    doc.kind = DocKind.DOC;

    if (!currentDoc) {
      this._duidsToDocs.set(doc.duid, doc);
    } else {
      Object.assign(currentDoc, doc);
    }

    this.$sortDocs();
  },
  $deleteDoc(doc) {
    this._duidsToDocs.delete(doc.duid);
  },
  /* TASK-DOC RELATIONSHIPS */
  async createTaskDocRelationship(taskDuid, docDuid, options = {}) {
    const { awaitBackend = false } = options;
    const preexistingRelationshipDuid = this._taskDuidsToDocDuidsToRelationships.get(taskDuid)?.get(docDuid);
    if (preexistingRelationshipDuid) {
      return this._duidsToTaskDocRelationships.get(preexistingRelationshipDuid) as TaskDocRelationship;
    }

    const newTaskDocRelationship: TaskDocRelationship = {
      duid: makeDuid(),
      taskDuid,
      docDuid,
    };

    this.$addTaskDocRelationshipNoBackend(newTaskDocRelationship);

    const backendAction = this.$backend.taskDocRelationship.create(newTaskDocRelationship);
    if (awaitBackend) {
      await backendAction;
    }

    return newTaskDocRelationship;
  },
  async deleteTaskDocRelationship(taskDuid, docDuid, options = {}) {
    const { awaitBackend = false } = options;
    const taskDocRelationshipDuid = this._taskDuidsToDocDuidsToRelationships.get(taskDuid)?.get(docDuid);
    if (!taskDocRelationshipDuid) {
      return;
    }
    const taskDocRelationship = this._duidsToTaskDocRelationships.get(taskDocRelationshipDuid);
    if (!taskDocRelationship) {
      return;
    }

    this.$deleteTaskDocRelationship(taskDocRelationship);

    const backendAction = this.$backend.taskDocRelationship.delete(taskDocRelationshipDuid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $addTaskDocRelationshipNoBackend(taskDocRelationshipCreate) {
    this.$createTaskDocRelationship(taskDocRelationshipCreate);
  },
  $setTaskDocRelationships(taskDocRelationships) {
    this._duidsToTaskDocRelationships = new Map(taskDocRelationships.map((e) => [e.duid, e]));

    this._docDuidsToTaskDuidsToRelationships = new Map();
    this._taskDuidsToDocDuidsToRelationships = new Map();
    taskDocRelationships.forEach((e) => {
      addValueToMapMap(this._docDuidsToTaskDuidsToRelationships, e.docDuid, e.taskDuid, e.duid);
      addValueToMapMap(this._taskDuidsToDocDuidsToRelationships, e.taskDuid, e.docDuid, e.duid);
    });
  },
  $createTaskDocRelationship(taskDocRelationship) {
    // TODO remove this check when we are filtering out spurious updates on BE of WS
    if (!this.getDocByDuid(taskDocRelationship.docDuid) || !this.getTaskByDuid(taskDocRelationship.taskDuid)) {
      return;
    }

    this._duidsToTaskDocRelationships.set(taskDocRelationship.duid, taskDocRelationship);

    addValueToMapMap(
      this._docDuidsToTaskDuidsToRelationships,
      taskDocRelationship.docDuid,
      taskDocRelationship.taskDuid,
      taskDocRelationship.duid
    );
    addValueToMapMap(
      this._taskDuidsToDocDuidsToRelationships,
      taskDocRelationship.taskDuid,
      taskDocRelationship.docDuid,
      taskDocRelationship.duid
    );
  },
  $deleteTaskDocRelationship(taskDocRelationship) {
    this._duidsToTaskDocRelationships.delete(taskDocRelationship.duid);

    removeValueFromMapSet(
      this._docDuidsToTaskDuidsToRelationships,
      taskDocRelationship.docDuid,
      taskDocRelationship.taskDuid
    );
    removeValueFromMapSet(
      this._taskDuidsToDocDuidsToRelationships,
      taskDocRelationship.taskDuid,
      taskDocRelationship.docDuid
    );
  },
};

export { actions, getters };
