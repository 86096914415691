<script setup lang="ts">
import type { ApexOptions } from "apexcharts";
import { computed } from "vue";
import ApexCharts from "vue3-apexcharts";

import { colorsByTheme } from "~/constants/style";
import { PieChartDisplayMetric } from "~/shared/enums";
import type { StatisticConfigPie, Task } from "~/shared/types";
import { usePageStore } from "~/stores";

import { getTotals } from "../common";
import StatisticNoData from "../StatisticNoData.vue";

const props = defineProps<{
  tasks: Task[];
  width: number;
  height: number;
  config: StatisticConfigPie;
}>();

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const args = computed(() => ({ tasks: props.tasks, propertyDuid: props.config.adtl.propertyDuid }));
const { totals, hasData, propertyOptions: groups } = getTotals(args);

const options = computed<ApexOptions>(() => ({
  chart: {
    id: props.config.duid,
    type: "pie",
    height: "100%",
    width: "100%",
    events: {
      mounted: (chart) => {
        // Chart is not correct width on first render
        chart.windowResizeHandler();
      },
    },
  },
  stroke: {
    colors: [colors.value.bgMd],
  },
  labels: groups.value.map((e) => e.title),
  colors: groups.value.map((e) => e.colorHex),
  legend: {
    labels: { colors: colors.value.textLt },
    position: props.height > props.width ? "bottom" : "right",
    horizontalAlign: "center",
    markers: { offsetX: -3, offsetY: 2 },
    itemMargin: { horizontal: 10 },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      dataLabels: {
        offset: -10,
      },
    },
  },
  dataLabels: {
    style: {
      fontSize: "14px",
    },
    formatter(percentage, opts) {
      if (!hasData.value) {
        return "No data";
      }
      if (props.config.adtl.displayMetric === PieChartDisplayMetric.COUNT) {
        return opts.w.config.series[opts.seriesIndex].toString();
      }
      return typeof percentage !== "number" ? percentage : `${percentage.toFixed(1)}%`;
    },
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    shared: true,
  },
}));
</script>

<template>
  <StatisticNoData :has-data="hasData">
    <ApexCharts class="dart-pie-chart" :options="options" :series="hasData ? totals : [1]" width="100%" height="100%" />
  </StatisticNoData>
</template>

<style scoped>
.dart-pie-chart {
  :deep(.apexcharts-tooltip) {
    @apply !py-1;
  }
  :deep(.apexcharts-tooltip-series-group) {
    @apply !bg-transparent !p-0;
  }
}
</style>
