<script setup lang="ts">
import { computed, ref } from "vue";

import actions from "~/actions";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import Button from "~/components/dumb/Button.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import TextInput from "~/components/dumb/TextInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpdateTime from "~/components/dumb/UpdateTime.vue";
import { UNKNOWN_USER_LABEL } from "~/components/visualization/constants";
import { FavoriteIcon, PublicIcon, TaskIcon, UnfavoriteIcon, WorkspaceSettingsIcon } from "~/icons";
import { ButtonStyle, EditorMode, IconKind, IconSize, PageKind } from "~/shared/enums";
import type { Page } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getDashboardLink, getDocLink, getViewLink, isLexicalStateEmpty } from "~/utils/common";
import { getEmojiRecommendation } from "~/utils/recommendation";

const props = defineProps<{
  page: Page;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();
const tenantStore = useTenantStore();

const inputTitle = ref<InstanceType<typeof TextInput> | null>(null);

// Doc
const relatedTasks = computed(() =>
  props.page.pageKind === PageKind.DOC ? dataStore.getTasksRelatedToDocOrdered(props.page.duid) : []
);
const relatedTasksText = computed(() => ["Linked tasks", ...relatedTasks.value.map((e) => `• ${e.title}`)]);
// View and dashboard
const isFavorited = computed(
  () =>
    (props.page.pageKind === PageKind.DASHBOARD || props.page.pageKind === PageKind.VIEW) &&
    props.page.favoritedByUserDuids.includes(userStore.duid)
);
const link = computed(() =>
  props.page.pageKind === PageKind.DASHBOARD
    ? getDashboardLink(props.page)
    : props.page.pageKind === PageKind.VIEW
      ? getViewLink(props.page)
      : props.page.pageKind === PageKind.DOC
        ? getDocLink(props.page)
        : ""
);

const pageName = computed(() =>
  props.page.pageKind === PageKind.DASHBOARD
    ? "dashboard"
    : props.page.pageKind === PageKind.VIEW
      ? "view"
      : props.page.pageKind === PageKind.DOC
        ? "doc"
        : "page"
);

const startEditingTitle = () => {
  inputTitle.value?.startEditing();
};

const finishEditingTitle = async (newTitle: string) => {
  const value = newTitle.trim();
  const pageIsDashboard = props.page.pageKind === PageKind.DASHBOARD;
  const pageIsView = props.page.pageKind === PageKind.VIEW;
  const pageIsDoc = props.page.pageKind === PageKind.DOC;

  if (value === "" && props.page.title === "") {
    // TODO Check if the dashboard's and view's filters are empty as well before deleting
    if (pageIsDashboard) {
      dataStore.deleteDashboard(props.page);
    } else if (pageIsView) {
      dataStore.deleteView(props.page);
    } else if (pageIsDoc && isLexicalStateEmpty(props.page.text)) {
      dataStore.trashDoc(props.page);
    }
    return;
  }

  if (props.page.iconKind !== IconKind.NONE) {
    const update = { duid: props.page.duid, title: value };
    if (pageIsDashboard) {
      dataStore.updateDashboard(update);
    } else if (pageIsView) {
      dataStore.updateView(update);
    } else if (pageIsDoc) {
      dataStore.updateDocs([update]);
    }
    return;
  }

  const emojiRecUpdate = await getEmojiRecommendation(props.page.duid, value);
  const emojiUpdate = { duid: props.page.duid, title: value, ...emojiRecUpdate };
  if (pageIsDashboard) {
    dataStore.updateDashboard(emojiUpdate);
  } else if (pageIsView) {
    dataStore.updateView(emojiUpdate);
  } else if (pageIsDoc) {
    dataStore.updateDocs([emojiUpdate]);
  }
};

const onContextMenu = (event: MouseEvent | KeyboardEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(
    event as PointerEvent,
    props.page.pageKind === PageKind.DOC
      ? actions.context.doc(props.page)
      : actions.context.page(props.page, true, () => {})
  );
};

const toggleFavorite = () => {
  if (props.page.pageKind === PageKind.DASHBOARD) {
    dataStore.updateDashboardFavorite(props.page.duid, !isFavorited.value);
  } else if (props.page.pageKind === PageKind.VIEW) {
    dataStore.updateViewFavorite(props.page.duid, !isFavorited.value);
  }
};

defineExpose({
  startEditingTitle,
});
</script>

<template>
  <div
    class="my-2 flex cursor-pointer justify-between gap-2 rounded px-3 py-2 drag-none hover:bg-lt"
    @contextmenu="onContextMenu">
    <RouterLink :to="link" class="flex grow flex-col gap-1">
      <div class="dart-no-drag -ml-0.5 flex gap-2">
        <PageIconPicker :page="page" @click.prevent @keydown.enter.prevent>
          <Tooltip text="Change icon">
            <span class="flex items-center justify-center rounded p-0.5 hover:bg-opposite/10">
              <PageIcon :page="page" />
            </span>
          </Tooltip>
        </PageIconPicker>
        <div class="-mx-0.5 max-w-80 text-md sm:max-w-[480px]">
          <TextInput
            ref="inputTitle"
            :text="page.title"
            :label="`${pageName} title`"
            editable
            click-to-edit
            text-base
            @save="finishEditingTitle" />
        </div>
      </div>
      <UpdateTime v-if="page.pageKind === PageKind.DOC" :at="page.updatedAt" cursor-pointer />
    </RouterLink>
    <div v-if="page.pageKind === PageKind.DOC" class="flex flex-col items-end gap-2">
      <AvatarGroup
        :ai="page.editedByAi"
        :duids="page.editorDuids"
        :editor-mode="EditorMode.FOLDER"
        tooltip-bottom
        :unset-label="UNKNOWN_USER_LABEL" />
      <Tooltip v-if="relatedTasks.length > 0" :text="relatedTasksText">
        <button
          type="button"
          class="flex h-5 items-center justify-center gap-0.5 rounded border px-0.5 text-gray-900/30 border-oncolor focus-ring-none dark:text-white/20">
          <TaskIcon class="icon-xs" aria-hidden="true" />
          <span class="text-xs">{{ relatedTasks.length }}</span>
        </button>
      </Tooltip>
    </div>
    <div v-else class="flex items-center gap-2">
      <Tooltip :text="`${isFavorited ? `Remove this ${pageName} from` : `Add this ${pageName} to`} favorites`">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          is-contrast
          :icon="isFavorited ? FavoriteIcon : UnfavoriteIcon"
          :icon-size="IconSize.S"
          :icon-args="{ class: '!text-vlt' }"
          borderless
          a11y-label="Toggle favorite"
          class="!p-0.5"
          @click="toggleFavorite" />
      </Tooltip>

      <Tooltip
        v-if="'accessibleByTeam' in page"
        :disabled="!page.accessibleByTeam && !('public' in page && page.public)"
        :text="`Can be accessed by ${'public' in page && page.public ? 'people outside this workspace' : 'everyone in this workspace'}`">
        <PublicIcon v-if="'public' in page && page.public" class="text-vlt icon-md" />
        <WorkspaceSettingsIcon v-else-if="page.accessibleByTeam" class="text-vlt icon-md" />
        <AvatarGroup
          v-else
          :duids="page.accessibleByUserDuids"
          :editor-mode="EditorMode.FOLDER"
          tooltip-bottom
          :unset-label="UNKNOWN_USER_LABEL" />
      </Tooltip>
    </div>
  </div>
</template>
