<script setup lang="ts">
import { capitalize, computed } from "vue";

import { backendOld } from "~/api";
import Checkbox from "~/components/dumb/Checkbox.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import type { EventSubscription, NotificationSettingEdit } from "~/shared/types";
import { useAppStore, useTenantStore, useUserStore } from "~/stores";

const appStore = useAppStore();
const userStore = useUserStore();
const tenantStore = useTenantStore();

const someMethodEnabled = computed(
  () =>
    userStore.notificationSettings.inApp || userStore.notificationSettings.email || userStore.notificationSettings.slack
);

const sections = computed(() => {
  const categoryMap = new Map();
  appStore.eventKinds.forEach((e) => {
    if (!categoryMap.has(e.category)) {
      categoryMap.set(e.category, []);
    }
    categoryMap.get(e.category).push({ ...e, title: e.subcategory.replace(/_/g, " ") });
  });
  return Array.from(categoryMap.entries()).map(([category, kinds]) => ({ category, kinds }));
});

const toggleIsDefault = (newValue: boolean) => {
  userStore.notificationSettings.default = newValue;
  const edits: NotificationSettingEdit[] = [{ field: "default", value: newValue }];
  // if we are switching to default
  if (newValue) {
    // set default values for in-app, email, and slack (for now, on for email and off for slack)
    if (!userStore.notificationSettings.inApp) {
      userStore.notificationSettings.inApp = true;
      edits.push({ field: "inApp", value: true });
    }
    if (!userStore.notificationSettings.email) {
      userStore.notificationSettings.email = true;
      edits.push({ field: "email", value: true });
    }
    if (userStore.notificationSettings.slack) {
      userStore.notificationSettings.slack = !!tenantStore.slackIntegration;
      edits.push({ field: "slack", value: false });
    }
    // go through all kinds and set them to default
    userStore.notificationSettings.subscriptions.forEach((v, k) => {
      const config = appStore.eventKinds.find((e) => e.id === k);
      if (config === undefined) {
        return;
      }
      if (v.inApp !== config.defaultOn) {
        // eslint-disable-next-line no-param-reassign
        v.inApp = config.defaultOn;
        edits.push({ eventKind: k, field: "inApp", value: config.defaultOn });
      }
      if (v.email !== config.defaultOn) {
        // eslint-disable-next-line no-param-reassign
        v.email = config.defaultOn;
        edits.push({ eventKind: k, field: "email", value: config.defaultOn });
      }
      if (v.slack !== config.defaultOn) {
        // eslint-disable-next-line no-param-reassign
        v.slack = config.defaultOn;
        edits.push({ eventKind: k, field: "slack", value: config.defaultOn });
      }
    });
  }
  backendOld.notifications.edit(edits);
};

const toggleOverallSetting = (field: keyof EventSubscription, newValue: boolean) => {
  userStore.notificationSettings[field] = newValue;
  backendOld.notifications.edit([{ field, value: newValue }]);
};

const toggleSpecificKind = (eventKind: number, field: keyof EventSubscription, newValue: boolean) => {
  const config = userStore.notificationSettings.subscriptions.get(eventKind);
  if (config === undefined) {
    return;
  }
  config[field] = newValue;
  backendOld.notifications.edit([{ eventKind, field, value: newValue }]);
};
</script>

<template>
  <div class="h-full overflow-y-scroll">
    <div class="mx-16 space-y-16 lg:mx-32">
      <div class="mb-12 flex flex-col space-y-3">
        <h2 class="flex select-none items-center text-xl text-md">Notifications</h2>
        <p class="select-none text-sm/relaxed text-lt">
          Configure when and how you are notified about important updates in Dart. You will only be notified when others
          make changes.
        </p>
      </div>

      <Toggle
        :value="userStore.notificationSettings.default"
        label="Recommended settings"
        description="Stick with the default settings to ensure you never miss an important update and don't get spammed"
        @update="toggleIsDefault" />

      <template v-if="!userStore.notificationSettings.default">
        <div class="flex flex-col space-y-16">
          <!-- TODO add new section titles and icons here -->
          <Toggle
            :value="userStore.notificationSettings.inApp"
            label="In-app"
            description="Allows you to get in-app notifications about events in Dart"
            @update="(newValue) => toggleOverallSetting('inApp', newValue)" />

          <Toggle
            :value="userStore.notificationSettings.email"
            label="Email"
            description="Allows you to get emails about events in Dart"
            @update="(newValue) => toggleOverallSetting('email', newValue)" />

          <Toggle
            v-if="tenantStore.slackIntegration"
            :value="userStore.notificationSettings.slack"
            label="Slack"
            description="Allows you to get instant Slack updates about events in Dart"
            @update="(newValue) => toggleOverallSetting('slack', newValue)" />

          <template v-if="someMethodEnabled">
            <table class="w-full divide-y border-b border-lt divide-lt">
              <tbody>
                <tr class="sticky -top-3.5 z-10 bg-std">
                  <th class="h-9 p-0" />
                  <th v-if="userStore.notificationSettings.inApp" class="h-9 p-0">
                    <div class="mb-1 mt-3 flex justify-center text-sm font-normal text-lt">In-app</div>
                  </th>
                  <th v-if="userStore.notificationSettings.email" class="h-9 p-0">
                    <div class="mb-1 mt-3 flex justify-center text-sm font-normal text-lt">Email</div>
                  </th>
                  <th v-if="tenantStore.slackIntegration && userStore.notificationSettings.slack" class="h-9 p-0">
                    <div class="mb-1 mt-3 flex justify-center text-sm font-normal text-lt">Slack</div>
                  </th>
                </tr>
                <template v-for="section in sections" :key="section.category">
                  <tr>
                    <td class="flex h-14 flex-col justify-end py-1 text-lg text-md" colspan="3">
                      {{ capitalize(section.category) }}
                    </td>
                  </tr>
                  <tr v-for="kind in section.kinds" :key="kind.id">
                    <td class="h-8 text-sm text-lt">
                      {{ kind.description }}
                    </td>
                    <td v-if="userStore.notificationSettings.inApp" class="h-8">
                      <Checkbox
                        :value="userStore.notificationSettings.subscriptions.get(kind.id)?.inApp ?? false"
                        :label="`Toggle ${section.category} ${kind.subcategory} in-app notifications`"
                        class="mx-auto"
                        @change="(newValue) => toggleSpecificKind(kind.id, 'inApp', newValue)" />
                    </td>
                    <td v-if="userStore.notificationSettings.email" class="h-8">
                      <Checkbox
                        :value="userStore.notificationSettings.subscriptions.get(kind.id)?.email ?? false"
                        :label="`Toggle ${section.category} ${kind.subcategory} emails`"
                        class="mx-auto"
                        @change="(newValue) => toggleSpecificKind(kind.id, 'email', newValue)" />
                    </td>
                    <td v-if="tenantStore.slackIntegration && userStore.notificationSettings.slack" class="h-8">
                      <Checkbox
                        :value="userStore.notificationSettings.subscriptions.get(kind.id)?.slack ?? false"
                        :label="`Toggle ${section.category} ${kind.subcategory} slacks`"
                        class="mx-auto"
                        @change="(newValue) => toggleSpecificKind(kind.id, 'slack', newValue)" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
